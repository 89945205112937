import ChatViewFooter from "../chatViewFooter/ChatViewFooter";
import { useReducer, useRef, useState, useEffect } from "react";
import Pusher from "pusher-js";
import ChatContent from "./ChatContent";
import axios from "axios";
import Loader from "../../../../../../MainComponents/Loader";
import { toast } from "react-hot-toast";

const ChatView = ({
  selectedUserId,
  selectedUserInfo,
  setRefatch,
  superadmin,
}) => {
  const [loading, setLoading] = useState(false);
  const stream = useRef(null);
  const recorder = useRef(null);
  const items = useRef([]);
  const counter = useRef(null);
  const seconds = useRef(null);
  const minutes = useRef(null);
  const textInput = useRef(null);
  const [messages, setMessages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case "setIsRecording": {
        return {
          ...state,
          isRecording: action.payload,
        };
      }
      case "setIsTyping": {
        return {
          ...state,
          isTyping: action.payload,
        };
      }
      case "setIsSendingIMG": {
        return {
          ...state,
          isSendingIMG: action.payload,
        };
      }
      case "setCounter": {
        return {
          ...state,
          counter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    isRecording: false,
    isTyping: false,
    isSendingIMG: false,
    counter: "00:01",
  });

  const handleReftchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          superadmin
            ? process.env.REACT_APP_SUPERADMIN_CHATS_API
            : process.env.REACT_APP_ADMIN_CHATS_API
        }/${selectedUserId}`
      )
      .then((res) => {
        setMessages([...res?.data.data]);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleReftchData();
  }, [selectedUserId, superadmin]);

  function handleTyping(e) {
    if (e.target.value !== "") {
      dispatch({ type: "setIsTyping", payload: true });
    } else {
      dispatch({ type: "setIsTyping", payload: false });
    }
  }
  function startCounter() {
    seconds.current = 1;
    minutes.current = 0;
    let secondsWithZero;
    let minutesdWithZero;
    counter.current = setInterval(() => {
      seconds.current++;
      if (seconds.current / 60 === 1) {
        seconds.current = 0;
        minutes.current++;
        if (minutes.current === 5) {
          stopRecording();
          resetCounter();
        }
      }
      if (seconds.current < 10) {
        secondsWithZero = "0" + seconds.current.toString();
      } else {
        secondsWithZero = seconds.current;
      }

      if (minutes.current < 10) {
        minutesdWithZero = "0" + minutes.current.toString();
      } else {
        minutesdWithZero = minutes.current;
      }
      dispatch({
        type: "setCounter",
        payload: minutesdWithZero + ":" + secondsWithZero,
      });
    }, 1000);
  }
  function resetCounter() {
    dispatch({ type: "setCounter", payload: "00:01" });
    dispatch({ type: "setIsRecording", payload: false });
    clearInterval(counter.current);
    seconds.current = 1;
    minutes.current = 0;
  }

  function startRecording(params) {
    dispatch({ type: "setIsRecording", payload: true });

    //recorder
    let device = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    device
      .then((streamobj) => {
        stream.current = streamobj;
        recorder.current = new MediaRecorder(streamobj);
        recorder.current.ondataavailable = (e) => {
          items.current.push(e.data);
        };
        recorder.current.start();
        startCounter();
      })
      .catch((Error) => {});
  }

  function stopRecording(params) {
    resetCounter();
    recorder.current.stop();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      let blob = new Blob(items.current, { type: "audio/webm" });
      items.current = [];
      let audioUrl = URL.createObjectURL(blob);

      let formData = new FormData();
      formData.append("src", blob, audioUrl);
      formData.append("user_id", selectedUserInfo.student_id);
      formData.append("message", null);
      formData.append("type", "voice");

      // setMessages((prev) => [...prev, { type: "voice", src: audioUrl }]);

      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;

      axios
        .post(process.env.REACT_APP_ADMIN_SEND_CHATS_API, formData)

        .then(() => setRefatch(true))
        .catch((error) => {});

      setRefatch(false);
    };
  }

  function cancelRecording(params) {
    resetCounter();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      items.current = [];
      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;
    };
  }

  function sendTextOrImages() {
    if (textInput.current?.value !== "") {
      let msg = textInput.current?.value;
      // setMessages((prev) => [...prev, { type: "text", msg: msg }]);

      let finalObject = {
        user_id: selectedUserInfo.student_id,
        message: msg,
        type: "text",
      };

      axios
        .post(process.env.REACT_APP_ADMIN_SEND_CHATS_API, finalObject)
        .then((res) => {
          setUploadedImages([]);
          setRefatch(true);
        });

      textInput.current.value = "";
      textInput.current.focus();
    }
    if (uploadedImages?.length > 0) {
      let formData = new FormData();

      formData.append("user_id", selectedUserInfo.student_id);
      formData.append("message", null);
      formData.append("type", "img");

      uploadedImages.forEach((img, index) => {
        let imgFile = uploadedImages[index];
        formData.append(`src`, imgFile);

        axios
          .post(process.env.REACT_APP_ADMIN_SEND_CHATS_API, formData)
          .then(() => {
            setUploadedImages([]);
            setRefatch(true);
          });
      });
    }

    dispatch({ type: "setIsTyping", payload: false });
    dispatch({ type: "setIsSendingIMG", payload: false });
    setRefatch(false);
    // setSelectedUserId(null);
  }

  function handleAddImages(e) {
    setUploadedImages([...uploadedImages, ...Array.from(e.target.files)]);
    if (uploadedImages.length > 0) {
      //logic for not pushing an already exist image
      uploadedImages.forEach((newItem) => {
        let exist = false;
        uploadedImages.forEach((oldItem) => {
          if (oldItem.name === newItem.name) {
            exist = true;
          }
        });
        if (!exist) {
          uploadedImages((curr) => [...curr, newItem]);
        }
      });
    } else {
      uploadedImages((curr) => [...curr]);
    }

    if (uploadedImages?.length > 0) {
      dispatch({ type: "setIsSendingIMG", payload: true });
    } else {
      dispatch({ type: "setIsSendingIMG", payload: false });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendTextOrImages();
    }
  };

  useEffect(() => {
    // Initialize Pusher with your app credentials
    const pusher = new Pusher("1152d8cdd95de185dc08", {
      cluster: "eu",
    });

    // Subscribe to the "chat" channel
    const channel = pusher.subscribe(`chatwith${selectedUserInfo.student_id}`);

    // Listen for new messages
    channel.bind("chatMessage", function (data) {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // Clean up the Pusher subscription
    return () => {
      pusher.unsubscribe(`chatwith${selectedUserInfo.student_id}`);
    };
  }, []);

  const handleDeleteMessage = (id) => {
    axios
      .post(process.env.REACT_APP_ADMIN_DELETE_MESSAGE_CHATS_API, {
        id,
      })
      .then((res) => {
        handleReftchData();
      })
      .catch((error) => toast.error("Error"));
  };

  return (
    <div className="chatView w-2/3 lg:w-full bg-white">
      <div className="userInfo flex items-center gap-4 p-4 mb-4 border-b-2">
        <div className="userImage">
          <img src={selectedUserInfo.student_img} alt="" />
        </div>
        <h4 className="font-bold">{selectedUserInfo.student_name}</h4>
      </div>

      {loading ? (
        <div className="h-fit chatContent flex items-center justify-center">
          <Loader fit />
        </div>
      ) : (
        <ChatContent
          messages={messages}
          handleDeleteMessage={handleDeleteMessage}
        />
      )}

      {!superadmin && (
        <ChatViewFooter
          state={state}
          stopRecording={stopRecording}
          cancelRecording={cancelRecording}
          handleAddImages={handleAddImages}
          handleTyping={handleTyping}
          textInput={textInput}
          startRecording={startRecording}
          sendTextOrImages={sendTextOrImages}
          handleKeyDown={handleKeyDown}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
        />
      )}
    </div>
  );
};

export default ChatView;
