import useTable from "../../../../../MainComponents/Hooks/useTable";

function OfferQuestionsTable({ id, data, refetch,setRefetch }) {
  const table = useTable(
    "OfferQuestions",
    "",
    "",
    "",
    "",
    id,
    true,
    data,
    refetch,
    setRefetch
  );

  return <div className="h-fit w-full">{table}</div>;
}

export default OfferQuestionsTable;
