import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faUser,
  faGear,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthDispatch } from "./GlobalContext.jsx";
import { useAuthState } from "./GlobalContext.jsx";

function DropDownMenu({ scroll }) {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  function handleSignOut() {
    setShowMenu(false);
    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/signin");
  }
  return (
    <>
      {showMenu && (
        <div
          className="absolute inset-0 w-[100vw] h-[100vh]"
          onClick={() => setShowMenu(false)}
        ></div>
      )}
      <div
        className=" img-icon relative flex justify-center items-center gap-2 min-w-[] min-h-[]"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="user-img-container overflow-hidden w-12 h-12 min-w-[48px] min-h-[48px] rounded-full p-1 border-2 border-blue cursor-pointer flex justify-center items-center">
          <img
            src={`https://api.our-matrix.com/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`}
            alt="user-img"
            className="user-img w-full h-full object-cover rounded-full"
          />
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className=" text-lg cursor-pointer"
        />

        {showMenu && (
          <ul className="drop-menu-items absolute top-16 right-0 w-64 h-96 bg-light-gray flex flex-col items-start justify-start p-8 gap-6 rounded-lg shadow-md z-50">
            {/** 
            <li
              onClick={() => {
                navigate("");
                setShowMenu(false);
              }}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <FontAwesomeIcon
                icon={faUser}
                className=" text-lg cursor-pointer"
              />
              <p className="text-lg font-semibold">Profile</p>
            </li>
            <li
              onClick={() => {
                navigate("/home/profile/change-password");
                setShowMenu(false);
              }}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <FontAwesomeIcon
                icon={faUser}
                className=" text-lg cursor-pointer"
              />
              <p className="text-lg font-semibold">Change Password</p>
            </li>

            <li
              onClick={() => {
                navigate("/student/codes");
                setShowMenu(false);
              }}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <FontAwesomeIcon
                className=" text-lg cursor-pointer"
                icon={faQrcode}
              />

              <p className="text-lg font-semibold">Code History</p>
            </li>

            <li
              onClick={() => {
                navigate("/student/exams");
                setShowMenu(false);
              }}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <FontAwesomeIcon
                className=" text-lg cursor-pointer"
                icon={faFileLines}
              />
              <p className="text-lg font-semibold">Trial Exam History</p>
            </li>
           
 * 

 <li
   onClick={() => {
     navigate("");
     setShowMenu(false);
   }}
   className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
 >
   <FontAwesomeIcon
     icon={faStar}
     className=" text-lg cursor-pointer"
   />
   <p className="text-lg font-semibold">Go Pro</p>
 </li>
 * 
*/}

            <li
              onClick={() => {
                navigate("/home/profile/");
                setShowMenu(false);
              }}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <div className="user-img-container overflow-hidden w-12 h-12 min-w-[48px] min-h-[48px] rounded-full p-1 border-2 border-blue cursor-pointer flex justify-center items-center">
                <img
                  src={`https://api.our-matrix.com/uploads/profile_picture/${AuthState.userData?.student?.profile_pic}`}
                  alt="user-img"
                  className="user-img w-full h-full object-cover rounded-full"
                />
              </div>
              <p className="text-lg font-semibold">
                {AuthState.userData?.student?.first_name}{" "}
                {AuthState.userData?.student?.second_name}{" "}
                {AuthState.userData?.student?.third_name}
              </p>
            </li>
            <li>
              <Link
                to="/home/my-wallet"
                className="user-email text-lg font-semibold"
              >
                My Wallet
              </Link>
            </li>
            <li>
              <p className="user-email text-lg font-semibold">
                Email: {AuthState.userData?.student?.email}
              </p>
            </li>
            <li>
              <p className="user-email text-lg font-semibold">
                Parent Id: {AuthState.userData?.student?.parent_id}
              </p>
            </li>
            <li
              onClick={() => handleSignOut()}
              className="flex justify-start items-center gap-4 cursor-pointer hover:opacity-80"
            >
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className=" text-lg cursor-pointer"
              />
              <p className="text-lg font-semibold">Log out</p>
            </li>
          </ul>
        )}
      </div>
    </>
  );
}

export default DropDownMenu;
