import { useEffect, useState } from "react";

function useFade(ref,height) {
      const [fade, setFade] = useState(false);

      useEffect(() => {
        const handleScroll = () => {
          var coursesTitleHeight = ref.current.getBoundingClientRect().top;

          if (parseInt(coursesTitleHeight) < height) {
            setFade(true);
          } else {
            setFade(false);
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

  return [fade, setFade];
}

export default useFade