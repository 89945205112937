import { useEffect, useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";

import useTable from "../../../../MainComponents/Hooks/useTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import Loader from "../../../../MainComponents/Loader";

function Students() {
  const AuthState = useAuthState();
  const [academiclevel, setAcademiclevel] = useState("1");
  const [left, setLeft] = useState("left-0");
  const [academicType, setAcademicType] = useState("scientific");
  const [reFetch, setReFetch] = useState(false);

  const [getAllStudent, getAllStudentErrors, getAllStudentloading] = useAxios(
    `${process.env.REACT_APP_ADMIN_STUDENT_TAB_API}${academiclevel}`,
    "GET",
    "GET",
    academiclevel | reFetch
  );

  const data =
    +academiclevel === 2 || +academiclevel === 3
      ? getAllStudent?.data?.filter((data) => data.section === academicType)
      : getAllStudent?.data;

  const table = useTable(
    "students",
    "",
    "",
    academicType,
    getAllStudent?.data,
    "",
    true,
    data,
    reFetch,
    setReFetch
  );

  if (getAllStudentloading) return <Loader />;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Students | Mr.Mohamed Salama" />
      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">Students</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("request_accept")
          )) && (
          <Link
            to="/admin/students/deleted"
            className=" rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 "
          >
            Deleted Students <FontAwesomeIcon className="ml-2" icon={faTrash} />
          </Link>
        )}
      </div>

      <div className={`relative flex  h-10 min-h-[40px] w-72 justify-start  `}>
        <button
          onClick={() => {
            setLeft("left-0");
            setAcademiclevel("1");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 1
        </button>
        <button
          onClick={() => {
            setLeft("left-1/3");
            setAcademiclevel("2");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 2
        </button>
        <button
          onClick={() => {
            setLeft("left-2/3");
            setAcademiclevel("3");
          }}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 3
        </button>
        <div
          className={`std-selector absolute top-0 h-full w-1/3 ${left} rounded-md bg-green duration-300`}
        ></div>
      </div>

      {+academiclevel === 2 || +academiclevel === 3 ? (
        <div
          className={`mt-10 filters-container bg-light-gray w-full flex justify-center `}
        >
          <div
            className={`filters flex justify-between  items-center w-[800px] h-12 relative amd-hero:flex-col  amd-hero:w-28  transition-all duration-200 ease-in-out`}
          >
            {/* <div
                onClick={() => {
                  dispatch({ type: "setToggleFilter" });
                  dispatch({ type: "setTop", payload: "top-0" });
                }}
                className="ALL hidden w-1/5 amd-hero:flex justify-center items-center h-full min-h-[calc(450px/9)] cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)]  amd-hero:w-28 "
              >
                <FontAwesomeIcon
                  className={`hidden amd-hero:block  font-bold text-2xl transition-all duration-200 ease-in-out ${
                    state.toggleFilter && "rotate-180"
                  }`}
                  icon={faChevronDown}
                />
              </div> */}

            <div
              onClick={() => {
                setAcademicType("scientific");
              }}
              className={`calculus  w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                academicType === "scientific" ? "bg-green" : ""
              }`}
            >
              Scientific
            </div>

            <div
              onClick={() => {
                setAcademicType("literary");
              }}
              className={`Algebra w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                academicType === "literary" ? "bg-green" : ""
              }`}
            >
              Literary
            </div>
          </div>
        </div>
      ) : null}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
