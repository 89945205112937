import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditChooseQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID } = useParams();

  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors, loading] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );

  const [question, setQuestion] = useState("");
  const [answer1, setAnswer1] = useState("<h2></h2> <h2>A</h2>");
  const [answer2, setAnswer2] = useState("<h2></h2> <h2>B</h2>");
  const [answer3, setAnswer3] = useState("<h2></h2> <h2>C</h2>");
  const [answer4, setAnswer4] = useState("<h2></h2> <h2>D</h2>");
  const [hint, setHint] = useState("");
  const [correct_answer, setCorrectAnswer] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [vdo_id, setVideoId] = useState("");
  const [platform, setPlatform] = useState("");
  const [degree, setDegree] = useState("");
  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (answer1 !== "") {
      setErrors("");
    } else if (answer2 !== "") {
      setErrors("");
    } else if (answer3 !== "") {
      setErrors("");
    } else if (answer4 !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    } else if (correct_answer !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (vdo_id !== "" && platform !== "") {
      setErrors("");
    }
  }, [
    question,
    answer1,
    answer2,
    answer3,
    answer4,
    difficulty,
    correct_answer,
    degree,
    vdo_id,
    platform,
  ]);

  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let api = type
    ? process.env.REACT_APP_ADMIN_QUIZ_ADD_CHOOSE_QUESTIONS_API
    : edit
    ? process.env.REACT_APP_EDIT_QUESTION_API
    : `${process.env.REACT_APP_ADD_CHOOSE_QUESTION_API}${categoryID}`;

  const examApi =
    process.env.REACT_APP_ADMIN_TRIAL_EXAM_ADD_CHOOSE_QUESTION_API;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      type === "exam" ? examApi : api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  useEffect(() => {
    if (QuestionDataSuccess?.data) {
      setQuestion(QuestionDataSuccess?.data?.question);
      setAnswer1(QuestionDataSuccess?.data?.answer1);
      setAnswer2(QuestionDataSuccess?.data?.answer2);
      setAnswer3(QuestionDataSuccess?.data?.answer3);
      setAnswer4(QuestionDataSuccess?.data?.answer4);
      setHint(
        QuestionDataSuccess?.data?.hint !== null
          ? QuestionDataSuccess?.data?.hint
          : ""
      );
      setDifficulty(QuestionDataSuccess?.data?.difficulty);
      setCorrectAnswer(QuestionDataSuccess?.data?.correct_answer);
      setPlatform(QuestionDataSuccess?.data?.platform);
      setVideoId(QuestionDataSuccess?.data?.vdo_id);
      setDegree(QuestionDataSuccess?.data?.degree);
    }
  }, [QuestionDataSuccess]);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (question === "") {
      setErrors("question");
      toast.error("Please Add Question");
    } else if (answer1 === "") {
      setErrors("answer1");
      toast.error("Please Add Answer1");
    } else if (answer2 === "") {
      setErrors("answer2");
      toast.error("Please Add Answer2");
    } else if (answer3 === "") {
      setErrors("answer3");
      toast.error("Please Add Answer3");
    } else if (answer4 === "") {
      setErrors("answer4");
      toast.error("Please Add Answer4");
    } else if (correct_answer === "") {
      setErrors("correct_answer");
      toast.error("Please Add Correct Answer");
    } else if (platform && !vdo_id) {
      setErrors("vdo_id");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (!platform && vdo_id) {
      setErrors("platform");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("Please Add Degree");
    } else {
      const data = type
        ? {
            question: question,
            answer1: answer1,
            answer2: answer2,
            answer3: answer3,
            answer4: answer4,
            correct_answer: correct_answer,
            difficulty: difficulty,
            vdo_id: vdo_id || null,
            platform: platform || null,
            hint: hint || null,
            degree: degree,
            content_id: id,
            exam_id: id,
          }
        : {
            question: question,
            answer1: answer1,
            answer2: answer2,
            answer3: answer3,
            answer4: answer4,
            vdo_id: vdo_id,
            platform: platform,
            hint: hint,
            degree: degree,
            correct_answer: correct_answer,
            difficulty: difficulty || null,
            id: edit ? questionID : categoryID,
          };
      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setDifficulty("");
      setCorrectAnswer("");
      setPlatform("");
      setVideoId("");
      setHint("");
      setDegree("");
      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light  ${
        type ? "pt-10" : "py-20"
      }`}
    >
      {!type && (
        <HelmetTags title="Question Bank | Mr Mohamed Salama"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container  flex flex-col items-center "
      >
        <h2
          className={` text-center text-xl ${
            type ? "mb-14" : "mb-20 font-semibold"
          }`}
        >
          {edit
            ? "Please fill in the field to edit the question"
            : "Please fill in the field to add the question"}{" "}
        </h2>
        {/**
         * //!------question-----
         */}
        <div className=" question mb-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <div className="mb-3 w-full flex justify-between items-center gap-5 md:flex-col md:items-center">
            <h2 className=" text-xl font-semibold">Question</h2>
            <div className=" bubble flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="bubble"
                    name="bubble"
                    type="checkbox"
                    defaultChecked={true}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAnswer1("<h2></h2> <h2>A</h2>");
                        setAnswer2("<h2></h2> <h2>B</h2>");
                        setAnswer3("<h2></h2> <h2>C</h2>");
                        setAnswer4("<h2></h2> <h2>D</h2>");
                      } else {
                        setAnswer1("");
                        setAnswer2("");
                        setAnswer3("");
                        setAnswer4("");
                      }
                    }}
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="bubble"
                  >
                    Bubble Sheet
                  </label>
                </div>
              </div>
            </div>
          </div>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",

              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={question}
            onChange={(event, editor) => {
              const data = editor.getData();
              setQuestion(data);
            }}
          />
          {errors === "question" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 1-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 1</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer1}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer1(data);
            }}
          />
          {errors === "answer1" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer1 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer1[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 2-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 2</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer2}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer2(data);
            }}
          />
          {errors === "answer2" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer2 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer2[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 3-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 3</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer3}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer3(data);
            }}
          />
          {errors === "answer3" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer3 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer3[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------answer 4-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Answer 4 </h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={answer4}
            onChange={(event, editor) => {
              const data = editor.getData();
              setAnswer4(data);
            }}
          />
          {errors === "answer4" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.answer4 && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.answer4[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------hint-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Hint</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={hint}
            onChange={(event, editor) => {
              const data = editor.getData();
              setHint(data);
            }}
          />
          {errors === "hint" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.hint && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.hint[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!---------right answer & difficulty
         */}
        <div className="flex w-full items-start justify-evenly gap-10 md:flex-col">
          {/**
           * //!------right answer -----
           */}
          <div
            className={`my-10 flex w-fit flex-col items-start justify-center gap-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="correct_answer">
              Correct Answer{" "}
            </label>
            <select
              value={correct_answer}
              onChange={(e) => setCorrectAnswer(e.target.value)}
              className="w-80"
              name="correct_answer"
              id="correct_answer"
            >
              <option hidden disabled value="">
                Correct Answer
              </option>{" "}
              <option value="answer1">Answer 1</option>
              <option value="answer2">Answer 2</option>
              <option value="answer3">Answer 3</option>
              <option value="answer4">Answer 4</option>
            </select>

            {errors === "correct_answer" && (
              <p className="text-xs text-red-500 ">Please fill in this field</p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors
                  ?.correct_answer && (
                  <p className="text-xs text-red-500  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.correct_answer[0]
                    }
                  </p>
                )
            }
          </div>
          {type === undefined && (
            <div
              className={`my-10 flex w-fit flex-col items-start justify-center gap-2  md:w-full `}
            >
              {/* //!------difficulty ----- */}
              <label className="w-full truncate" htmlFor="difficulty">
                Difficulty{" "}
              </label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                className="w-80"
                name="difficulty"
                id="difficulty"
              >
                <option hidden disabled value="">
                  Difficulty
                </option>{" "}
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>

              {errors === "difficulty" && (
                <p className="text-xs text-red-500 ">
                  Please fill in this field
                </p>
              )}
              {
                //!-------server errors -----

                addEditQuestionErrors &&
                  addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                    <p className="text-xs text-red-500  ">
                      {
                        addEditQuestionErrors?.response?.data?.errors
                          ?.difficulty[0]
                      }
                    </p>
                  )
              }
            </div>
          )}
        </div>
        <div className="flex w-full items-start justify-evenly gap-10 md:flex-col">
          <div className=" vdo_id flex w-1/3  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="vdo_id">
              Video Link{" "}
            </label>
            <input
              className="signin-inputs w-full pl-4    "
              type="text"
              id="vdo_id"
              placeholder="Video Link "
              value={vdo_id}
              onChange={(e) => setVideoId(e.target.value)}
              name="vdo_id"
              autoComplete="on"
            />

            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.vdo_id && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.vdo_id[0]}
                  </p>
                )
            }
          </div>

          <div
            className={`flex w-1/3  flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="platform">
              Platform
            </label>
            <select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              required={vdo_id}
              name="platform"
              id="platform"
            >
              <option hidden disabled value="">
                Platform
              </option>
              <option value="youtube">youtube</option>
              <option value="vimeo">vimeo</option>
              <option value="vdocipher">vdocipher</option>
            </select>

            {errors === "platform" ||
              (vdo_id && platform === "" && (
                <p className="text-xs text-red-500">This field is required. </p>
              ))}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.platform && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.platform[0]}
                  </p>
                )
            }
          </div>
          <div className=" Degree flex w-1/3  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              Degree
            </label>
            <input
              className="signin-inputs w-full "
              type="number"
              // inputMode="numeric"
              min={1}
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              id="degree"
              placeholder="000"
              step=".01"
              name="degree"
              autoComplete="on"
            />

            {errors === "degree" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>

        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit Question" : "Add Question"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditChooseQuestion;
