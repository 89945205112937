import React, { useEffect, useReducer } from "react";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { motion} from "framer-motion";

function StudentOffer() {
  const navigate = useNavigate();
  const { offerID } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  function reducer(state, action) {
    switch (action.type) {
      case "setBuyLecture": {
        return {
          ...state,
          buyOffer: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    buyOffer: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [offerInfoData] = useAxios(
    `${process.env.REACT_APP_STUDENT_OFFER_INFO_API}${offerID}`,
    "GET",
    "GET",
    offerID
  );

  //!---- buy offer  -------
  const [buyOfferSuccess, buyOfferErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_OFFER_API,
    "POST",
    state.buyOffer.flag,
    state.buyOffer.dependency,
    state.buyOffer.data,
    true
  );

  useEffect(() => {
    if (buyOfferSuccess) {
      reset();
      navigate(-1);
    }
  }, [buyOfferSuccess]);

  const handleBuyOffer = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyOffer",
        dependency: !state.buyOffer.dependency,
        data: { ...data, id: offerID },
      },
    });
  };
  return (
    <section className="width h-auto flex items-center flex-col py-10 gap-10">
      <form
        onSubmit={handleSubmit(handleBuyOffer)}
        method="post"
        className="flex w-3/4 max-w-xl flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        <div className=" w-full flex justify-between items-center gap-5">


        <h3 className="price w-full font-bold text-3xl text-center truncate">
          {offerInfoData?.data?.offer?.name} 
        </h3>
        <h3 className="price w-full font-bold text-3xl text-center ">
          {offerInfoData?.data?.offer?.price} L.E
        </h3>
        </div>

        <div className=" flex w-full flex-col items-start justify-center gap-2 ">
          <input
            className="signin-inputs  w-full "
            placeholder="Add your code"
            type="text"
            name="code"
            {...register("code", {
              required: true,
              maxLength: 255,
              minLength: 1,
              pattern: /^[A-Za-z0-9-_]*$/,
            })}
          />
          {errors.code && (
            <p className="text-xs text-red-500 ">
              {errors.code.type === "required" && "This field is required."}
              {errors.code.type === "maxLength" &&
                "code Max length is 255 char."}
              {errors.code.type === "minLength" && "code Min length is 1 char."}
              {errors.code.type === "pattern" &&
                "Only Letters and Numbers are Allowed."}
            </p>
          )}
          {
            //!-------server errors -----

            buyOfferErrors && buyOfferErrors?.response?.data?.errors?.code && (
              <p className="text-xs text-red-500 ">
                {buyOfferErrors?.response?.data?.errors?.code[0]}
              </p>
            )
          }
        </div>

        {/** submit */}

        <button
          className=" submit mt-6 w-full "
          type="submit"
          disabled={!isValid || (submitLoading && state.buyOffer.flag)}
        >
          {submitLoading && state.buyOffer.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Unlock Offer"
          )}
        </button>
      </form>
      <h2 className="font-semibold text-lg"> The Offer's Courses</h2>
      <div className={`offers  mt-10 grid-auto-fit w-full `}>
        {offerInfoData?.data?.courses_offer?.map((offer, index) => {
          return (
            <motion.div
              initial={{
                opacity: 0,
                translateX: -50,
                translateY: -50,
              }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 + 0.2 }}
              key={offer.key}
              className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px]   rounded-md p-4 shadow-lg`}
            >
              <div className="img-wrapper w-full rounded-md overflow-hidden">
                <img
                  className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
                  src={offer.course_img}
                  alt={offer.course_name}
                  onClick={() => navigate(`/home/courses/${offer.key}`)}
                />
              </div>

              <div className="details-wrapper mt-5">
                <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
                  <span
                    onClick={() => navigate(`/home/courses/${offer.key}`)}
                    className="cursor-pointer w-3/5  truncate"
                  >
                    {offer.course_name}
                  </span>
                  <span className="font-normal"> {offer.type}</span>
                </h2>
                <p className="description">{offer.description}</p>

                <div className="price-cta flex justify-center items-center mt-5 w-full">
                  <p className="price"> {offer.price}</p>
                  <Link
                    to="/"
                    className="flex justify-center
               items-center gap-2 bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
                  >
                    <FontAwesomeIcon icon={faLock} />
                    Enroll Now
                  </Link>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </section>
  );
}

export default StudentOffer;
