import { useEffect, useState } from "react";
import HelmetTags from "../../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../../MainComponents/GlobalContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OfferCard from "../../../../../MainComponents/OfferCard.jsx";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../../MainComponents";

function Offers() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(true);

  const [allOffersData, allOffersErrors] = useAxios(
    process.env.REACT_APP_ADMIN_ALL_OFFERS_API,
    "GET",
    "GET",
    reFetch
  );

  useEffect(() => {
    if (allOffersData || allOffersErrors) {
      setLoading(false);
    }
  }, [allOffersErrors, allOffersData]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Offers | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-start gap-10 amd-hero:items-center">
        <h2 className=" text-2xl font-bold">Offers</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("offer_add")
          )) && (
          <Link
            to="/admin/courses/offers/add-offer"
            className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 "
          >
            Add Offer <FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Link>
        )}
      </div>
      <div className="Offers w-full ">
        {loading ? (
          <Loader />
        ) : allOffersErrors && typeof allOffersErrors === "string" ? (
          <p>{allOffersErrors}</p>
        ) : (
          <div className={`offers  mt-10 grid-auto-fit w-full `}>
            {allOffersData?.data?.map((offer) => {
              return (
                <OfferCard
                  admin
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  key={offer.key}
                  offer={offer}
                />
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}

export default Offers;
