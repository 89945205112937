import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
function Reviews() {
  const [index, setIndex] = useState(-1);
  const [lightboxReviews, setLightboxReviews] = useState([]);

  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddReview": {
        return {
          ...state,
          submitAddReview: action.payload,
        };
      }
      case "setSubmitDeleteReview": {
        return {
          ...state,
          submitDeleteReview: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddReview: {
      flag: "",
      dependency: false,
      data: {},
    },
    submitDeleteReview: {
      flag: "",
      dependency: false,
      data: {},
    },
    refetch: false,
  });
  //!--------- get all reviews-------

  const [Reviews, ReviewsErrors, ReviewsLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ALL_REVIEWS_API,
    "GET",
    "GET",
    state.refetch
  );

  useEffect(() => {
    if (Reviews) {
      setLightboxReviews(Reviews?.data);
    }
  }, [Reviews]);

  //!--------- add  Review -------

  const [AddReviewSuccess, AddReviewErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_REVIEW_API,
    "POST",
    state.submitAddReview.flag,
    state.submitAddReview.dependency,
    state.submitAddReview.data,
    true
  );
  //! ---- delete Review ----
  const [DeleteReviewSuccess, DeleteReviewErrors] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_REVIEW_API,
    "POST",
    state.submitDeleteReview.flag,
    state.submitDeleteReview.dependency,
    state.submitDeleteReview.data,
    true
  );

  useEffect(() => {
    if (AddReviewSuccess || DeleteReviewSuccess) {
      dispatch({
        type: "setRefetch",
      });
      reset();
    }
  }, [AddReviewSuccess, DeleteReviewSuccess]);

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitAddReview",
      payload: {
        flag: "AddReview",
        dependency: !state.submitAddReview.dependency,
        data: {
          img: data?.img[0],
        },
      },
    });
  };
  function handleDeleteReview(id) {
    dispatch({
      type: "setSubmitDeleteReview",
      payload: {
        flag: "DeleteReview",
        dependency: !state.submitDeleteReview.dependency,
        data: { id: id },
      },
    });
  }
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Reviews | Mr.Mohamed Salama"></HelmetTags>{" "}
      <h2 className="mb-7 text-start text-2xl font-bold sm:text-center">
        Review~s
      </h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("reviews_add")
        )) && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 max-w-[500px] flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50 h-44"
        >
          <h2>Add Review</h2>
          {/** file  */}
          <div className="flex justify-between items-start w-full">
            <div className="flex !w-3/4 flex-col items-start justify-center  gap-2  md:w-full">
              <input
                id="img"
                className="signin-inputs   w-full !rounded-r-none !h-9 !border-r-0"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/webp"
                name="img"
                {...register("img", {
                  required: true,
                  validate: (value) => !(value[0]?.size > 5000000),
                })}
              />

              {errors.img && (
                <p className="mt-2  text-xs text-red-500">
                  {errors.img.type === "required" && "This field is required."}
                  {errors.img.type === "validate" && "File Max Size is 15 MB."}
                </p>
              )}
              {
                //!-------server errors -----

                AddReviewErrors &&
                  AddReviewErrors?.response?.data?.errors?.img && (
                    <p className=" text-xs text-red-500  ">
                      {AddReviewErrors?.response?.data?.errors?.img[0]}
                    </p>
                  )
              }
            </div>

            {/** submit */}

            <button
              disabled={
                !isValid || (submitLoading && state.submitAddReview.flag)
              }
              className=" submit  !w-1/4 !rounded-l-none !bg-blue !text-bg hover:!bg-white hover:!text-blue hover:!border-blue active:!scale-100 !h-9"
              type="submit"
            >
              {submitLoading && state.submitAddReview.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <p>{Reviews?.is_found === 1 ? "Edit File" : "Add Review"}</p>
              )}
            </button>
          </div>
        </form>
      )}
      <h2 className="font-semibold text-lg my-10">All Reviews</h2>
      <div>
        {
          <div className="Reviews w-full ">
            {ReviewsErrors && typeof ReviewsErrors === "string" ? (
              <p>{ReviewsErrors}</p>
            ) : Reviews?.data?.length === 0 ? (
              <p className="w-full text-center">There are No Reviews Yet.</p>
            ) : (
              <div className={`Reviews  mt-10 reviews-auto-fit w-full `}>
                {Reviews?.data?.map((review, index) => (
                  <div
                    key={review.key}
                    className="review-rapper border-2 border-secondary p-4 w-[350px] aspect-square sm:w-[300px] rounded-xl overflow-hidden"
                  >
                    <div className="image-delete-btn-wrapper rounded-xl w-full h-full overflow-hidden relative">
                      {(AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("reviews_delete")
                        )) && (
                        <Popconfirm
                          okText="Confirm"
                          okType="danger"
                          cancelText="Cancel"
                          color="#ffffff"
                          //okButtonProps={{ loading: state.loading }}
                          onConfirm={() => handleDeleteReview(review.key)}
                          title="Are you sure you want to delete?"
                        >
                          <button className="type-edit-icon w-7 h-7  flex justify-center items-center border-2 border-delete text-white  rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-transparent bg-delete hover:text-delete hover:border-delete absolute right-3 top-3">
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </Popconfirm>
                      )}
                      <img
                        onClick={() => setIndex(index)}
                        className=" w-full h-full object-cover cursor-pointer"
                        src={review.src}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
                <Lightbox
                  slides={Reviews?.data}
                  open={index >= 0}
                  index={index}
                  close={() => setIndex(-1)}
                  plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
              </div>
            )}
          </div>
        }
      </div>
    </section>
  );
}

export default Reviews;
