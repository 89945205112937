import { useCallback, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import ChatMessageList from "./sections/chatMessageList/ChatMessageList";
import ChatView from "./sections/chatView/ChatView";
import style from "./styles/chatAdmin.module.scss";
import { useEffect } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import Analytics from "./sections/analytics/Analytics";

const ChatAdmin = ({ superadmin }) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [refatch, setRefatch] = useState(false);
  const [chatUsers, setChatUsers] = useState([]);
  const [msgsType, setMsgsType] = useState("");

  const [chatUsersData, chatUsersDataErrors, chatUsersDataLoading] = useAxios(
    superadmin
      ? process.env.REACT_APP_SUPERADMIN_CHATS_API
      : process.env.REACT_APP_ADMIN_CHATS_API,
    "GET",
    "GET",
    refatch,
    ""
  );

  useEffect(() => {
    setChatUsers(chatUsersData ? [...chatUsersData?.data] : []);
  }, [chatUsersData]);

  useEffect(() => {
    function filterdDataOnRender() {
      const filterdData =
        msgsType === "readed"
          ? chatUsersData?.data.filter((user) => user.is_readed !== 1)
          : chatUsersData?.data.filter((user) => user.is_readed === 1);
      setChatUsers(
        msgsType === "readed" || msgsType === "unreaded"
          ? filterdData
          : chatUsersData?.data
      );
    }
    filterdDataOnRender();
  }, [chatUsersData, msgsType]);

  return (
    <section
      className={`${style.chatAdmin} h-full w-full overflow-y-auto px-10 py-10 sm:px-4`}
    >
      <HelmetTags title="Chats | Mr.Mohamed Salama"></HelmetTags>
      <h2 className="mb-7 text-start text-2xl font-bold sm:text-center">
        All Chats
      </h2>
      {superadmin && (
        <Analytics unreadedMsg={chatUsersData?.unreaded_messages} />
      )}
      <div className="lef-right-container flex h-fit w-full items-start gap-6 lg:flex-col lg:items-center lg:justify-start lg:gap-20 ">
        <ChatMessageList
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          setSelectedUserInfo={setSelectedUserInfo}
          chatUsers={chatUsers}
          refatch={refatch}
          msgsType={msgsType}
          setMsgsType={setMsgsType}
          chatUsersDataLoading={chatUsersDataLoading}
        />
        {selectedUserId !== null && (
          <ChatView
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            selectedUserInfo={selectedUserInfo}
            setRefatch={setRefatch}
            refatch={refatch}
            superadmin={superadmin}
            chatUsersDataLoading={chatUsersDataLoading}
            msgsType={msgsType}
            setChatUsers={setChatUsers}
          />
        )}
      </div>
    </section>
  );
};

export default ChatAdmin;
