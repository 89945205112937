import { Navigate, Outlet, useLocation } from "react-router-dom";

function ProtectedAdminRoutes({ isAllowed, redirectPath = "/", children }) {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  } else {
    return children ? children : <Outlet />;
  }
}

export default ProtectedAdminRoutes;
