import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useRef, useState } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import Loader from "../../../../MainComponents/Loader";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

const LateStudents = () => {
  const [academiclevel, setAcademicLevel] = useState(1);
  const [days, setDays] = useState(1);
  const daysRef = useRef();
  const [reFetch, setReFetch] = useState(false);
  const [academicType, setAcademicType] = useState("scientific");

  const handleChangeDays = () => {
    setDays(daysRef.current.value);
  };

  // const viewAllLateStudentsTable = useTable(
  //   "viewAllLateStudents",
  //   "",
  //   "",
  //   academiclevel,
  //   days,
  //   { academiclevel, days }
  // );

  const [getAllStudent, getAllStudentErrors, getAllStudentloading] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSE_VIEW_ALL_LATE_STUDENTS_API}/${academiclevel}/${days}`,
    "GET",
    "GET",
    academiclevel,
    ""
  );

  const data =
    +academiclevel === 2 || +academiclevel === 3
      ? getAllStudent?.data?.filter((data) => data.section === academicType)
      : getAllStudent?.data;

  const viewAllLateStudentsTable = useTable(
    "viewAllLateStudents",
    "",
    "",
    academicType,
    getAllStudent?.data,
    "",
    true,
    data,
    reFetch,
    setReFetch
  );

  if (getAllStudentloading) return <Loader />;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Late Students | Mr.Mohamed Salama" />
      <h2 className=" text-start text-2xl font-bold ">Late Students</h2>

      <div
        className={`filters text-center flex justify-between  items-center w-1/2 md:w-full md:flex-col md:px-6 relative my-4 transition-all duration-200 ease-in-out`}
      >
        <button
          onClick={() => setAcademicLevel(1)}
          className={`z-10 py-2 w-1/3 text-base font-semibold  md:w-full ${
            academiclevel === 1 && "rounded-md bg-green duration-300"
          } `}
        >
          Stage 1
        </button>
        <button
          onClick={() => setAcademicLevel(2)}
          className={`z-10 py-2 w-1/3 text-base font-semibold  md:w-full ${
            academiclevel === 2 && "rounded-md bg-green duration-300"
          } `}
        >
          Stage 2
        </button>
        <button
          onClick={() => setAcademicLevel(3)}
          className={`z-10 py-2 w-1/3 text-base font-semibold  md:w-full ${
            academiclevel === 3 && "rounded-md bg-green duration-300"
          } `}
        >
          Stage 3
        </button>
      </div>

      {+academiclevel === 2 || +academiclevel === 3 ? (
        <div
          className={`mt-10 filters-container bg-light-gray w-full flex justify-center `}
        >
          <div
            className={`filters flex justify-between  items-center w-[800px] h-12 relative amd-hero:flex-col  amd-hero:w-28  transition-all duration-200 ease-in-out`}
          >
            {/* <div
                onClick={() => {
                  dispatch({ type: "setToggleFilter" });
                  dispatch({ type: "setTop", payload: "top-0" });
                }}
                className="ALL hidden w-1/5 amd-hero:flex justify-center items-center h-full min-h-[calc(450px/9)] cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)]  amd-hero:w-28 "
              >
                <FontAwesomeIcon
                  className={`hidden amd-hero:block  font-bold text-2xl transition-all duration-200 ease-in-out ${
                    state.toggleFilter && "rotate-180"
                  }`}
                  icon={faChevronDown}
                />
              </div> */}

            <div
              onClick={() => {
                setAcademicType("scientific");
              }}
              className={`calculus  w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28  transition-{height} duration-200 ease-in-out  ${
                academicType === "scientific" ? "bg-green" : ""
              }`}
            >
              Scientific
            </div>

            <div
              onClick={() => {
                setAcademicType("literary");
              }}
              className={`Algebra w-1/3  flex justify-center items-center h-full cursor-pointer z-10 font-semibold amd-hero:h-[calc(450px/9)] amd-hero:w-28 transition-{height} duration-200 ease-in-out ${
                academicType === "literary" ? "bg-green" : ""
              }`}
            >
              Literary
            </div>
          </div>
        </div>
      ) : null}

      <div className="my-6 flex w-full items-start gap-16 md:flex-col md:gap-10 ">
        <div className="flex w-1/2 flex-col items-start justify-center gap-2 md:order-1 md:w-full">
          <input
            className="signin-inputs w-full"
            type="number"
            id="days"
            min={1}
            placeholder="Days"
            name="days"
            autoComplete="on"
            ref={daysRef}
          />
          {/* Username validation errors */}
        </div>
        <button
          className=" submit !w-fit md:w-full !px-6 text-center"
          type="button"
          onClick={handleChangeDays}
        >
          Change Days
        </button>
      </div>

      <div className="flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-star">
        <div className="h-fit  w-full">{viewAllLateStudentsTable}</div>
      </div>
    </section>
  );
};

export default LateStudents;
