import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAxios from "../../../../../MainComponents/Hooks/useAxios.jsx";
import AddEditChooseQuestion from "../../QuestionBank/AddEditChooseQuestion.jsx";
import AddEditEssayQuestion from "../../QuestionBank/AddEditEssayQuestion.jsx";
import QuestionBankCategory from "../../QuestionBank/QuestionBankCategory.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AddSetsForm from "./AddSetsForm.jsx";
import SetsTable from "./SetsTable.jsx";
import HelmetTags from "../../../../../MainComponents/HelmetTags.jsx";
import { useAuthState } from "../../../../../MainComponents/GlobalContext.jsx";
import Loader from "../../../../../MainComponents/Loader.jsx";

function AdminQuizHomework({ homework, exam }) {
  const AuthState = useAuthState();
  const { ID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditInfo": {
        return {
          ...state,
          submitAddEditInfo: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditInfo: {
      flag: "",
      dependency: false,
      data: {},
    },
    info: "",
  });
  const [values, setValues] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [questionType, setQuestionType] = useState("choose");

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let infoApi = exam
    ? `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${ID}}`
    : `${process.env.REACT_APP_ADMIN_SESSION_CONTENT_INFO_API}${ID}`;
  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    infoApi,
    "GET",
    "GET",
    refetch
  );

  useEffect(() => {
    if (getInfoSuccess?.is_found === 1) {
      if (homework) {
        const { title, v_model, success_rate } =
          getInfoSuccess?.data?.Homework_info[0];

        setValues({
          title,
          v_model: v_model === 1 ? true : false,
          success_rate,
        });
      } else {
        const { duration, v_model, success_rate } =
          getInfoSuccess?.data?.quiz_info[0];

        setValues({
          duration,
          v_model: v_model === 1 ? true : false,
          success_rate,
        });
      }
    }
  }, [getInfoSuccess]);

  //!--------- add edit quiz / homework info  -------

  let addEditInfoApi =
    homework && getInfoSuccess?.is_found === 1
      ? process.env.REACT_APP_ADMIN_SESSION_EDIT_HOMEWORK_API
      : homework && getInfoSuccess?.is_found === undefined
      ? process.env.REACT_APP_ADMIN_SESSION_ADD_HOMEWORK_API
      : getInfoSuccess?.is_found === 1 && getInfoSuccess?.type === "quiz"
      ? process.env.REACT_APP_ADMIN_SESSION_EDIT_QUIZ_API
      : getInfoSuccess?.is_found === 0 && getInfoSuccess?.type === "quiz"
      ? process.env.REACT_APP_ADMIN_SESSION_ADD_QUIZ_API
      : "";

  const [AddEditInfoSuccess, AddEditInfoErrors, AddEditInfoLoading] = useAxios(
    addEditInfoApi,
    "POST",
    state.submitAddEditInfo.flag,
    state.submitAddEditInfo.dependency,
    state.submitAddEditInfo.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values,
  });

  useEffect(() => {
    //!---add actions ----

    if (AddEditInfoSuccess) {
      setRefetch(!refetch);
    }
  }, [AddEditInfoSuccess]);

  function onSubmitAddEditInfo(data) {
    if (homework) {
      const { title, v_model, success_rate } = data;
      let finalData =
        getInfoSuccess?.is_found === 1
          ? {
              homework_id: getInfoSuccess?.data?.Homework_info[0]?.key,
              title,
              v_model: v_model === true ? 1 : 0,
              success_rate,
            }
          : getInfoSuccess?.is_found === undefined
          ? { content_id: ID, ...data }
          : {};
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    } else {
      const { duration, v_model, success_rate } = data;
      let finalData =
        getInfoSuccess?.is_found === 1
          ? {
              quiz_id: getInfoSuccess?.data?.quiz_info[0]?.key,
              duration,
              v_model: v_model === true ? 1 : 0,
              success_rate,
            }
          : getInfoSuccess?.is_found === 0
          ? { content_id: Number(ID), ...data }
          : {};
      dispatch({
        type: "setSubmitAddEditInfo",
        payload: {
          flag: "Quiz",
          dependency: !state.submitAddEditInfo.dependency,
          data: finalData,
        },
      });
    }
  }

  return (
    <section className="flex h-auto w-full flex-col items-end gap-16 overflow-y-auto px-10 py-16 dark:bg-dark dark:text-light sm:px-4">
      <HelmetTags title="Sessions | Mr.Mohamed Salama"></HelmetTags>{" "}
      {AddEditInfoLoading || (getInfoLoading && <Loader />)}
      <div className="add-quiz-homework flex w-full flex-col items-center gap-14 md:items-center">
        {getInfoSuccess && !exam && (
          <h2 className="w-full text-center text-xl font-semibold">
            {getInfoSuccess?.is_found === 1 && homework
              ? "Edit Homework"
              : getInfoSuccess?.is_found === 1
              ? "Edit Quiz"
              : homework
              ? "Add Homework"
              : "Add Quiz"}
          </h2>
        )}
        {/**
         * //!--- add exam duration or homework name -------
         */}
        {!exam &&
          (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            (homework &&
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_homeworks_permissions")
              )) ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_quizzes_permissions")
            )) &&
          !AddEditInfoLoading && (
            <form
              onSubmit={handleSubmit(onSubmitAddEditInfo)}
              method="post"
              className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
            >
              <div className="flex flex-col w-full items-center justify-evenly gap-10 md:flex-col md:gap-10 ">
                {homework ? (
                  <div className="title flex w-full md:w-full flex-col items-start  justify-center  gap-2">
                    <label htmlFor="title">Name</label>
                    <input
                      className="signin-inputs w-full     "
                      type="text"
                      id="title"
                      placeholder="Name"
                      title="title"
                      autoComplete="on"
                      {...register("title", {
                        required: true,
                        //pattern: /^[A-Za-z]+$/,
                        maxLength: 255,
                        minLength: 3,
                      })}
                    />
                    {errors.title && (
                      <p className="text-xs text-red-500 ">
                        {errors.title.type === "required" &&
                          "This field is required."}
                        {errors.title.type === "pattern" &&
                          "Only English Letters are Allowed."}
                        {errors.title.type === "maxLength" &&
                          "Allowed Name Max Length is 255."}
                        {errors.title.type === "minLength" &&
                          "Please Enter at Least 3 Letters."}
                      </p>
                    )}

                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors?.title && (
                          <p className="text-xs text-red-500">
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.title[0]
                            }
                          </p>
                        )
                    }
                  </div>
                ) : (
                  <div
                    //!--duration--
                    className=" duration flex  w-full flex-col items-start justify-center gap-2 md:w-full"
                  >
                    <label className="w-fit truncate" htmlFor="duration">
                      Duration (Minutes)
                    </label>
                    <input
                      className="signin-inputs w-full "
                      type="number"
                      min={1}
                      inputMode="numeric"
                      id="duration"
                      max={255}
                      placeholder="Minutes"
                      name="duration"
                      autoComplete="on"
                      {...register("duration", {
                        valueAsNumber: true,
                        min: 1,
                        required: true,
                      })}
                    />

                    {errors.duration && (
                      <>
                        <p className="text-xs text-red-500">
                          {errors.duration?.type === "required" &&
                            "This field is required."}{" "}
                          {errors.duration?.type === "min" &&
                            "Minimum Number is one."}{" "}
                        </p>
                      </>
                    )}
                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors?.duration && (
                          <p
                            className="text-xs text-red-500
  "
                          >
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.duration[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}
                <div
                  //!--success_rate--
                  className=" success_rate flex  w-full flex-col items-start justify-center gap-2 md:w-full"
                >
                  <label className="w-fit truncate" htmlFor="success_rate">
                    Success Rate
                  </label>
                  <input
                    className="signin-inputs w-full "
                    type="number"
                    min={1}
                    inputMode="numeric"
                    id="success_rate"
                    max={100}
                    placeholder="Success Rate"
                    name="success_rate"
                    autoComplete="on"
                    {...register("success_rate", {
                      valueAsNumber: true,
                      min: 1,
                      required: true,
                    })}
                  />

                  {errors.success_rate && (
                    <>
                      <p className="text-xs text-red-500">
                        {errors.success_rate?.type === "required" &&
                          "This field is required."}{" "}
                        {errors.success_rate?.type === "min" &&
                          "Minimum Number is one."}{" "}
                      </p>
                    </>
                  )}
                  {
                    //!-------server errors -----

                    AddEditInfoErrors &&
                      AddEditInfoErrors?.response?.data?.errors?.duration && (
                        <p
                          className="text-xs text-red-500
  "
                        >
                          {
                            AddEditInfoErrors?.response?.data?.errors
                              ?.duration[0]
                          }
                        </p>
                      )
                  }
                </div>
                {getInfoSuccess?.is_found === 1 && (
                  <div className=" v_model flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
                    <div className="flex w-full items-center justify-center md:justify-center gap-10">
                      <label className="visibility-switch">
                        <input
                          className=""
                          id="v_model"
                          name="v_model"
                          {...register("v_model", {
                            required: false,
                          })}
                          type="checkbox"
                        />
                        <span className="visibility-slider">
                          <svg
                            className="slider-icon"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <path fill="none" d="m4 16.5 8 8 16-16"></path>
                          </svg>
                        </span>
                      </label>
                      <div>
                        <label
                          className="w-full cursor-pointer truncate"
                          htmlFor="v_model"
                        >
                          Visibility
                        </label>
                      </div>
                    </div>

                    {errors.v_model && (
                      <p
                        className="text-xs text-red-500
 "
                      >
                        {errors.v_model.type === "required" &&
                          "This field is required."}{" "}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      AddEditInfoErrors &&
                        AddEditInfoErrors?.response?.data?.errors?.v_model && (
                          <p
                            className="text-xs text-red-500
  "
                          >
                            {
                              AddEditInfoErrors?.response?.data?.errors
                                ?.v_model[0]
                            }
                          </p>
                        )
                    }
                  </div>
                )}
              </div>

              {/** submit */}

              <button
                disabled={
                  !isValid ||
                  (AddEditInfoLoading && state.submitAddEditInfo.flag)
                }
                className=" submit mt-6 w-full "
                type="submit"
              >
                {AddEditInfoLoading && state.submitAddEditInfo.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <p>
                    {" "}
                    {getInfoSuccess?.is_found === 1 && homework
                      ? "Edit Homework"
                      : getInfoSuccess?.is_found === 1
                      ? "Edit Quiz"
                      : homework
                      ? "Add Homework"
                      : "Add Quiz"}
                  </p>
                )}
              </button>
            </form>
          )}
      </div>
      {getInfoSuccess?.is_found === 1 || exam ? (
        <>
          <div className="category-questions flex w-full flex-col items-center gap-14 md:items-center ">
            <h2 className="text-center text-xl font-semibold">
              Choose Questions from Categories
            </h2>
            {/*
             */}
            <AddSetsForm
              type={homework ? "homework" : exam ? "exam" : "quiz"}
              id={ID}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          </div>

          {getInfoSuccess?.data?.sets?.length > 0 && (
            <div className="category-questions flex w-full flex-col items-center gap-2 md:items-center ">
              <h2 className="text-center text-xl font-semibold">
                All Sets Information{" "}
              </h2>
              {getInfoSuccess?.data?.sets?.length > 0 && (
                <SetsTable
                  id={
                    homework
                      ? ID
                      : exam
                      ? getInfoSuccess?.data?.quiz_info[0]?.key
                      : ID
                  }
                  tableFor={exam ? "examSets" : "sets"}
                />
              )}
            </div>
          )}

          <div className="custom-question  w-full ">
            <h2 className="text-center text-xl font-semibold">
              Add Custom Question
            </h2>
            <div className="custom-question-options mt-14 flex  gap-10 justify-center">
              <button
                onClick={() => setQuestionType("essay")}
                className={`  rounded-2xl border-2 border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-bg active:scale-90 ${
                  questionType === "essay" ? "bg-secondary text-bg" : ""
                }`}
              >
                Essay Question
              </button>
              <button
                onClick={() => setQuestionType("choose")}
                className={`  rounded-2xl border-2  border-secondary  px-3 py-1 text-center font-semibold  duration-300 hover:bg-secondary hover:text-bg  active:scale-90 ${
                  questionType === "choose" ? "bg-secondary text-bg" : ""
                }`}
              >
                Choose Questions
              </button>
            </div>
            {questionType === "choose" ? (
              <AddEditChooseQuestion
                type={homework ? "homework" : exam ? "exam" : "quiz"}
                id={ID}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            ) : (
              <AddEditEssayQuestion
                type={homework ? "homework" : exam ? "exam" : "quiz"}
                id={ID}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}
          </div>
          {getInfoSuccess?.data?.Questions?.length > 0 && (
            <div className="all-custom-questions w-full ">
              <h2 className="text-center text-xl font-semibold">
                Custom Questions
              </h2>
              <QuestionBankCategory
                type={homework ? "homework" : "quiz"}
                ID={ID}
                QuizQuestionsData={getInfoSuccess?.data?.Questions}
                setRefetchQuiz={setRefetch}
                refetchQuiz={refetch}
              />
            </div>
          )}
        </>
      ) : null}
    </section>
  );
}

export default AdminQuizHomework;
