import { useEffect, useRef } from "react";
import Message from "./Message";

const ChatContent = ({ messages, selectedUserId, setSelectedUserId }) => {
  const chatContentRef = useRef(null);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="studentChatContent" ref={chatContentRef}>
      <div className="typeItems">
        {messages.map((msg, i) => (
          <Message msg={msg} key={i} setSelectedUserId={setSelectedUserId} />
        ))}
      </div>
    </div>
  );
};

export default ChatContent;
