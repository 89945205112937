import React, { useEffect } from "react";
import useCountDown from "../../../../MainComponents/Hooks/useCountDown";
import { useNavigate } from "react-router-dom";

const SingleCourseCountDown = ({ expireDate }) => {
  const { days, hours, minutes, seconds } = useCountDown(expireDate);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (
  //     (days === "00" && hours === "00" && minutes === "00", seconds === "00")
  //   ) {
  //     //   navigate("/courses");
  //   }
  // }, [days, hours, minutes, seconds]);

  // console.log(
  //   days === "00" && hours === "00" && minutes === "00",
  //   seconds === "00"
  // );
  return (
    <div
      className={`title-description border-2 border-blue p-3 rounded-xl mt-5 w-3/4 md:w-full  text-start"`}
    >
      <p className="font-bold text-md mb-2">Expired After</p>
      <p className="course-description text-base">
        {days} days {hours} Hrs {minutes} Min {seconds} Sec
      </p>
    </div>
  );
};

export default SingleCourseCountDown;
