import { useEffect } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

const AdminAddStudentToCourse = () => {
  const { courseID } = useParams();
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false);
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [addStudentToCourseSucsses, addStudentToCourseErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_COURSE_VIEW_ADD_STUDENT_API,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "addStudentToCourse",
      dependency: !submitGenerate.dependency,
      data: { ...data, id: courseID },
    });
  };

  useEffect(() => {
    //! reset the form on success submit
    if (addStudentToCourseSucsses) {
      reset();
      setRefetch(!refetch);
      navigate(`/admin/courses/view/${courseID}`);
    }
  }, [addStudentToCourseSucsses, courseID, navigate, refetch, reset]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags
        title={"Add Student To Course | Mr.Mohamed Salama"}
      ></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        Add Student To Course
      </h2>
      <div className="flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-star">
        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="shadow-l flex h-fit w-3/4 md:w-full  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-64 "
        >
          <div className="w-full items-start justify-between gap-6 md:flex-col md:justify-start">
            {/* Type messages field */}
            <div className="flex flex-col items-end justify-center gap-2 md:w-full">
              <label htmlFor="mobile">Mobile</label>
              <input
                className="signin-inputs w-full mt-2"
                type="number"
                id="mobile"
                placeholder="Mobile"
                name="mobile"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />

              {/* Mobile number validation errors */}
              {errors.mobile && (
                <p className="mt-2 text-xs text-red-500">
                  {errors.mobile.type === "required" &&
                    "Please, fill this input"}
                  {errors.mobile.type === "pattern" &&
                    "Enter a phone number with 11 digits"}
                  {errors.mobile.type === "maxLength" &&
                    "Enter a phone number with 11 digits"}
                </p>
              )}
              {/* Server errors for mobile number */}
              {addStudentToCourseErrors &&
                addStudentToCourseErrors?.response?.data?.errors?.mobile && (
                  <p className="text-xs text-red-500">
                    {
                      addStudentToCourseErrors?.response?.data?.errors
                        ?.mobile[0]
                    }
                  </p>
                )}
            </div>
          </div>

          {/** submit */}
          <button
            disabled={!isValid || (submitLoading && submitGenerate.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitGenerate.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Add Student"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddStudentToCourse;
