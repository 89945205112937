import { useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom";
import { useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import HelmetTags from "../../../../MainComponents/HelmetTags";

export default function AdminTopStudents() {


    function reducer(state, action) {
        switch (action.type) {
          case "setSumbitTopStudents": {
            return {
              ...state,
              submitTopStudents: action.payload,
            };
          }
    
          default:
            throw Error("Unknown action: " + action.type);
        }
      }
      const [state, dispatch] = useReducer(reducer, {
        submitTopStudents: {
          flag: "",
          dependency: false,
          data: {},
        },
      });
    const {ID} = useParams();

    const [TopStudentsSuccess, TopStudentsErrors, submitLoading] = useAxios(
        process.env.REACT_APP_ADMIN_COURSE_VIEW_TOP_STUDENTS,
        "POST",
        state.submitTopStudents.flag,
        state.submitTopStudents.dependency,
        state.submitTopStudents.data,
        true
      );

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
        reset,
      } = useForm({
        mode: "onTouched",
      });
      const onSubmit = (data) => {
        const finalData = ID
          ? {
              ...data,
             exam_id: ID,
              
            }
          : {
              ...data,
            };
        dispatch({
          type: "setSumbitTopStudents",
          payload: {
            flag: "TopStudents",
            dependency: !state.submitTopStudents.dependency,
            data: finalData,
          },
        });
      };
  return (
    <>
    <HelmetTags title={'Top Student | Mr.Mohamed Salama'}></HelmetTags>
    <section className="dark:bg-dark dark:text-light h-screen w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-center  ">
        <form onSubmit={handleSubmit(onSubmit)}  method="post"
          className="flex max-w-[350px] w-full flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50" >
            <label htmlFor="degree" className="text-center text-4xl font-semibold">Student Degree</label>
            <input type="number" placeholder="Degree" className="w-full py-2 px-3 border border-blue text-blue rounded-md outline-blue" {...register("student_degree", {
                  required: true,
                })}/>
           <button
  type="submit"
  className="mx-auto text-center border border-blue rounded-lg hover:bg-blue hover:text-white px-3 py-2"
  disabled={!isValid || (submitLoading && state.submitTopStudents.flag)}
>
  {submitLoading && state.submitTopStudents.flag ? (
    <FontAwesomeIcon icon={faSpinner} spin />
  ) : (
    <>Submit</>
  )}
    </button>
        </form>
        {submitLoading ? <></> : TopStudentsSuccess && 
          <div className="w-full flex items-end py-10">
          <a className="mx-auto" href={TopStudentsSuccess?.data?.zip} >
          
          <button className="mx-auto text-center border border-blue rounded-lg bg-blue text-white hover:bg-blue/50 hover:text-white px-3 py-2">Download Images</button>
          
          </a>
          </div>
        }
        
       
        <div className="flex flex-wrap gap-5 items-center justify-center">
        {submitLoading ? <Loader/> : TopStudentsSuccess && TopStudentsSuccess?.data?.images?.map((item,idx)=>(
    <div  className="w-full max-w-[450px] h-[450px] roundend-md" key={idx}>
      <img src={item.url} alt={item.name} />
    </div>
  ))}
        </div>

    </section>
    </>
    
  )
}
