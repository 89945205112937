import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";

import Loader from "../../../MainComponents/Loader.jsx";
import OfferCard from "../../../MainComponents/OfferCard.jsx";

import StudentCoursesCard from "./components/StudentCoursesCard.jsx";

function StudentCourses({ teacher }) {
  const [left, setLeft] = useState("left-0");
  const [top, setTop] = useState("top-0");
  const [name, setName] = useState("ALL");
  const [coursesData, setCoursesData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [allCoursesData, allCoursesErrors, loading] = useAxios(
    process.env.REACT_APP_STUDENT_COURSES_API,
    "GET",
    "GET"
  );

  const handelSetFilter = (type) => {
    if (type === "all") {
      setCoursesData(allCoursesData?.data);
    } else {
      setCoursesData(
        allCoursesData.data.filter((course) => course.branch === type)
      );
    }
  };

  useEffect(() => {
    setCoursesData(allCoursesData?.data);
  }, [allCoursesData?.data]);

  return (
    <section className="h-auto width">
      {/* {allCoursesData?.data?.length > 0 && (
        <div className="offers-section">
          <h2 className=" text-center text-3xl font-bold mt-12">Offers</h2>
          <div className={`offers  mt-10 grid-auto-fit w-full `}>
            {allCoursesData?.data?.map((offer) => {
              return <OfferCard key={offer.key} offer={offer} />;
            })}
          </div>
        </div>
      )} */}
      <HelmetTags title={`Courses | Mr.Mohamed Salama`} />

      <div
        className={`filters-container bg-light-gray mt-10 flex justify-center `}
      >
        <div
          className={`filters flex justify-between  items-center w-[600px] h-12 relative md:flex-col  md:w-20  transition-all duration-200 ease-in-out ${
            toggleFilter ? "md:h-80 " : "md:h-16"
          }`}
          onClick={() => setToggleFilter(!toggleFilter)}
        >
          <div
            onClick={() => {
              handelSetFilter("all");
              setTop("top-0");
              setLeft("left-0");

              setName("ALL");
            }}
            className="ALL w-1/5 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:min-h-[64px] md:w-20 "
          >
            ALL
            <FontAwesomeIcon
              className={`hidden md:block ml-2 font-bold text-lg transition-all duration-200 ease-in-out ${
                toggleFilter && "rotate-180"
              }`}
              icon={faChevronDown}
            />
          </div>

          <div
            onClick={() => {
              handelSetFilter("calculus");
              setLeft("left-[20%]");
              setTop("top-[20%]");

              setName("Calculus");
            }}
            className={`calculus w-1/5 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:w-20  transition-{height} duration-200 ease-in-out  ${
              toggleFilter
                ? "md:opacity-100 md:h-16"
                : "md:opacity-0 md:h-0 md:pointer-events-none"
            }`}
          >
            Calculus
          </div>

          <div
            onClick={() => {
              handelSetFilter("algebra");
              setTop("top-[40%]");
              setLeft("left-[40%]");
              setName("Algebra");
            }}
            className={`Algebra w-1/5 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:w-20 transition-{height} duration-200 ease-in-out ${
              toggleFilter
                ? "md:opacity-100 md:h-16"
                : "md:opacity-0 md:h-0 md:pointer-events-none"
            }`}
          >
            Algebra
          </div>

          <div
            onClick={() => {
              handelSetFilter("statistics");
              setLeft("left-[60%]");
              setName("Statistics");
            }}
            className={`Statistics w-1/5 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:w-20 transition-{height} duration-200 ease-in-out ${
              toggleFilter
                ? "md:opacity-100 md:h-16"
                : "md:opacity-0 md:h-0 md:pointer-events-none"
            }`}
          >
            Statistics
          </div>

          <div
            onClick={() => {
              handelSetFilter("dynamics");
              setLeft("left-[80%]");
              setName("Dynamics");
            }}
            className={`Dynamics w-1/5 flex justify-center items-center h-full cursor-pointer z-10 font-semibold md:h-16 md:w-20 transition-{height} duration-200 ease-in-out ${
              toggleFilter
                ? "md:opacity-100 md:h-16"
                : "md:opacity-0 md:h-0 md:pointer-events-none"
            }`}
          >
            Dynamics
          </div>

          <div
            className={`selector-desktop md:hidden absolute w-1/5 h-full  ${left} md:left-0 bg-green duration-300 rounded-md md:h-16 md:w-20  top-0`}
          ></div>
          <div
            className={`selector-mobile hidden md:block   absolute w-1/5 h-full  left-0 bg-green duration-300 rounded-md md:h-16 md:w-20 ${top} `}
          ></div>
        </div>
      </div>

      <h1 className="course-name text-center text-3xl font-bold mt-12">
        {name} Courses
      </h1>

      <div
        className={`courses grid-auto-fit width ${
          teacher && "sss:justify-items-start"
        } mt-10 `}
      >
        {coursesData?.map((course, index) => {
          return (
            <StudentCoursesCard key={index} course={course} teacher={teacher} />
          );
        })}
        {coursesData?.length === 0 && <p>There are No Courses Yet.</p>}
      </div>
      {loading && <Loader />}
    </section>
  );
}

export default StudentCourses;
