import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ViewsForm = ({ values }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const [viewsValue, setViewsValue] = useState(0);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({ mode: "onTouched" });

  const videosInput = watch("vidoe");
  const viewsInput = watch("views");

  useEffect(() => {
    setValue(
      "views",
      values.filter((value) => value.id === +videosInput)[0]?.views
    );
  }, [values, videosInput]);

  //!--------- add edit assistant -------

  const [updateViewsSucsses, updateViewsErrors, updateViewsLoading] = useAxios(
    process.env.REACT_APP_ADMIN_UPDATE_VIEWS_EXCEPTION_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues({ id: formData.vidoe, views: formData.views });
    setFormFlag("updateViews");
    setFormDependency(true);
  };

  useEffect(() => {
    if (updateViewsSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateViewsSucsses]);

  useEffect(() => {
    if (updateViewsErrors) {
      toast.error(updateViewsErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateViewsErrors]);
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className={`flex flex-col items-end justify-center gap-2 mb-6`}>
        <label className="w-full truncate" htmlFor="course">
          Videos
        </label>
        <select
          name="vidoe"
          id="vidoe"
          {...register("vidoe", { required: true })}
        >
          <option value="">Select a video</option>
          {values.map((value, i) => (
            <option key={i} value={value.id}>
              {value.course}
            </option>
          ))}
        </select>
        {errors.vidoe && (
          <p className="text-xs text-red-500 ">
            {errors.vidoe.vidoe === "required" && "This field is required."}
          </p>
        )}
        {
          //!-------server errors -----

          updateViewsErrors &&
            updateViewsErrors?.response?.data?.errors?.vidoe && (
              <p className="w-full text-end text-xs text-red-500  ">
                {updateViewsErrors?.response?.data?.errors?.vidoe[0]}
              </p>
            )
        }
      </div>
      <div className={`flex flex-col items-end justify-center gap-2`}>
        <label className="w-full truncate" htmlFor="views">
          Views
        </label>
        <input
          className="signin-inputs w-full pl-4    "
          type="number"
          id="views"
          placeholder="views"
          name="views"
          autoComplete="on"
          // value={viewsValue}
          // onChange={(e) => setViewsValue(e.target.value)}
          {...register("views", {
            required: true,
            value: viewsValue,
          })}
        />
        {errors.views && (
          <p className="text-xs text-red-500  ">
            {errors.views.type === "required" && "Please fill this input"}
            {errors.views.type === "pattern" && "Only English allowed"}
            {errors.views.type === "maxLength" && "max characters is 40"}
            {errors.views.type === "minLength" &&
              "Please, write 3 characters at least"}
          </p>
        )}

        {
          //!-------server errors -----

          updateViewsErrors &&
            updateViewsErrors?.response?.data?.errors?.views && (
              <p className="text-xs text-red-500  ">
                {updateViewsErrors?.response?.data?.errors?.views[0]}
              </p>
            )
        }
      </div>
      <button className=" submit mt-6 w-full" type="submit">
        {updateViewsLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Update Views"
        )}
      </button>
    </form>
  );
};

export default ViewsForm;
