import { useState } from "react";
import { useAuthState } from "../../../../../MainComponents/GlobalContext";

const ExecptionType = ({ setType, setMobileNumber, setCourseId }) => {
  const [filterActive, setFilterActive] = useState(1);
  const AuthState = useAuthState();

  const handleSelectExceptionType = (id, type) => {
    setFilterActive(id);
    setType(type);
    setMobileNumber("");
    setCourseId("");
  };

  return (
    <div
      className={`filters flex justify-between  items-center w-full md:flex-col md:px-6 relative my-4 transition-all duration-200 ease-in-out`}
    >
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_courses")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(1, "courses")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 1 && "rounded-md bg-green duration-300"
          } `}
        >
          Courses
        </button>
      ) : null}

      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_sessions")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(2, "session")}
          className={`z20 py-2 w-1/4 text-base font-semibold md:w-full ${
            filterActive === 2 && "rounded-md bg-green duration-300"
          } `}
        >
          Sessions
        </button>
      ) : null}

      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_quizzes")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(3, "quiz")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 3 && "rounded-md bg-green duration-300"
          } `}
        >
          Quizzes
        </button>
      ) : null}

      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_courses")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(4, "homeworks")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 4 && "rounded-md bg-green duration-300"
          } `}
        >
          Homeworks
        </button>
      ) : null}
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_videos")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(5, "video")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 5 && "rounded-md bg-green duration-300"
          } `}
        >
          Videos
        </button>
      ) : null}
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_purchases")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(6, "purchases")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 6 && "rounded-md bg-green duration-300"
          } `}
        >
          Purchases
        </button>
      ) : null}
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_unlock_courses")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(7, "unlockCourse")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 7 && "rounded-md bg-green duration-300"
          } `}
        >
          Unlock Course
        </button>
      ) : null}
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_views")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(8, "views")}
          className={`z-10 py-2 w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 8 && "rounded-md bg-green duration-300"
          } `}
        >
          Views
        </button>
      ) : null}
      {AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
      AuthState.userData?.admin?.permissions?.some((permission) =>
        permission.name.startsWith("exceptions_invisible_courses")
      ) ? (
        <button
          onClick={() => handleSelectExceptionType(9, "invisiblecourses")}
          className={`z-10 py-2 w-1/4 text-base font-semibold whitespace-nowrap  md:w-full px-1 ${
            filterActive === 9 && "rounded-md bg-green duration-300 "
          } `}
        >
          Invisible Courses
        </button>
      ) : null}
    </div>
  );
};

export default ExecptionType;
