import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SessionForm = ({ courseId, mobileNumber, sessionId }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [updateSessionSucsses, updateSessionErrors, updateSessionLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_UPDATE_SESSION_EXCEPTION_API,
      "POST",
      formFlag,
      formDependency,
      formValues
    );

  const onSubmit = (formData) => {
    setFormValues({
      ...formData,
      id: courseId,
      mobile: mobileNumber,
      session_id: sessionId,
    });
    setFormFlag("updateSession");
    setFormDependency(true);
  };

  useEffect(() => {
    if (updateSessionSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateSessionSucsses]);

  useEffect(() => {
    if (updateSessionErrors) {
      toast.error(updateSessionErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateSessionErrors]);
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className={`flex flex-col items-end justify-center gap-2`}>
        <label className="w-full truncate" htmlFor="course">
          Hours
        </label>
        <input
          className="signin-inputs w-full pl-4    "
          type="number"
          id="hour"
          placeholder="Hours"
          name="hour"
          autoComplete="on"
          {...register("hour", {
            required: true,
          })}
        />
        {errors.hour && (
          <p className="text-xs text-red-500  ">
            {errors.hour.type === "required" && "Please fill this input"}
            {errors.hour.type === "pattern" && "Only English allowed"}
            {errors.hour.type === "maxLength" && "max characters is 40"}
            {errors.hour.type === "minLength" &&
              "Please, write 3 characters at least"}
          </p>
        )}

        {
          //!-------server errors -----

          updateSessionErrors &&
            updateSessionErrors?.response?.data?.errors?.hour && (
              <p className="text-xs text-red-500  ">
                {updateSessionErrors?.response?.data?.errors?.hour[0]}
              </p>
            )
        }
      </div>
      <button className=" submit mt-6 w-full" type="submit">
        {updateSessionLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Update Session"
        )}
      </button>
    </form>
  );
};

export default SessionForm;
