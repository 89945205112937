import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditEssayQuestion({
  edit,
  type,
  id,
  lectureQuestion,
  refetch,
  setRefetch,
}) {
  const { categoryID, questionID } = useParams();
  const [question, setQuestion] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [vdo_id, setVideoId] = useState("");
  const [platform, setPlatform] = useState("");
  const [hint, setHint] = useState("");
  const [degree, setDegree] = useState("");
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();
  const [submitQuestion, setSubmitQuestion] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!  reset error messages if the input value has changed
  useEffect(() => {
    if (question !== "") {
      setErrors("");
    } else if (degree !== "") {
      setErrors("");
    } else if (difficulty !== "") {
      setErrors("");
    } else if (vdo_id !== "" && platform !== "") {
      setErrors("");
    }
  }, [question, difficulty, degree, vdo_id, platform]);

  //!---add edit question request
  let api = type
    ? process.env.REACT_APP_ADMIN_QUIZ_ADD_ESSAY_QUESTIONS_API
    : edit
    ? process.env.REACT_APP_EDIT_QUESTION_API
    : `${process.env.REACT_APP_ADD_ESSAY_QUESTION_API}${categoryID}`;

  const [addEditQuestionSuccess, addEditQuestionErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitQuestion.flag,
      submitQuestion.dependency,
      submitQuestion.data,
      true
    );

  //!---get question data request
  let QuestionDataApi = lectureQuestion
    ? `${process.env.REACT_APP_QUIZ_HOMEWORK_QUESTION_INFO_API}${questionID}`
    : `${process.env.REACT_APP_QUESTION_INFO_API}${questionID}`;
  const [QuestionDataSuccess, QuestionErrors] = useAxios(
    QuestionDataApi,
    "GET",
    edit
  );
  useEffect(() => {
    if (QuestionDataSuccess) {
      setDifficulty(QuestionDataSuccess.data?.difficulty);
      setQuestion(QuestionDataSuccess.data?.question);
      setPlatform(QuestionDataSuccess.data?.platform);
      setVideoId(QuestionDataSuccess.data?.vdo_id);
      setHint(QuestionDataSuccess.data?.hint);
      setDegree(QuestionDataSuccess.data?.degree);
    }
  }, [QuestionDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (question === "") {
      setErrors("question");
      toast.error("Please Add Question");
    } else if (platform && !vdo_id) {
      setErrors("vdo_id");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (!platform && vdo_id) {
      setErrors("platform");
      toast.error("Please Add both the Platform and the Video Id.");
    } else if (difficulty === "") {
      setErrors("difficulty");
      toast.error("Please Add Difficulty");
    } else if (degree === "" || Number(degree) < 1) {
      setErrors("degree");
      toast.error("Please Add Degree");
    } else {
      const data = type
        ? {
            question: question,
            vdo_id: vdo_id || null,
            platform: platform || null,
            hint: hint || null,
            degree: degree,
            difficulty: difficulty,
            content_id: id,
          }
        : {
            question: question,
            vdo_id: vdo_id,
            platform: platform,
            hint: hint,
            degree: degree,
            difficulty: difficulty,
            id: edit ? questionID : categoryID,
          };
      setSubmitQuestion({
        flag: "addEditQuestionRequest",
        dependency: !submitQuestion.dependency,
        data: data,
      });
    }
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addEditQuestionSuccess) {
      setQuestion("");
      setDifficulty("");
      setPlatform("");
      setVideoId("");
      setHint("");
      setDegree("");
      setErrors("");
      type ? setRefetch(!refetch) : navigate(-1);
    }
  }, [addEditQuestionSuccess]);

  return (
    <section
      className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light ${
        type ? "pt-10" : "py-20"
      }`}
    >
      {!type && (
        <HelmetTags title="Question Bank | Mr Mohamed Salama"></HelmetTags>
      )}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="form-container  flex flex-col items-center"
      >
        <h2
          className={` text-center text-xl ${
            type ? "mb-14" : "mb-20 font-semibold"
          }`}
        >
          {edit
            ? "Please fill in the fields to edit the question"
            : "Please fill in the fields to add the question"}
        </h2>
        {/**
         * //!------question-----
         */}
        <div className=" question mb-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Question</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={question}
            onChange={(event, editor) => {
              const data = editor.getData();
              setQuestion(data);
            }}
          />
          {errors === "question" && (
            <p className="text-xs text-red-500 "> This field is required. </p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.question && (
                <p className="w-full text-end text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.question[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------hint-----
         */}
        <div className=" answer1 my-10 flex w-[900px] flex-col items-start gap-2 alg:w-[700px] amd:w-[500px] sm:w-[300px]">
          <h2 className="mb-3 text-xl font-semibold">Hint</h2>
          <CKEditor
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "imageInsert",
                  "|",
                  "heading",
                  "bold",
                  "italic",
                  "strikethrough",
                  "underline",
                  "link",
                  "blockQuote",
                  "removeFormat",
                  "|",
                  "selectAll",
                  "specialCharacters",
                  "superscript",
                  "subscript",
                  "style",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "outdent",
                  "indent",
                  "|",
                  "mediaEmbed",
                  "insertTable",
                  "|",
                  "fontFamily",
                  "fontColor",
                  "fontBackgroundColor",
                  "fontSize",
                  "highlight",
                  "|",
                  "horizontalLine",
                  "pageBreak",
                  "findAndReplace",
                  "restrictedEditingException",
                  "textPartLanguage",
                  "-",
                ],
                shouldNotGroupWhenFull: true,
              },
              language: "en",
              image: {
                toolbar: [
                  "imageTextAlternative",
                  "toggleImageCaption",
                  "imageStyle:inline",
                  "imageStyle:block",
                  "imageStyle:side",
                ],
              },
              table: {
                contentToolbar: [
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "tableCellProperties",
                  "tableProperties",
                ],
              },
            }}
            editor={ClassicEditor}
            data={hint}
            onChange={(event, editor) => {
              const data = editor.getData();
              setHint(data);
            }}
          />
          {errors === "hint" && (
            <p className="text-xs text-red-500 ">Please fill in this field</p>
          )}
          {
            //!-------server errors -----

            addEditQuestionErrors &&
              addEditQuestionErrors?.response?.data?.errors?.hint && (
                <p className="text-xs text-red-500  ">
                  {addEditQuestionErrors?.response?.data?.errors?.hint[0]}
                </p>
              )
          }
        </div>
        {/**
         * //!------vdo_id platform -----
         */}{" "}
        {/** vdo_id platform*/}
        <div className="flex w-full items-start justify-between gap-10 md:gap-6 md:flex-col">
          <div className=" vdo_id flex w-1/2  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="vdo_id">
              Video Link{" "}
            </label>
            <input
              className="signin-inputs w-full pl-4    "
              type="text"
              id="vdo_id"
              placeholder="Video Link "
              value={vdo_id}
              onChange={(e) => setVideoId(e.target.value)}
              name="vdo_id"
              autoComplete="on"
            />
            {errors === "vdo_id" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.vdo_id && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.vdo_id[0]}
                  </p>
                )
            }
          </div>
          <div
            className={`flex w-1/2  flex-col items-start justify-center gap-2   md:w-full `}
          >
            <label className="w-full truncate" htmlFor="platform">
              Platform{" "}
            </label>
            <select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
              name="platform"
              id="platform"
            >
              <option hidden disabled value="">
                Platform
              </option>
              <option value="youtube">youtube</option>
              <option value="vimeo">vimeo</option>
              <option value="vdocipher">vdocipher</option>
            </select>

            {errors === "platform" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.platform && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.platform[0]}
                  </p>
                )
            }
          </div>
        </div>
        {/** //!---Difficulty degree
         *
         *       */}
        <div className="flex w-full items-start justify-between gap-10 md:gap-6 md:flex-col mt-5">
          <div
            className={`my-1 flex w-1/2 flex-col items-start justify-center gap-1  md:w-full `}
          >
            <label className="w-full truncate" htmlFor="difficulty">
              Difficulty{" "}
            </label>
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              name="difficulty"
              id="difficulty"
            >
              <option hidden disabled value="">
                Difficulty
              </option>{" "}
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>

            {errors === "difficulty" && (
              <p className="text-xs text-red-500 "> This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.difficulty && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {
                      addEditQuestionErrors?.response?.data?.errors
                        ?.difficulty[0]
                    }
                  </p>
                )
            }
          </div>
          <div className=" Degree flex w-1/2  flex-col items-start justify-center gap-2 md:w-full">
            <label className="w-full truncate" htmlFor="degree">
              Degree
            </label>
            <input
              className="signin-inputs w-full "
              type="number"
              inputMode="numeric"
              min={1}
              id="degree"
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
              placeholder="000"
              name="level"
              autoComplete="on"
            />

            {errors === "degree" && (
              <p className="text-xs text-red-500">This field is required. </p>
            )}
            {
              //!-------server errors -----

              addEditQuestionErrors &&
                addEditQuestionErrors?.response?.data?.errors?.degree && (
                  <p className=" text-xs text-red-500  ">
                    {addEditQuestionErrors?.response?.data?.errors?.degree[0]}
                  </p>
                )
            }
          </div>
        </div>
        <button
          disabled={submitLoading && submitQuestion.flag}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && submitQuestion.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p> {edit ? "Edit Question" : "Add Question"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditEssayQuestion;
