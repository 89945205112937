import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";

const CodesTracker = () => {
  const [refetch, setRefetch] = useState(false);
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  const [trackCodesSuccess, trackCodesErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_CODE_TRACKER_API,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };

  useEffect(() => {
    //! reset the form on success submit

    if (trackCodesSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [trackCodesSuccess]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"Codes Tracker | Mr.Mohamed Salama"}></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        Codes Tracker
      </h2>

      <div className="form-codes-container my-20">
        <h2 className="my-10 text-center text-xl font-semibold">
          Please fill the form to Know the Codes Tracker
        </h2>
        {trackCodesErrors && (
          <p className={`text-center font-bold mb-6 text-red-700`}>
            {trackCodesErrors}
          </p>
        )}
        {trackCodesSuccess?.message && (
          <p className={`text-center font-bold mb-6 `}>
            {trackCodesSuccess?.message}
          </p>
        )}

        <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="shadow-l flex h-fit w-96  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-64 "
          >
            <div className="first-row flex w-full items-start justify-between gap-6 md:flex-col md:justify-start">
              {/** Type of codes field */}

              <div
                className={`flex flex-col items-end justify-center gap-2 w-1/2 md:w-full`}
              >
                <label className="w-full truncate" htmlFor="is_code">
                  Type Of Code
                </label>
                <select
                  name="type"
                  id="is_code"
                  {...register("is_code", { required: true })}
                >
                  <option value="1">Codes</option>
                  <option value="0">Offers Codes</option>
                </select>

                {errors.is_code && (
                  <p className="text-xs text-red-500 ">
                    {errors.is_code.is_code === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  trackCodesErrors &&
                    trackCodesErrors?.response?.data?.errors?.is_code && (
                      <p className="w-full text-end text-xs text-red-500  ">
                        {trackCodesErrors?.response?.data?.errors?.is_code[0]}
                      </p>
                    )
                }
              </div>

              {/** seryal code field */}
              <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="code_id">
                  Serial Number
                </label>
                <input
                  className="signin-inputs w-full "
                  type="number"
                  min={1}
                  id="code_id"
                  placeholder="000"
                  name="code_id"
                  autoComplete="on"
                  {...register("code_id", {
                    required: true,
                    valueAsNumber: true,
                    min: 1,
                  })}
                />

                {errors.code_id && (
                  <p className="text-xs text-red-500 ">
                    {errors.code_id.type === "required" &&
                      "This field is required."}
                    {errors.code_id.type === "max" &&
                      "Max letters count is 255 letter"}
                    {errors.code_id.type === "min" &&
                      "Please enter at least one letter"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  trackCodesErrors &&
                    trackCodesErrors?.response?.data?.errors?.code_id && (
                      <p className="w-full text-end text-xs text-red-500  ">
                        {trackCodesErrors?.response?.data?.errors?.code_id[0]}
                      </p>
                    )
                }
              </div>
            </div>

            {/** submit */}
            <button
              disabled={!isValid || (submitLoading && submitGenerate.flag)}
              className=" submit mt-6 w-full "
              type="submit"
            >
              {submitLoading && submitGenerate.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Track"
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CodesTracker;
