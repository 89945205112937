import { useState, useEffect } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export default function Status({
  videoId,
  history_id,
  videoRef,
  isAPIReady,
  setVideoRef,
}) {
  const [status, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);
  const [counter, setCounter] = useState(0);
  const [counterRunning, setCounterRunning] = useState(false);
  const [submitDecreaseWatch, setSubmitDecreaseWatch] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [decreaseWatchSuccess, decreaseWatchErrors, decreaseloading] = useAxios(
    process.env.REACT_APP_STUDENT_DECREASE_WATCHE_IN_COURSE_API,
    "POST",
    submitDecreaseWatch.flag,
    submitDecreaseWatch.dependency,
    submitDecreaseWatch.data
  );

  useEffect(() => {
    let interval;
    if (!videoRef) {
      setPlayer(null);
      return;
    }
    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    if (player && player.video) {
      player.video.addEventListener("play", () => {
        if (!counterRunning) {
          interval = setInterval(() => {
            setCounter((prevCount) => prevCount + 1);
          }, 1000);
          setCounterRunning(true);
        }
      });

      player.video.addEventListener("pause", () => {
        clearInterval(interval);
        // setCounterRunning(false);
      });
    }

    window.player = player;

    return () => {
      clearInterval(interval);
    };
  }, [isAPIReady, videoRef]);

  useEffect(() => {
    setCounter(0);
    setVideoRef(null);
  }, [videoRef]);

  useEffect(() => {
    if (counter === 1200) {
      setSubmitDecreaseWatch({
        flag: "deleteQuestion",
        dependency: !submitDecreaseWatch.dependency,
        data: { id: videoId, history_id: history_id },
      });
    }
  }, [counter, videoId]);

  return null;
}
