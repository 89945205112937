import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState, useEffect } from "react";

const AdminViewAllStudentsAssignments = () => {
  const { courseID } = useParams();

  const viewAllStudentsTable = useTable(
    "viewAllStudentsAssignments",
    "",
    "",
    "",
    "",
    courseID,
    // true,
    // data?.map((data) => ({
    //   student_name: data.student_name,
    //   ...data.quiz
    //     .map((quiz, i) => ({ [`quiz${i}`]: quiz.name }))
    //     .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    // })),
    ""
  );

  /*
   data?.map((data) => ({
      student_name: data.student_name,
      ...data.quiz
        .map((quiz, i) => ({ [`quiz${i}`]: quiz.name }))
        .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    })),
*/
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags
        title={"View All Assignments | Mr.Mohamed Salama"}
      ></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        View All Assignments
      </h2>
      <div className="h-fit  w-full">{viewAllStudentsTable}</div>
    </section>
  );
};

export default AdminViewAllStudentsAssignments;
