import HelmetTags from "../../../MainComponents/HelmetTags";
import StudentChangeWallet from "./components/ChangeWallet";

const MyWallet = () => {
  return (
    <section className="h-auto width flex flex-col items-center">
      <HelmetTags title="My Wallet | Mr.Mohamed Salama"></HelmetTags>{" "}
      <StudentChangeWallet />
    </section>
  );
};

export default MyWallet;
