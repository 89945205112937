import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function InvisibleCourseForm({ mobileNumber, course_id }) {
  const [values, setValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [dependency, setDependency] = useState(false);

  const [
    updateMobileInvisibleCoursesData,
    updateMobivleInvisibleCoursesErrors,
    updateMobivleInvisibleCoursesLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ADD_EXCEPTION_INVISIBLECOURSE,
    "POST",
    formFlag,
    dependency,
    values
  );

  const onClick = () => {
    setFormFlag(course_id);
    setDependency(true);
    setValues({
      course_id: course_id,
      mobile: mobileNumber,
    });
  };

  useEffect(() => {
    if (updateMobileInvisibleCoursesData) {
      toast.success("Success..");
      setFormFlag("");
      setDependency(false);
    }
  }, [updateMobileInvisibleCoursesData]);

  useEffect(() => {
    if (updateMobivleInvisibleCoursesErrors) {
      toast.error(updateMobivleInvisibleCoursesErrors.message);
      setFormFlag("");
      setDependency(false);
    }
  }, [updateMobivleInvisibleCoursesErrors]);

  return (
    <div className="w-full">
      <button
        className=" submit mt-6 w-full  mx-auto"
        type="submit"
        onClick={onClick}
      >
        {updateMobivleInvisibleCoursesLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Invisible Courses"
        )}
      </button>
    </div>
  );
}
