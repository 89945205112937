import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { useParams } from "react-router-dom";

const StudentSendWhatsappMessage = ({ message }) => {
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [refetch, setRefetch] = useState(false);
  const AuthState = useAuthState();
  const { studentID } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  const [whatsappSendMessageSuccess, whatsappSendMessageErrors, submitLoading] =
    useAxios(
      message
        ? process.env.REACT_APP_SEND_MESSAGE_STUDENT_API
        : process.env.REACT_APP_SEND_WHATSAPP_MESSAGE_STUDENT_API,
      "POST",
      submitGenerate.flag,
      submitGenerate.dependency,
      submitGenerate.data,
      true
    );

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: { student_id: studentID, ...data },
    });
  };

  useEffect(() => {
    //! reset the form on success submit

    if (whatsappSendMessageSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [whatsappSendMessageSuccess]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags
        title={`${
          message ? "Message" : "Whatsapp Message"
        } | Mr.Mohamed Salama`}
      ></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        {message ? "Message" : "Whatsapp Message"}
      </h2>

      <div className="form-codes-container my-20">
        <h2 className="my-10 text-center text-xl font-semibold">
          Please fill the form to send messages
        </h2>
        <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="shadow-l flex h-fit w-1/2  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-64 "
          >
            {/** message field */}
            <div className="flex w-full items-start justify-between gap-2 flex-col md:justify-start">
              <label className="w-full truncate" htmlFor="message">
                Please enter the text of the message sent
              </label>
              <textarea
                name="message"
                id="message"
                className="h-[100px] p-3"
                placeholder="Enter Your Message"
                {...register("message", { required: true })}
              ></textarea>

              {errors.message && (
                <p className="text-xs text-red-500 ">
                  {errors.message.message === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                whatsappSendMessageErrors &&
                  whatsappSendMessageErrors?.response?.data?.errors
                    ?.message && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {
                        whatsappSendMessageErrors?.response?.data?.errors
                          ?.message[0]
                      }
                    </p>
                  )
              }
            </div>

            {/** message field */}

            {!message && (
              <div className="flex w-full items-start justify-between gap-2 flex-col md:justify-start">
                <label className="w-full truncate" htmlFor="message_to">
                  Message to
                </label>
                <select
                  name="message_to"
                  id="message_to"
                  {...register("message_to", { required: true })}
                >
                  <option value="0">Student</option>
                  <option value="1">Student Parent 1</option>
                  <option value="2">Student Parent 2</option>
                </select>

                {errors.message_to && (
                  <p className="text-xs text-red-500 ">
                    {errors.message_to.type === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  whatsappSendMessageErrors &&
                    whatsappSendMessageErrors?.response?.data?.errors
                      ?.message_to && (
                      <p className="w-full text-end text-xs text-red-500  ">
                        {
                          whatsappSendMessageErrors?.response?.data?.errors
                            ?.type[0]
                        }
                      </p>
                    )
                }
              </div>
            )}

            {/** submit */}
            <button
              disabled={!isValid || (submitLoading && submitGenerate.flag)}
              className=" submit mt-6 w-full "
              type="submit"
            >
              {submitLoading && submitGenerate.flag ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Send Message"
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default StudentSendWhatsappMessage;
