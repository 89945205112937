import UserMessage from "./UserMessage";
import { MdMarkEmailRead } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import Loader from "../../../../../../MainComponents/Loader";
import { useState } from "react";

const ChatMessageList = ({
  selectedUserId,
  setSelectedUserId,
  setSelectedUserInfo,
  chatUsers,
  msgsType,
  setMsgsType,
  chatUsersDataLoading,
}) => {
  const [selectedStage, setSelectedStage] = useState(1);

  return (
    <div className="chatMessageList w-1/3 lg:w-full h-full p-4">
      <h3 className="font-bold mb-6">All Avilable Chats</h3>

      <div className="mb-4">
        <select
          name="type"
          id="type"
          value={selectedStage}
          onChange={(e) => setSelectedStage(e.target.value)}
        >
          <option value={1}>First</option>
          <option value={2}>Second</option>
          <option value={3}>Third</option>
        </select>
      </div>
      <div className="flex items-center gap-4 mb-4">
        <button
          onClick={() =>
            setMsgsType((prev) => (prev === "readed" ? "" : "readed"))
          }
          className={`flex items-center gap-2 bg-[#ede3e3] p-2 py-1 rounded-lg cursor-pointer  hover:bg-blue hover:text-white transition-all ${
            msgsType === "readed" && "!bg-blue text-white"
          }`}
        >
          <MdMarkEmailRead />
          Answerd
        </button>
        <button
          onClick={() =>
            setMsgsType((prev) => (prev === "unreaded" ? "" : "unreaded"))
          }
          className={`flex items-center gap-2 bg-[#ede3e3] p-2 py-1 rounded-lg cursor-pointer  hover:bg-blue hover:text-white transition-all ${
            msgsType === "unreaded" && "!bg-blue text-white"
          }`}
        >
          <IoMdMailUnread />
          Unanswerd
        </button>
      </div>

      <div className="messageList">
        {chatUsers?.filter((user) => user.stage === +selectedStage)?.length ===
          0 && (
          <p className="font-bold text-center pt-20">There is no Messages...</p>
        )}
        {chatUsersDataLoading ? (
          <Loader fit />
        ) : (
          chatUsers
            ?.filter((user) => user.stage === +selectedStage)
            ?.map((user, i) => (
              <UserMessage
                selected
                user={user}
                key={i}
                setSelectedUserId={setSelectedUserId}
                selectedUserId={selectedUserId}
                setSelectedUserInfo={setSelectedUserInfo}
              />
            ))
        )}
      </div>
    </div>
  );
};

export default ChatMessageList;
