import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const StudentChangeWallet = () => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [
    EditStudentWalletSuccess,
    EditStudentWalletErrors,
    EditStudentWalletLoading,
  ] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_WALLET_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const [
    getStudentWalletSuccess,
    getStudentWalletErrors,
    getStudentWalletLoading,
  ] = useAxios(
    process.env.REACT_APP_GET_STUDENT_WALLET_API,
    "GET",
    "GET",
    EditStudentWalletSuccess
  );

  console.log("jjjf");
  const onSubmit = (formData) => {
    setFormValues({ ...formData });
    setFormFlag("EditAssistantPassword");
    setFormDependency(true);
  };

  useEffect(() => {
    if (EditStudentWalletSuccess) {
      toast.success("Update Password Successfully..");
      setFormFlag("");
      setFormDependency(false);
      reset();
    }
  }, [EditStudentWalletSuccess, reset]);

  useEffect(() => {
    if (EditStudentWalletErrors) {
      toast.error(EditStudentWalletErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [EditStudentWalletErrors]);

  if (getStudentWalletLoading) return <Loader />;

  return (
    <div className="h-auto width flex flex-col items-center mt-12">
      <h4 className="text-lg font-bold">
        My Wallet: {getStudentWalletSuccess?.balance}
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 gap-4"
      >
        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="code">Code</label>
            <input
              id="code"
              className="signin-inputs w-full"
              type="text"
              placeholder="Code"
              name="code"
              autoComplete="on"
              {...register("code", {
                required: true,
              })}
            />
          </div>
          {errors.code && (
            <p className="text-red-500 text-xs pt-4">
              {errors.code.type === "required" && "This field is required."}
            </p>
          )}
        </div>

        <button
          disabled={!isValid || EditStudentWalletLoading}
          className=" submit mt-6 w-full "
          type="submit"
        >
          {EditStudentWalletLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Charge Wallet"
          )}
        </button>
      </form>
    </div>
  );
};

export default StudentChangeWallet;
