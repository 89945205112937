import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";

const CourseSelect = ({
  values,
  setSessionSelectValues,
  setCourseId,
  mobileNumber,
  type,
}) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const courseSelect = watch("course");

  const api =
    type === "views"
      ? process.env.REACT_APP_ADMIN_GET_STUDENT_SESSIONS_BY_VIEWS_EXCEPTION_API
      : process.env.REACT_APP_ADMIN_GET_INVISBLE_COURSES_API ||
        process.env.REACT_APP_ADMIN_GET_SESSION_COURSE_EXCEPTION_API;

  const [courseSelectSuccess, courseSelectErrors, courseSelectLoading] =
    useAxios(api, "POST", formFlag, formDependency, formValues);

  const onSubmit = (formData) => {
    if (type !== "purchases") {
      setFormValues({ ...formData, mobile: mobileNumber });
      setFormFlag("EditAssistantsInformations");
      setFormDependency(true);
    }
  };

  useEffect(() => {
    if (type !== "unlockCourse" && type !== "purchases") {
      if (courseSelect) onSubmit({ id: courseSelect });
    }
  }, [courseSelect, type]);

  useEffect(() => {
    if (courseSelectSuccess) {
      setFormFlag("");
      setFormDependency(false);
      setSessionSelectValues(courseSelectSuccess?.data);
      setCourseId(courseSelect);
      console.log(courseSelectSuccess);
    }
  }, [courseSelectSuccess]);

  useEffect(() => {
    if (courseSelectErrors) {
      toast.error(courseSelectErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [courseSelectErrors]);

  useEffect(() => {
    setCourseId(courseSelect);
    console.log(values);
  }, [courseSelect, setCourseId]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className="flex flex-col items-end justify-center gap-2">
        <label className="w-full truncate" htmlFor="course">
          Courses
        </label>
        <select
          name="course"
          id="course"
          {...register("course", { required: true })}
        >
          <option value="">Select a course</option>
          {values.map((value, i) => (
            <option key={i} value={value.id}>
              {type === "invisiblecourses" ? value.title : value.course}
            </option>
          ))}
        </select>

        {errors.course && (
          <p className="text-xs text-red-500">
            {errors.course.type === "required" && "This field is required."}
          </p>
        )}

        {courseSelectErrors &&
          courseSelectErrors?.response?.data?.errors?.course_id && (
            <p className="w-full text-end text-xs text-red-500">
              {courseSelectErrors.response.data.errors.course_id[0]}
            </p>
          )}
      </div>
    </form>
  );
};

export default CourseSelect;
