import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title,
  Filler,
} from "chart.js";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useEffect, useState } from "react";

ChartJS.register(
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title,
  Filler
);

function Analytics() {
  const [stageCount, setStageCount] = useState([]);
  const table = useTable("analytics", "", "");
  const [analyticsData, analyticsDataErrors, analyticsDataLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ANALYTICS_API,
    "GET",
    "GET",
    "",
    ""
  );

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Stage 1",
        data: [300, 600, 800, 900, 200, 450, 700],
        backgroundColor: "#4285f4",
        borderColor: "#4285f4",
        pointBorderColor: "#4285f4",
        //fill:true,
        tension: 0.4,
      },
      {
        label: "Stage 2",
        data: [700, 100, 300, 200, 1000, 150, 1000],
        backgroundColor: "rgb(110, 231, 183)",
        borderColor: "rgb(110, 231, 183)",
        pointBorderColor: "rgb(110, 231, 183)",
        //fill:true,
        tension: 0.4,
      },
      {
        label: "Stage 3",
        data: [600, 900, 500, 400, 700, 950, 100],
        backgroundColor: "rgba(189, 19, 236)",
        borderColor: "rgba(189, 19, 236)",
        pointBorderColor: "rgba(189, 19, 236)",
        //fill:true,
        tension: 0.4,
      },
    ],
  };
  const DoughnutData = {
    labels: ["Stage 1", "Stage 2", "Stage 3"],
    datasets: [
      {
        label: "Stages",
        data: stageCount,
        backgroundColor: [
          "#4285f4",
          "rgb(110, 231, 183)",
          "rgba(189, 19, 236)",
        ],
        borderColor: [
          "rgba(3, 65, 221, 1)",
          "rgb(110, 231, 183,1)",
          "rgba(189, 19, 236, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,

    plugins: {
      legend: true,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      y: {},
    },
  };

  useEffect(() => {
    setStageCount((prev) =>
      analyticsData?.data.stagecount.map((count) => count.count)
    );
  }, [analyticsData]);
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Analytics | Mr.Mohamed Salama"></HelmetTags>{" "}
      <h2 className="mb-7 text-start text-2xl font-bold sm:text-center">
        Analytics
      </h2>
      <div
        //!container
        className="lef-right-container flex h-fit w-full items-start justify-between gap-12 lg:flex-col lg:items-center lg:justify-start lg:gap-20 "
      >
        <div
          //!left
          className="admin-dash-left flex  h-fit w-1/2 flex-col items-start justify-start gap-8 lg:w-full lg:items-center "
        >
          <div className="line-chart  flex max-h-[310px] w-full flex-col items-start justify-start gap-4  lg:items-center xs:w-[80%]">
            <Doughnut data={DoughnutData}></Doughnut>
          </div>
        </div>

        <div
          //!right
          className="admin-dash-right flex  h-fit w-1/2 flex-col items-end justify-start lg:w-full  lg:items-center "
        >
          <div className="bar-chart mt-20 flex h-52 w-full flex-col items-start justify-start gap-4 lg:items-center xs:w-[80%]">
            <Bar className="" data={data} options={options}></Bar>
          </div>
        </div>
      </div>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Analytics;
