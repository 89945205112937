import { Link } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";
import useAxios from "./Hooks/useAxios.jsx";
import { useEffect, useState } from "react";

function OfferCard({ reFetch, setReFetch, offer, admin }) {
  const AuthState = useAuthState();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Course -------

  const [deleteCourseSuccess, deleteCourseErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_SINGLE_OFFER_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );

  useEffect(() => {
    //! refetch the data on success delete
    if (deleteCourseSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteCourseSuccess]);

  function handleDeleteOffer(id) {
    setSubmitDelete({
      flag: "deleteCourse",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  return (
    <div className="plan-card hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px]   rounded-md  shadow-lg p-8">
      <h2 className="font-bold  ">{offer.name}</h2>
      <div className="etiquet-price w-[340px] sm:w-[290px] relative bg-blue text-bg flex justify-center my-7">
        <p>{offer.price}</p>
        <div className="border-t-[13px] border-t-blue/80 text-bg"></div>
      </div>
      {admin && (
        <div className="stage-visibility w-full flex  justify-between items-center">
          <span>
            {offer.stage === 1
              ? "First Stage"
              : offer.stage === 2
              ? "Second Stage"
              : "Third Stage"}
          </span>
          <span>{offer.visibility === 1 ? " Visible" : " Hidden"}</span>
        </div>
      )}
      {admin ? (
        <>
          <div className="admin-btns flex justify-between items-center gap-4  w-full mt-8">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("offer_delete")
              )) && (
              <Popconfirm
                okText="Confirm"
                okType="danger"
                cancelText="Cancel"
                color="#ffffff" //okButtonProps={{ loading: loading }}
                onConfirm={() => handleDeleteOffer(offer.key)}
                title="Are You Sure You Want to Delete The offer?"
              >
                <button className="delete-btn   bg-delete text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-delete hover:bg-bg hover:text-delete transition-all duration-300  hover:scale-105 active:scale-90">
                  Delete
                </button>
              </Popconfirm>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("offer_edit")
              )) && (
              <Link
                to={`/admin/courses/offers/edit-offer/${offer.key}`}
                className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
              >
                Edit
              </Link>
            )}
          </div>
        </>
      ) : (
        <Link
          to={`/courses/offers/${offer.key}`}
          className="flex justify-center mt-8
               items-center gap-2 bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
        >
          <FontAwesomeIcon icon={faLock} />
          Get offer
        </Link>
      )}
    </div>
  );
}

export default OfferCard;
