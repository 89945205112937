import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const UnlockCourse = ({ courseId, mobileNumber }) => {
  const [values, setValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [dependency, setDependency] = useState(false);

  const [
    updateUnlockCourseSucsses,
    updateUnlockCourseErrors,
    updateUnlockCourseLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_UNLCOK_COURSE_ADD_EXCEPTION_API,
    "POST",
    formFlag,
    dependency,
    values
  );

  const onClick = () => {
    setFormFlag(courseId);
    setDependency(true);
    setValues({
      id: courseId,
      mobile: mobileNumber,
    });
  };

  useEffect(() => {
    if (updateUnlockCourseSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setDependency(false);
    }
  }, [updateUnlockCourseSucsses]);

  useEffect(() => {
    if (updateUnlockCourseErrors) {
      toast.error(updateUnlockCourseErrors.message);
      setFormFlag("");
      setDependency(false);
    }
  }, [updateUnlockCourseErrors]);
  return (
    <div className="w-full">
      <button onClick={onClick} className=" submit mt-6 w-full" type="submit">
        {updateUnlockCourseLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Update Lock Course"
        )}
      </button>
    </div>
  );
};

export default UnlockCourse;
