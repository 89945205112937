import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const QuestionModalAnswer = ({
  collapse,
  onCloseCollapse,
  activeQuestionId,
  question,
  setShowPopUpVideo,
  setCurrentVideoSrc,
}) => {
  const correctAnswer = question?.correct_answer;
  const studentAnswer = question?.student_answer;
  const src =
    question?.platform === "youtube"
      ? `https://www.youtube.com/embed/${question?.vdo_id}`
      : question?.platform === "vimeo"
      ? `https://player.vimeo.com/video/${question?.vdo_id}`
      : `https://player.vdocipher.com/v2/?otp=${question?.OTP}&playbackInfo=${question?.playbackInfo}`;
  return (
    <div
      className={`single-question fixed left-0 top-0  z-40 h-screen w-[37.5%] overflow-y-auto bg-red-200 px-[20px] pb-[40px] pt-[128px] transition-all  duration-300 ease-in-out md:w-full ${
        collapse ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="close-btn-wrapper mb-[20px] flex w-full justify-end pr-[20px]">
        <div
          onClick={() => onCloseCollapse(false)}
          className="close-question-btn  flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full  bg-accent text-light shadow-md shadow-black/40 transition-all duration-300 ease-in-out hover:scale-110 active:scale-90"
        >
          <FontAwesomeIcon
            className={`transition-all duration-300 ease-in-out hover:scale-110 active:scale-90 `}
            icon={faXmark}
          />
        </div>
      </div>

      <div className="current-question flex flex-row-reverse">
        {`.${activeQuestionId + 1}`}
        <div
          dangerouslySetInnerHTML={{
            __html: question?.question,
          }}
        />
      </div>
      <div className="input-container response-question-input flex flex-col items-end"></div>
      <div className="response-question-answers">
        {question?.answers.map((answer, i) => (
          <div
            key={i}
            className={`${
              correctAnswer !== studentAnswer &&
              answer.order === studentAnswer &&
              "response-selected"
            }
            ${
              studentAnswer === correctAnswer &&
              answer.order === correctAnswer &&
              "bg-lime-500 border-lime-500"
            }
            ${
              answer.order === correctAnswer && "bg-lime-500 border-lime-500"
            } `}
          >
            <span>{answer.order}</span>
            <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
          </div>
        ))}
      </div>

      {question?.platform && (
        <div
          onClick={() => {
            setShowPopUpVideo(true);
            setCurrentVideoSrc(src);
          }}
          className="question__video w-full aspect-video mt-7 overflow-hidden rounded-xl border-2 cursor-pointer"
        >
          <iframe
            className="pointer-events-none"
            src={src}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default QuestionModalAnswer;
