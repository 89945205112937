import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const QuizAndHomeworkForm = ({ homework }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  //!--------- add edit assistant -------

  const [updateSessionSucsses, updateSessionErrors, updateSessionLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_GET_VIDEOS_COURSE_EXCEPTION_API,
      "POST",
      formFlag,
      formDependency,
      formValues
    );

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag(formData.id);
    setFormDependency(true);
  };

  useEffect(() => {
    if (updateSessionSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateSessionSucsses]);

  useEffect(() => {
    if (updateSessionErrors) {
      toast.error(updateSessionErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateSessionErrors]);
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className={`flex flex-col items-end justify-center gap-2`}>
        <label className="w-full truncate" htmlFor="course">
          {homework ? "Homework" : "Quiz"}
        </label>
        <select name="quiz" id="quiz" {...register("quiz", { required: true })}>
          <option value="">Select a {homework ? "homework" : "quiz"}</option>
        </select>
        {errors.quiz && (
          <p className="text-xs text-red-500 ">
            {errors.quiz.quiz === "required" && "This field is required."}
          </p>
        )}
        {
          //!-------server errors -----

          updateSessionErrors &&
            updateSessionErrors?.response?.data?.errors?.course && (
              <p className="w-full text-end text-xs text-red-500  ">
                {updateSessionErrors?.response?.data?.errors?.course[0]}
              </p>
            )
        }
      </div>
      <button className=" submit mt-6 w-full" type="submit">
        {updateSessionLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          `Update ${homework ? "Homework" : "Quiz"}`
        )}
      </button>
    </form>
  );
};

export default QuizAndHomeworkForm;
