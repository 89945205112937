import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCountDown from "../../../../MainComponents/Hooks/useCountDown";
import { useEffect } from "react";

const SessionCollapse = ({ session, dispatch, state }) => {
  const { days, hours, minutes, seconds } = useCountDown(
    session.is_start === 1 ? session.start_at : session.expire_at
  );

  useEffect(() => {
    if (session.is_expire !== undefined) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        dispatch({ type: "setRefetch", payload: !state.refetch });
      }
    }
  }, [days, dispatch, hours, minutes, seconds, session, state.refetch]);
  return (
    <button
      disabled={session.is_expire === 1}
      onClick={(e) => {
        if (e.target.parentElement) {
          e.target.parentElement.classList.toggle("collapse-session");
        }
      }}
      className="session-title-wrapper w-full cursor-pointer border-y-2 px-4 flex justify-between items-center gap-5 h-16 bg-green disabled:opacity-50 "
    >
      <div className="icon_title flex justify-start gap-5 items-center font-semibold text-lg pointer-events-none">
        <FontAwesomeIcon
          className="chevron-icon transition-all duration-300 ease-in-out"
          icon={faChevronUp}
        />
        <p className="">{session.name} </p>
      </div>
      <div className="session-details-left pointer-events-none flex flex-col">
        {session.is_expire === 1 && <p className="">Session Expired</p>}

        {session.is_expire === 0 ? (
          <>
            <p>{session.session.length} Content</p>
            <p>
              {session.status} {""}
              {days} days | {hours} Hrs | {minutes} Min | {seconds} Sec
            </p>
          </>
        ) : null}
      </div>
    </button>
  );
};

export default SessionCollapse;
