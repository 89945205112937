import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditMaterial() {
  const [values, setValues] = useState(null);
  const { materialID } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMaterial": {
        return {
          ...state,
          submitAddEditMaterial: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditMaterial: {
      flag: "",
      dependency: false,
      data: {},
    },
  });
  //!--------- get the Material info -------

  const [MaterialInfo, MaterialInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_SESSION_CONTENT_INFO_API}${materialID}`,
    "GET",
    "GET",
    materialID
  );

  useEffect(() => {
    if (MaterialInfo?.is_found === 1) {
      setValues({
        is_public: MaterialInfo.data[0].is_public === 1 ? true : false,
      });
    }
  }, [MaterialInfo]);

  //!--------- add  Material -------

  const [MaterialAddEditSuccess, MaterialAddEditErrors, submitLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_SESSION_ADD_MATERIAL_API,
      "POST",
      state.submitAddEditMaterial.flag,
      state.submitAddEditMaterial.dependency,
      state.submitAddEditMaterial.data,
      true
    );

  useEffect(() => {
    if (MaterialAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [MaterialAddEditSuccess]);

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitAddEditMaterial",
      payload: {
        flag: "AddEditMaterial",
        dependency: !state.submitAddEditMaterial.dependency,
        data: {
          id: materialID,
          is_public: data?.is_public === true ? "1" : 0,
          address: data?.address[0],
        },
      },
    });
  };

  return (
    <section className=" h-full w-full   dark:bg-dark dark:text-light  flex flex-col items-center justify-center">
      <HelmetTags title="Sessions | Mr.Mohamed Salama"></HelmetTags>{" "}
      {MaterialInfo && (
        <h2 className="mb-10 text-center text-xl font-semibold">
          {MaterialInfo?.is_found === 1 ? "Edit File" : "Add File"}
        </h2>
      )}
      {MaterialInfo?.is_found === 1 && (
        <div className=" flex justify-center items-center gap-10 mb-10 h-fit">
          <h3>{MaterialInfo?.data[0].name}</h3>
          <a
            href={MaterialInfo?.data[0].url}
            download="Download File"
            className="type-edit-icon w-7 h-7  flex justify-center items-center border-2 border-blue text-blue rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-blue hover:text-white hover:border-blue"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        method="post"
        className="flex w-3/4 max-w-[500px] flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
      >
        {/** file is-public filed */}
        <div className="flex w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
          {/** file  */}

          <div className="flex w-full flex-col items-start justify-center  gap-2  md:w-full">
            <label className=" " htmlFor="address">
              Add File
            </label>

            <input
              id="address"
              className="signin-inputs   w-full"
              type="file"
              accept=".pdf"
              name="address"
              {...register("address", {
                required: true,
                validate: (value) => !(value[0]?.size > 150 * 1024 * 1024),
              })}
            />

            {errors.address && (
              <p className="mt-2  text-xs text-red-500">
                {errors.address.type === "required" &&
                  "This field is required."}
                {errors.address.type === "validate" &&
                  "File Max Size is 15 MB."}
              </p>
            )}
            {
              //!-------server errors -----

              MaterialAddEditErrors &&
                MaterialAddEditErrors?.response?.data?.errors?.address && (
                  <p className=" text-xs text-red-500  ">
                    {MaterialAddEditErrors?.response?.data?.errors?.address[0]}
                  </p>
                )
            }
          </div>
          {/** is-public  */}
          <div className=" is_public flex w-1/2 flex-col items-start justify-center gap-2 md:w-full ">
            <div className="flex w-full items-center justify-end md:justify-center gap-10">
              <label className="visibility-switch">
                <input
                  className=""
                  id="is_public"
                  name="is_public"
                  {...register("is_public", {
                    required: false,
                  })}
                  type="checkbox"
                />
                <span className="visibility-slider">
                  <svg
                    className="slider-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path fill="none" d="m4 16.5 8 8 16-16"></path>
                  </svg>
                </span>
              </label>
              <div>
                <label
                  className="w-full cursor-pointer truncate"
                  htmlFor="is_public"
                >
                  Free{" "}
                </label>
              </div>
            </div>

            {errors.is_public && (
              <p className="text-xs text-red-500 ">
                {errors.is_public.type === "required" &&
                  "This field is required."}{" "}
              </p>
            )}
            {
              //!-------server errors -----

              MaterialAddEditErrors &&
                MaterialAddEditErrors?.response?.data?.errors?.is_public && (
                  <p className=" text-xs text-red-500  ">
                    {
                      MaterialAddEditErrors?.response?.data?.errors
                        ?.is_public[0]
                    }
                  </p>
                )
            }
          </div>
        </div>

        {/** submit */}

        <button
          disabled={
            !isValid || (submitLoading && state.submitAddEditMaterial.flag)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {submitLoading && state.submitAddEditMaterial.flag ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <p>{MaterialInfo?.is_found === 1 ? "Edit File" : "Add File"}</p>
          )}
        </button>
      </form>
    </section>
  );
}

export default AddEditMaterial;
