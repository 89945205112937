import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";

const AdminViewStudentAsignments = () => {
  const { ID } = useParams();

  const activeSutdentsTable = useTable(
    "adminViewStudentAsignments",
    "",
    "",
    "",
    "",
    ID
  );
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"Asignments | Mr.Mohamed Salama"}></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        View Student Asignments
      </h2>
      <div className="flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-star">
        <div className="h-fit  w-full">{activeSutdentsTable}</div>
      </div>
    </section>
  );
};

export default AdminViewStudentAsignments;
