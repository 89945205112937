//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../MainComponents/Loader";

function AddEditCourse({ edit }) {
  const [values, setValues] = useState(null);
  const { courseID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditCourse": {
        return {
          ...state,
          submitAddEditCourse: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditCourse: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_COURSE_API
    : process.env.REACT_APP_ADMIN_ADD_COURSE_API;
  //!--------- add edit Course -------

  const [CourseAddEditSuccess, CourseAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditCourse.flag,
    state.submitAddEditCourse.dependency,
    state.submitAddEditCourse.data,
    true
  );

  //!--------- get the Course info for editing -------

  const [CourseInfo, CourseInfoErrors, CourseInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSE_EDIT_API}${courseID}`,
    "GET",
    courseID,
    courseID
  );

  const [NextCourses, NextCoursesErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NEXT_COURSES_API,
    "GET",
    "GET",
    ""
  );

  useEffect(() => {
    if (CourseInfo) {
      let temp = CourseInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        is_buying: temp.is_buying === 1 ? true : false,
        section: temp.section,
      });
    }
  }, [CourseInfo]);

  useEffect(() => {
    //!---add actions ----

    if (CourseAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [CourseAddEditSuccess]);

  const navigate = useNavigate();

  const defaultValues = CourseInfo?.data[0];

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
    values: defaultValues,
  });
  const watchSequence = watch("sequence", defaultValues?.sequence);
  const watchStage = watch("stage", defaultValues?.stage);
  const watchSection = watch("section", defaultValues?.section);

  const onSubmit = (data) => {
    const finalData = courseID
      ? {
          ...data,
          id: courseID,
          img: data?.img[0].name ? data?.img[0] : null,
          description: data?.description || null,
          visibility: +data?.visibility,
          is_buying: +data?.is_buying,
          next_course: +data.sequence === 0 ? null : data.next_course,
          section: +data.stage !== 1 ? data.section : null,
        }
      : {
          ...data,
          img: data?.img[0].name ? data?.img[0] : null,
          sequence: data?.sequence ? data?.sequence : 0,
          description: data.description || null,
          next_course: +data.sequence === 0 ? null : data.next_course,
          section: +data.stage !== 1 ? data.section : null,
        };

    dispatch({
      type: "setSubmitAddEditCourse",
      payload: {
        flag: "AddEditCourse",
        dependency: !state.submitAddEditCourse.dependency,
        data: finalData,
      },
    });
  };

  if (CourseInfoLoading) return <Loader />;

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Courses | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-10 text-center text-2xl font-semibold">
          {courseID ? "Edit Course" : "Add Course"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name filed */}

          <div className="name flex flex-col items-start justify-center  gap-2  w-full">
            <label htmlFor="title">Name</label>
            <input
              className="signin-inputs    "
              type="text"
              id="title"
              placeholder="Name"
              name="title"
              autoComplete="on"
              {...register("title", {
                required: true,
                //pattern: /^[A-Za-z]+$/,
                maxLength: 80,
                minLength: 3,
              })}
            />
            {errors.title && (
              <p className=" pt-2 text-xs text-red-500  ">
                {errors.title.type === "required" && "This field is required."}
                {errors.title.type === "pattern" &&
                  "Only English letters are allowed."}
                {errors.title.type === "maxLength" &&
                  "Allowed Name max length is 80."}
                {errors.title.type === "minLength" &&
                  "Please enter at least 3 letters."}
              </p>
            )}

            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.title && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {CourseAddEditErrors?.response?.data?.errors?.title[0]}
                  </p>
                )
            }
          </div>
          {/** branch stage filed */}
          <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:gap-6 md:flex-col-reverse  ">
            <div
              className={`flex w-1/2 flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
            >
              <label htmlFor="stage">Stage</label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.stage.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.stage && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {CourseAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            <div
              className={`flex w-1/2 flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
            >
              <label htmlFor="branch">Branch</label>
              <select
                name="branch"
                id="branch"
                {...register("branch", { required: true })}
              >
                <option value="statistics">Statistics</option>
                <option value="dynamics">Dynamics</option>
                <option value="calculus">Calculus</option>
                <option value="algebra">Algebra</option>
                <option value="statics">Statics</option>
                <option value="applied">Applied</option>
                <option value="pure">Pure</option>
              </select>

              {errors.branch && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.branch.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.branch && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {CourseAddEditErrors?.response?.data?.errors?.branch[0]}
                    </p>
                  )
              }
            </div>
          </div>
          {+watchStage === 2 || +watchStage === 3 ? (
            <div className="  flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="section">Section</label>
              <div className="flex w-1/2 md:w-full items-end justify-between gap-8 pb-1 ">
                {/** Scientific */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="scientific"
                    value="scientific"
                    {...register("section", { required: true })}
                  />
                  <label className="cursor-pointer" htmlFor="scientific">
                    Scientific
                  </label>
                </div>
                {/** Literary  */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="literary"
                    value="literary"
                    {...register("section", { required: true })}
                  />
                  <label className="cursor-pointer" htmlFor="literary">
                    Literary
                  </label>
                </div>
              </div>
              {errors.section && (
                <p className="-mt-1 text-xs text-red-500">
                  {errors.section.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.section && (
                  <p className="text-xs text-red-500  ">
                    {state.errors.section[0]}
                  </p>
                )
              }
            </div>
          ) : null}

          {/**level  sequence filed */}
          <div className="flex  w-full items-start justify-between gap-16 md:gap-6 md:flex-col  ">
            <div className="  flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="sequence">Sequence</label>
              <div className="flex w-full items-center justify-start gap-16 mb-3 ">
                {/** yes */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="yes"
                    value={"1"}
                    checked={+watchSequence === 1}
                    {...register("sequence", { required: true })}
                  />
                  <label className="cursor-pointer" htmlFor="yes">
                    Yes
                  </label>
                </div>
                {/** no  */}

                <div className=" flex  items-center justify-center gap-2 ">
                  <input
                    className="cursor-pointer"
                    type="radio"
                    id="no"
                    value={"0"}
                    checked={+watchSequence === 0}
                    {...register("sequence", { required: true })}
                  />
                  <label className="cursor-pointer" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
              {errors.sequence && (
                <p className="-mt-1 text-xs text-red-500">
                  {errors.sequence.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                state.errors && state.errors.sequence && (
                  <p className="text-xs text-red-500  ">
                    {state.errors.sequence[0]}
                  </p>
                )
              }
            </div>
            {+watchSequence === 1 && (
              <div className="  flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
                <label htmlFor="next_course">Required Course</label>
                <select
                  name="next_course"
                  id="next_course"
                  {...register("next_course", {
                    required: true,
                    valueAsNumber: true,
                    min: 1,
                  })}
                >
                  {NextCourses?.data
                    ?.filter((course) => course.stage === +watchStage)
                    ?.filter((course) => course.section === watchSection)
                    .map((course) => (
                      <option value={course.key} key={course.key}>
                        {course.title}
                      </option>
                    ))}
                </select>

                {errors.level && (
                  <p className="pt-2 text-xs text-red-500 ">
                    {errors.level.type === "required" &&
                      "This field is required."}{" "}
                    {errors.level.type === "min" && "Minimum Number is 1."}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  CourseAddEditErrors &&
                    CourseAddEditErrors?.response?.data?.errors?.level && (
                      <p className=" pt-2 text-xs text-red-500  ">
                        {CourseAddEditErrors?.response?.data?.errors?.level[0]}
                      </p>
                    )
                }
              </div>
            )}
          </div>
          <div className="flex  w-full items-end justify-between gap-16 md:gap-6 md:flex-col  ">
            <div className="  flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="expired_at">Expired At (Days)</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                min={1}
                id="expired_at"
                placeholder="000"
                name="expired_at"
                autoComplete="on"
                {...register("expired_at", {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                })}
              />

              {errors.expired_at && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.expired_at.type === "required" &&
                    "This field is required."}{" "}
                  {errors.expired_at.type === "min" && "Minimum Number is 1."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.expired_at && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {
                        CourseAddEditErrors?.response?.data?.errors
                          ?.expired_at[0]
                      }
                    </p>
                  )
              }
            </div>

            <div className="  flex w-1/2 flex-col items-start justify-center gap-2 md:w-full">
              <label htmlFor="price">Price</label>
              <input
                className="signin-inputs w-full "
                type="number"
                inputMode="numeric"
                min={0}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                  min: 0,
                })}
              />

              {errors.price && (
                <p className="pt-2 text-xs text-red-500 ">
                  {errors.price.type === "required" &&
                    "This field is required."}{" "}
                  {errors.price.type === "min" && "Minimum Number is 1."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                CourseAddEditErrors &&
                  CourseAddEditErrors?.response?.data?.errors?.price && (
                    <p className=" pt-2 text-xs text-red-500  ">
                      {CourseAddEditErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>
          </div>
          {/** Expired At  visibility filed */}
          <div className="flex  w-full items-end justify-between gap-16 md:gap-6 md:flex-col  ">
            {edit && (
              <>
                <div className=" visibility flex w-1/2 flex-col items-start justify-center gap-2 md:w-full  h-full">
                  <div className="flex w-full items-center justify-end gap-10">
                    <label className="visibility-switch">
                      <input
                        id="visibility"
                        name="visibility"
                        {...register("visibility", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div>
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="visibility"
                      >
                        Visibility
                      </label>
                    </div>
                  </div>

                  {errors.visibility && (
                    <p className="pt-2 text-xs text-red-500 ">
                      {errors.visibility.type === "required" &&
                        "This field is required."}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    CourseAddEditErrors &&
                      CourseAddEditErrors?.response?.data?.errors
                        ?.visibility && (
                        <p className=" pt-2 text-xs text-red-500  ">
                          {
                            CourseAddEditErrors?.response?.data?.errors
                              ?.visibility[0]
                          }
                        </p>
                      )
                  }
                </div>
                <div className=" visibility flex w-1/2 flex-col items-start justify-center gap-2 md:w-full  h-full">
                  <div className="flex w-full items-center justify-end gap-10">
                    <label className="visibility-switch">
                      <input
                        id="is_buying"
                        name="is_buying"
                        {...register("is_buying", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div>
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="is_buying"
                      >
                        Available for purchase
                      </label>
                    </div>
                  </div>

                  {errors.is_buying && (
                    <p className="pt-2 text-xs text-red-500 ">
                      {errors.is_buying.type === "required" &&
                        "This field is required."}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    CourseAddEditErrors &&
                      CourseAddEditErrors?.response?.data?.errors
                        ?.is_buying && (
                        <p className=" pt-2 text-xs text-red-500  ">
                          {
                            CourseAddEditErrors?.response?.data?.errors
                              ?.is_buying[0]
                          }
                        </p>
                      )
                  }
                </div>
              </>
            )}
          </div>

          {/** Course Image  */}
          <div className="flex w-full flex-col items-start gap-2">
            <label className=" " htmlFor="img">
              Course Image
            </label>

            <input
              id="img"
              className="signin-inputs w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className=" pt-2 text-xs text-red-500">
                {errors.img.type === "required" && "This field is required."}
                {errors.img.type === "validate" && "Image Max Size is 5 MB."}
              </p>
            )}
            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.img && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {CourseAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-start justify-center gap-2 ">
            <label className=" " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="pt-2 text-xs text-red-500 ">
                {errors.description.type === "maxLength" &&
                  "Allowed Description Max Length is 155."}
                {errors.description.type === "minLength" &&
                  "Please Enter at Least 3 Letters."}
              </p>
            )}
            {
              //!-------server errors -----

              CourseAddEditErrors &&
                CourseAddEditErrors?.response?.data?.errors?.description && (
                  <p className=" pt-2 text-xs text-red-500  ">
                    {
                      CourseAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditCourse.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditCourse.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {courseID ? "Confirm Editing The Course" : "Add The Course"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCourse;
