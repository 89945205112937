import React, { useCallback, useEffect, useRef, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Popconfirm } from "antd";

function AdminExams({ admin }) {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const observer = useRef();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  //!--------- get all Exams -------

  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Exam -------

  const [deleteExamSuccess, deleteExamErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_TRIAL_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteExam(id) {
    setSubmitDelete({
      flag: "deleteExam",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete
    if (deleteExamSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteExamSuccess]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Trial Exams | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="mb-7 flex flex-col items-start gap-10 md:items-center">
        <h2 className=" text-2xl font-bold">Trial Exams</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("exam_add")
          )) && (
          <Link
            to="/admin/exams/add-exam"
            className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 "
          >
            Add Trial Exam <FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Link>
        )}
      </div>
      {
        <div className="courses grid-auto-fit">
          {allExamsErrors ? (
            <p>{allExamsErrors}</p>
          ) : (
            <div className={`courses  mt-10 grid-auto-fit w-full `}>
              {allExamsData?.data?.map((exam, index) => {
                return (
                  <div
                    key={exam.key}
                    className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px]   rounded-md p-4 shadow-lg`}
                  >
                    <div className="img-wrapper w-full rounded-md overflow-hidden">
                      <img
                        className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
                        src={exam.img}
                        alt={exam.title}
                        onClick={() => navigate(`/admin/exams/${exam.key}`)}
                      />
                    </div>

                    <div className="details-wrapper mt-5">
                      <div className="type-hours w-full flex justify-between items-center">
                        <div className="type rounded-lg px-2 bg-pink text-white cursor-pointer">
                          {exam.type}
                        </div>
                        <div className="hours">
                          <p className="">Stage {exam.stage}</p>
                        </div>
                      </div>

                      <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
                        <Link
                          to={`/admin/exams/${exam.key}`}
                          className="cursor-pointer w-3/5  truncate"
                        >
                          {exam.title}
                        </Link>
                        <span className="font-normal"> {exam.type}</span>
                      </h2>
                      <p className="description">{exam.description}</p>

                      <div className="price-cta flex justify-between items-center mt-5 w-full">
                        <div className="admin-btns flex justify-end items-center gap-4  w-full">
                          {(AuthState.userData?.admin?.roles[0]?.name ===
                            "superadmin" ||
                            AuthState.userData?.admin?.permissions?.some(
                              (permission) =>
                                permission.name.startsWith("lecture_view")
                            )) && (
                            <Link
                              to={`/admin/exams/all-students/${exam.key}`}
                              className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                            >
                              View
                            </Link>
                          )}
                          {(AuthState.userData?.admin?.roles[0]?.name ===
                            "superadmin" ||
                            AuthState.userData?.admin?.permissions?.some(
                              (permission) =>
                                permission.name.startsWith("lecture_delete")
                            )) && (
                            <Popconfirm
                              okText="Confirm"
                              okType="danger"
                              cancelText="Cancel"
                              color="#ffffff"
                              //okButtonProps={{ loading: loading }}
                              onConfirm={() => handleDeleteExam(exam.key)}
                              title="Are You Sure You Want to Delete The Course?"
                            >
                              <button className="delete-btn   bg-delete text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-delete hover:bg-bg hover:text-delete transition-all duration-300  hover:scale-105 active:scale-90">
                                Delete
                              </button>
                            </Popconfirm>
                          )}
                          {(AuthState.userData?.admin?.roles[0]?.name ===
                            "superadmin" ||
                            AuthState.userData?.admin?.permissions?.some(
                              (permission) =>
                                permission.name.startsWith("lecture_edit")
                            )) && (
                            <Link
                              to={`/admin/exams/edit-exam/${exam.key}`}
                              className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                            >
                              Edit
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      }
      {allExamsloading && <Loader />}
    </section>
  );
}

export default AdminExams;
