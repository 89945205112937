import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditVideo() {
  const [values, setValues] = useState(null);
  const { videoID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditVideo": {
        return {
          ...state,
          submitAddEditVideo: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditVideo: {
      flag: "",
      dependency: false,
      data: {},
    },
    src: "",
  });
  //!--------- get the Video info for editing -------

  const [VideoInfo, VideoInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_SESSION_CONTENT_INFO_API}${videoID}`,
    "GET",
    "GET",
    videoID
  );
  useEffect(() => {
    if (VideoInfo?.is_found === 1) {
      const { is_public, platform, vdo_id, OTP, playbackInfo } =
        VideoInfo.data[0];
      setValues({
        ...VideoInfo.data[0],
        is_public: is_public === 1 ? true : false,
      });

      let src =
        platform === "youtube"
          ? `https://www.youtube.com/embed/${vdo_id}`
          : platform === "vimeo"
          ? `https://player.vimeo.com/video/${vdo_id}`
          : `https://player.vdocipher.com/v2/?otp=${OTP}&playbackInfo=${playbackInfo}`;
      dispatch({
        type: "setSrc",
        payload: src,
      });
    }
  }, [VideoInfo]);

  //!--------- add edit Video -------
  let addEditVideoApi =
    VideoInfo?.is_found === 1
      ? process.env.REACT_APP_ADMIN_SESSION_EDIT_VIDEO_API
      : VideoInfo?.is_found === 0
      ? process.env.REACT_APP_ADMIN_SESSION_ADD_VIDEO_API
      : "";

  const [VideoAddEditSuccess, VideoAddEditErrors, submitLoading] = useAxios(
    addEditVideoApi,
    "POST",
    state.submitAddEditVideo.flag,
    state.submitAddEditVideo.dependency,
    state.submitAddEditVideo.data,
    true
  );

  useEffect(() => {
    //!---add actions ----

    if (VideoAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [VideoAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData =
      VideoInfo?.is_found === 1
        ? {
            ...data,
            id: VideoInfo?.data[0]?.key,
            is_public: data?.is_public === true ? 1 : 0,
          }
        : VideoInfo?.is_found === 0
        ? {
            ...data,
            content_id: Number(videoID),
            is_public: data?.is_public === true ? 1 : 0,
          }
        : {};

    dispatch({
      type: "setSubmitAddEditVideo",
      payload: {
        flag: "AddEditVideo",
        dependency: !state.submitAddEditVideo.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto min-h-screen py-20 gap-16 w-full  flex-col justify-center items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Sessions | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="form-container  flex w-full flex-col items-center">
        {VideoInfo && (
          <h2 className=" mb-10 text-center text-xl font-semibold">
            {VideoInfo?.is_found === 1
              ? "Edit Video Details"
              : "Add Video Details"}
          </h2>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="flex w-3/4 xs:w-5/6 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Name platform filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div
              className={`flex w-1/2 flex-col items-start justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="platform">
                Platform{" "}
              </label>
              <select
                name="platform"
                id="platform"
                {...register("platform", { required: true })}
              >
                <option value="youtube">youtube</option>
                <option value="vimeo">vimeo</option>
                <option value="vdocipher">vdocipher</option>
              </select>

              {errors.platform && (
                <p className="text-xs text-red-500 ">
                  {errors.platform.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.platform && (
                    <p className=" text-xs text-red-500  ">
                      {VideoAddEditErrors?.response?.data?.errors?.platform[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 140,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className=" text-xs text-red-500  ">
                  {errors.name.type === "required" && "This field is required."}
                  {errors.name.type === "pattern" &&
                    "Only English Letters are Allowed."}
                  {errors.name.type === "maxLength" &&
                    "Allowed Name Max Length is 140."}
                  {errors.name.type === "minLength" &&
                    "Please Enter at Least 3 Letters."}
                </p>
              )}

              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.name && (
                    <p className=" text-xs text-red-500  ">
                      {VideoAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** vdo_id is_public*/}
          <div className="flex  w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" vdo_id flex w-1/3 flex-col items-start justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="vdo_id">
                Video Link{" "}
              </label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="vdo_id"
                placeholder="Video Link "
                name="vdo_id"
                autoComplete="on"
                {...register("vdo_id", {
                  required: true,
                  maxLength: 155,
                  minLength: 3,
                  //pattern: /^[A-Za-z]+$/,
                })}
              />
              {errors.vdo_id && (
                <p className=" text-xs text-red-500  ">
                  {errors.vdo_id.type === "required" &&
                    "This field is required."}
                  {errors.vdo_id.type === "pattern" &&
                    "Only English Letters are Allowed."}
                  {errors.vdo_id.type === "maxLength" &&
                    "Allowed Video Link Max Length is 155."}
                  {errors.vdo_id.type === "minLength" &&
                    "Please Enter at Least 3 Letters."}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.vdo_id && (
                    <p className=" text-xs text-red-500  ">
                      {VideoAddEditErrors?.response?.data?.errors?.vdo_id[0]}
                    </p>
                  )
              }
            </div>
            <div className=" flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="views">
                Views{" "}
              </label>
              <input
                className="signin-inputs  w-full "
                type="number"
                min={1}
                id="views"
                placeholder="000"
                name="views"
                autoComplete="on"
                {...register("views", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.views && (
                <p className="text-xs text-red-500 ">
                  {errors.views.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.views && (
                    <p className=" text-xs text-red-500  ">
                      {VideoAddEditErrors?.response?.data?.errors?.views[0]}
                    </p>
                  )
              }
            </div>
            <div className=" is_public flex w-1/3 flex-col items-start justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end md:justify-center gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="is_public"
                    name="is_public"
                    {...register("is_public", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="is_public"
                  >
                    Free{" "}
                  </label>
                </div>
              </div>

              {errors.is_public && (
                <p className="text-xs text-red-500 ">
                  {errors.is_public.type === "required" &&
                    "This field is required."}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                VideoAddEditErrors &&
                  VideoAddEditErrors?.response?.data?.errors?.is_public && (
                    <p className=" text-xs text-red-500  ">
                      {VideoAddEditErrors?.response?.data?.errors?.is_public[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className=" flex w-full flex-col items-start justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-xs text-red-500 ">
                {errors.description.type === "maxLength" &&
                  "Allowed Description Max Length is 20."}
                {errors.description.type === "minLength" &&
                  "Please Enter at Least 3 Letters."}
              </p>
            )}
            {
              //!-------server errors -----

              VideoAddEditErrors &&
                VideoAddEditErrors?.response?.data?.errors?.description && (
                  <p className=" text-xs text-red-500  ">
                    {VideoAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditVideo.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditVideo.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {VideoInfo?.is_found === 1 ? "Edit Video" : "Add Video"}</p>
            )}
          </button>
        </form>
      </div>
      {VideoInfo?.is_found === 1 && (
        <div className="iframe-wrapper aspect-video  w-3/4 xs:w-5/6 overflow-hidden rounded-xl border-2">
          <iframe
            src={state.src}
            width="100%"
            height="100%"
            title="YouTube video player"
            frameBorder="0"
            //style={{ aspectRatio: "16 / 9" }}
            allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </section>
  );
}

export default AddEditVideo;
