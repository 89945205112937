import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";
import { useState } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";

const ViewStudent = () => {
  const { studentID } = useParams();
  const [filterActive, setFilterActive] = useState(1);
  const tableFor =
    filterActive === 1
      ? "purchaseHistoryStudentView"
      : filterActive === 2
      ? "trialExam"
      : filterActive === 3
      ? "logs"
      : "";

  const table = useTable(tableFor, "", "", filterActive, "", studentID);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"View Student | Mr.Mohamed Salama"}></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        View Student
      </h2>

      <div
        className={`filters flex justify-between  items-center w-1/2 md:w-full md:flex-col md:px-6 h-12 relative mb-14 transition-all duration-200 ease-in-out`}
      >
        <button
          onClick={() => setFilterActive(1)}
          className={`z-10 h-full w-1/4 text-base font-semibold md:w-full ${
            filterActive === 1 && "rounded-md bg-green duration-300"
          } `}
        >
          Purchase History
        </button>
        <button
          onClick={() => setFilterActive(2)}
          className={`z-10 h-full w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 2 && "rounded-md bg-green duration-300"
          } `}
        >
          Trial Exam History
        </button>
        <button
          onClick={() => setFilterActive(3)}
          className={`z-10 h-full w-1/4 text-base font-semibold  md:w-full ${
            filterActive === 3 && "rounded-md bg-green duration-300"
          } `}
        >
          Logs
        </button>
      </div>

      <div className="table-container w-full h-fit overflow-hidden">
        {table}
      </div>
    </section>
  );
};

export default ViewStudent;
