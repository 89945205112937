import { useEffect, useRef } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { faFileSignature, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Loader } from "../../../MainComponents";

function SingleExamPage() {
  const [buyExam, setBuyExam] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  const { examID } = useParams();
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  //!---- get Exam info -------
  const [examInfoData, examInfoErrors, examInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_TRIAL_EXAMS_STUDENT_TAB_API}/${examID}`,
    "GET",
    examID,
    refetch
  );

  //!---- buy Exam  -------
  const [buyExamSuccess, buyExamErrors, submitLoading] = useAxios(
    process.env.REACT_APP_BUY_TRIAL_EXAMS_STUDENT_TAB_API,
    "POST",
    buyExam.flag,
    buyExam.dependency,
    buyExam.data,
    true
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onTouched",
  });
  const handleBuyExam = (data) => {
    setBuyExam((prev) => ({
      ...prev,
      flag: "BuyExam",
      dependency: !prev.dependency,
      data: { ...data, id: examID },
    }));
  };

  useEffect(() => {
    if (buyExamSuccess) {
      setRefetch((prev) => !prev);
      reset();
    }
  }, [buyExamSuccess, reset]);

  if (examInfoLoading) {
    return <Loader />;
  }

  if (examInfoErrors && typeof examInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{examInfoErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width mb-32 flex h-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Exams || Mr.Mohamed Salama"></HelmetTags>
      <h1 className="course-name text-center text-3xl font-bold my-12">
        {examInfoData?.data?.exam_info.title}
      </h1>
      <div className="exam-details flex w-3/5 flex-col items-center gap-12 rounded-3xl bg-white p-8 md:w-full">
        <div className="img-wrapper h-96 w-full cursor-pointer overflow-hidden rounded-3xl">
          <img
            className="h-full w-full object-cover duration-300 hover:scale-125
          "
            src={examInfoData?.data?.exam_info.img}
            alt={examInfoData?.data?.exam_info.title}
          />
        </div>
        <h2 className="text-4xl font-bold">
          {examInfoData?.data?.exam_info.title}
        </h2>
        <h3>{examInfoData?.data?.exam_info.description}</h3>
        <div className="time-price flex w-3/4 items-center justify-center gap-5">
          <p className="flex h-10 w-fit items-center justify-center gap-1 rounded-xl bg-secondary text-white  px-3 font-semibold">
            <span>{examInfoData?.data?.exam_info.duration}</span>
            <span>Min </span>
          </p>
        </div>

        <div className="quiz flex flex-col justify-between w-full border-t-2 border-t-secondary md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
          <h2 className="my-8 w-full text-xl text-center font-semibold">
            <FontAwesomeIcon icon={faFileSignature} />
            <span className="ml-2">Exam</span>
          </h2>

          {examInfoData?.data?.exam_info?.is_taken === 1 && (
            <div className="student-quiz-info flex w-full flex-col gap-10">
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Degree</span>
                <span>{examInfoData?.data?.exam_info?.degree}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Average grades</span>
                <span>{examInfoData?.data?.exam_info?.average}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Grades in percentage</span>
                <span>{examInfoData?.data?.exam_info?.percentage}</span>
              </h2>
              <h2 className="flex w-full items-center justify-between ">
                <span className="font-semibold">Student ranking </span>
                <span>{examInfoData?.data?.exam_info?.rank}</span>
              </h2>
            </div>
          )}

          {examInfoData?.data?.can_buy !== 0 && (
            <>
              {examInfoData?.data?.exam_info?.is_taken === 1 ? (
                <>
                  {examInfoData?.data?.exam_info?.is_taken === 1 &&
                    examInfoData?.data?.exam_info?.v_model === 1 && (
                      <Link
                        to={`/exams/modal-answer/${examID}`}
                        className="w-full flex items-center justify-center mt-8 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-white  duration-300 hover:bg-white hover:text-secondary active:scale-90"
                      >
                        Modal Answer
                      </Link>
                    )}
                </>
              ) : (
                <Link
                  to={`/exams/solve-exam/${examID}`}
                  className="w-full flex items-center justify-center mt-8 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-white  duration-300 hover:bg-white hover:text-secondary active:scale-90"
                >
                  Start Exam
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default SingleExamPage;
