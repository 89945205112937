//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditCategory() {
  const navigate = useNavigate();
  const [submitCategory, setSubmitCategory] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [addCategorySuccess, addCategoryErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADD_CATEGORY_API,
    "POST",
    submitCategory.flag,
    submitCategory.dependency,
    submitCategory.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitCategory({
      flag: "addCategoryRequest",
      dependency: !submitCategory.dependency,
      data: data,
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addCategorySuccess) {
      reset();
      navigate(-1);
    }
  }, [addCategorySuccess]);

  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Add Category | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-xl font-semibold">
          Please Fill All Required Fields To Add New Category
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          className="flex w-3/4 flex-col items-start  justify-center gap-6 rounded-3xl bg-white p-10 shadow-lg shadow-blue/50"
        >
          {/** Category name */}

          <div className=" flex w-full flex-col items-start justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              Category name
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="Add category"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,
                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-xs text-red-500 ">
                {errors.name.type === "required" && "This field is required."}{" "}
                {errors.name.type === "maxLength" &&
                  "Allowed category name Max Length is 100 char"}
                {errors.name.type === "minLength" &&
                  " Please Enter at Least 3 Letters."}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.name && (
                  <p
                    className=" text-xs text-red-500
  "
                  >
                    {addCategoryErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** stage*/}
          <div
            className={`flex w-full flex-col items-start justify-center  gap-2 `}
          >
            <label className="w-full truncate" htmlFor="stage">
              Stage{" "}
            </label>
            <select
              name="stage"
              id="stage"
              {...register("stage", { required: true })}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>

            {errors.stage && (
              <p
                className="text-xs text-red-500
 "
              >
                {errors.stage.type === "required" && "This field is required."}{" "}
              </p>
            )}
            {
              //!-------server errors -----

              addCategoryErrors &&
                addCategoryErrors?.response?.data?.errors?.stage && (
                  <p
                    className=" text-xs text-red-500
  "
                  >
                    {addCategoryErrors?.response?.data?.errors?.stage[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitCategory.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitCategory.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Add Category"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditCategory;
