import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useParams } from "react-router-dom";

const AdminEditViewCourse = () => {
  const [values, setValues] = useState(null);

  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [refetch, setRefetch] = useState(false);
  const [border, setBorder] = useState(false);
  const { ID } = useParams();

  const [
    getCourseViewInputs,
    getCourseViewInputsErrors,
    getCourseViewInputsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSE_VIEW_TABLE_GET_EDIT_API}/${ID}`,
    "GET",
    "GET",
    "",
    ""
  );

  useEffect(() => {
    if (getCourseViewInputs) setValues(getCourseViewInputs?.data);
  }, [getCourseViewInputs]);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  let api = process.env.REACT_APP_GENERATE_CODES_API;
  const [generateCodesSuccess, generateCodesErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );

  const onSubmit = (data) => {
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: data,
    });
  };

  useEffect(() => {
    //! reset the form on success submit
    if (generateCodesSuccess) {
      setBorder(true);
      reset();
      setRefetch(!refetch);
    }
  }, [generateCodesSuccess]);
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"Course | Mr.Mohamed Salama"}></HelmetTags>
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        Edit View Course
      </h2>
      <div className="flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-star">
        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="shadow-l flex h-fit w-1/2  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-64 "
        >
          <div className="first-row flex w-full items-start justify-between gap-6 md:flex-col md:justify-start">
            {/** Classroom field */}
            <div className=" flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full" htmlFor="expire_at">
                Expire At
              </label>
              <input
                id="expire_at"
                className="signin-inputs  w-full"
                type="datetime-local"
                name="expire_at"
                {...register("expire_at", {
                  required: true,
                })}
              />

              {errors.expire_at && (
                <p className="text-xs text-red-500 ">
                  {errors.expire_at.type === "required" &&
                    "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                generateCodesErrors &&
                  generateCodesErrors?.response?.data?.errors?.type && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {generateCodesErrors?.response?.data?.errors?.type[0]}
                    </p>
                  )
              }
            </div>
            {/* Type messages field */}
            <div className="flex flex-col items-end justify-center gap-2 w-1/2 md:w-full">
              <label className="w-full truncate" htmlFor="type">
                Type
              </label>
              <select
                name="type"
                id="type"
                {...register("type", { required: true })}
              >
                <option value="all">All</option>
                <option value="not-approved">Not approved students</option>
                <option value="active-students">Active students</option>
              </select>

              {errors.type && (
                <p className="text-xs text-red-500 ">
                  {errors.type.type === "required" && "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                generateCodesErrors &&
                  generateCodesErrors?.response?.data?.errors?.type && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {generateCodesErrors?.response?.data?.errors?.type[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** message field */}
          <div className="flex w-full items-start justify-between gap-2 flex-col md:justify-start">
            <label className="w-full truncate" htmlFor="messageTo">
              Message to
            </label>
            <select
              name="messageTo"
              id="messageTo"
              {...register("messageTo", { required: true })}
            >
              <option value="student">Student</option>
              <option value="student-parent-1">Student Parent 1</option>
              <option value="student-parent-2">Student Parent 2</option>
            </select>

            {errors.messageTo && (
              <p className="text-xs text-red-500 ">
                {errors.messageTo.type === "required" &&
                  "This field is required."}
              </p>
            )}
            {
              //!-------server errors -----

              generateCodesErrors &&
                generateCodesErrors?.response?.data?.errors?.messageTo && (
                  <p className="w-full text-end text-xs text-red-500  ">
                    {generateCodesErrors?.response?.data?.errors?.type[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}
          <button
            disabled={!isValid || (submitLoading && submitGenerate.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitGenerate.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Send Message"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminEditViewCourse;
