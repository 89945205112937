import { Link, useNavigate } from "react-router-dom";
// import SingleCourse from "../../../MainComponents/SingleCourse";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";
import useAxios from "../../../MainComponents/Hooks/useAxios";
function Exams() {
  const navigate = useNavigate();
  //!---- get all Exams  -------
  const [allExamsData, allExamsErrors, allExamsLoading] = useAxios(
    process.env.REACT_APP_STUDENT_TRIAL_EXAMS_STUDENT_TAB_API,
    "GET",
    "GET"
  );

  if (allExamsLoading) {
    return <Loader />;
  }

  if (allExamsErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allExamsErrors}</p>
      </div>
    );
  }
  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light ">
      <HelmetTags title="Exams || Mr.Mohamed Salama"></HelmetTags>{" "}
      <h1 className="course-name text-center text-3xl font-bold mt-12">
        All Exams
      </h1>
      <div className={`courses grid-auto-fit width mt-10 `}>
        {allExamsData?.data.map((exam, index) => (
          <div
            className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] sm:w-[300px] rounded-md p-4 shadow-lg`}
          >
            <div className="img-wrapper w-full rounded-md overflow-hidden">
              <img
                className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
                src={exam.img}
                alt={exam.title}
                onClick={() => navigate(`/exams/${exam.key}`)}
              />
            </div>

            <div className="details-wrapper mt-5">
              <div className="type-hours w-full flex justify-between items-center">
                <div className="type rounded-lg px-2 bg-pink text-white cursor-pointer">
                  {exam.type}
                </div>
              </div>

              <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
                <Link to={`/courses/${exam.key}`}>
                  <span className="cursor-pointer w-3/5  truncate">
                    {exam.title}
                  </span>
                </Link>
              </h2>
              <p className="description">{exam.description}</p>

              <Link
                to={`/exams/${exam.key}`}
                className="mt-6 flex justify-center items-center gap-2 w-full bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
              >
                View
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Exams;
