import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Link } from "react-router-dom";

const AdminViewExam = () => {
  const { ID } = useParams();
  const table = useTable("viewStudentExam", "", "", "", "", ID);
  
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Trial Exams | Mr.Mohamed Salama"></HelmetTags>{" "}
      <h2 className=" text-start text-2xl font-bold ">Students</h2>
      <Link to={`/admin/exams/all-students/${ID}/top-students`} className="w-fit text-white rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold  duration-300 hover:bg-bg hover:text-secondary active:scale-90 mt-10">Top Students</Link>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminViewExam;
