import { useEffect } from "react";
import { toast } from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { useState } from "react";

const StudentNumberCheck = ({
  setCourseSelectValues,
  setMobileNumber,
  type,
}) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  const studentNumberCheckInput = watch("mobile");

  //!--------- add edit assistant -------

  const api =
    type === "purchases"
      ? process.env.REACT_APP_ADMIN_GET_STD_NUMBER_PURCHASE_EXCEPTION_API
      : type === "unlockCourse"
      ? process.env
          .REACT_APP_ADMIN_GET_STUDENT_COURSES_UNLCOK_COURSE_EXCEPTION_API
      : type === "views"
      ? process.env.REACT_APP_ADMIN_GET_STUDENT_COURSES_BY_VIEWS_EXCEPTION_API
      : type === "invisiblecourses"
      ? process.env.REACT_APP_ADMIN_GET_INVISBLE_COURSES_API
      : process.env.REACT_APP_ADMIN_CHECK_NUMBER_STUDENT_EXCEPTION_API;

  const [
    studentNumberCheckSucsses,
    studentNumberCheckErrors,
    studentNumberCheckLoading,
  ] = useAxios(api, "POST", formFlag, formDependency, formValues);

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag("EditAssistantsInformations");
    setFormDependency(true);
    setMobileNumber(formData.mobile);
  };

  useEffect(() => {
    if (studentNumberCheckInput?.length === 11) {
      onSubmit({ mobile: studentNumberCheckInput });
    } else {
      setCourseSelectValues([]);
    }
  }, [
    setCourseSelectValues,
    studentNumberCheckInput,
    studentNumberCheckInput?.length,
  ]);

  useEffect(() => {
    if (studentNumberCheckSucsses) {
      setFormFlag("");
      setFormDependency(false);
      setCourseSelectValues(studentNumberCheckSucsses?.data);
    }
  }, [setCourseSelectValues, studentNumberCheckSucsses]);

  useEffect(() => {
    if (studentNumberCheckErrors) {
      toast.error(studentNumberCheckErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [studentNumberCheckErrors]);

  useEffect(() => {
    reset();
  }, [type, reset]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <label htmlFor="mobile">Mobile</label>
      <input
        className="signin-inputs w-full mt-2"
        type="number"
        id="mobile"
        placeholder="Mobile"
        name="mobile"
        {...register("mobile", {
          required: true,
          pattern: /^[\d]{11}/,
          maxLength: 11,
        })}
      />

      {/* Mobile number validation errors */}
      {errors.mobile && (
        <p className="mt-2 text-xs text-red-500">
          {errors.mobile.type === "required" && "Please, fill this input"}
          {errors.mobile.type === "pattern" &&
            "Enter a phone number with 11 digits"}
          {errors.mobile.type === "maxLength" &&
            "Enter a phone number with 11 digits"}
        </p>
      )}
      {/* Server errors for mobile number */}
      {studentNumberCheckErrors &&
        studentNumberCheckErrors?.response?.data?.errors?.mobile && (
          <p className="text-xs text-red-500">
            {studentNumberCheckErrors?.response?.data?.errors?.mobile[0]}
          </p>
        )}
    </form>
  );
};

export default StudentNumberCheck;
