import { Link } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faClock,
  faEnvelope,
  faHashtag,
  faLocationDot,
  faMobileScreenButton,
  faSchoolFlag,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const RequestsCard = ({ std, handleAcceptReq, handleDeleteReq }) => {
  const AuthState = useAuthState();

  return (
    <div
      key={std.key}
      //!single student
      className="mx-auto flex h-fit w-5/6 items-start justify-between gap-12 rounded-[50px] bg-red-100 p-6 lg:w-full overflow-x-hidden amd:flex-col"
    >
      <div
        //!std-left
        className="std-img flex h-auto w-1/2 flex-col items-center justify-center gap-9 amd:w-full "
      >
        <div className="name-stage">
          {std.facebooklink ? (
            <a
              href={std.facebooklink}
              target="_blank"
              className="text-white rounded-xl border-2 border-secondary bg-secondary px-2  font-semibold text-light transition-all duration-300 hover:bg-transparent hover:text-secondary active:scale-90"
              rel="noreferrer"
            >
              {std.name}
            </a>
          ) : (
            <div className="text-white rounded-xl border-2 border-secondary bg-secondary px-2  font-semibold text-light transition-all duration-300 hover:bg-transparent hover:text-secondary active:scale-90">
              {std.name}
            </div>
          )}

          <h4 className="mt-3 text-center text-lg opacity-60">
            Stage {std.stage}
          </h4>
        </div>
        <div className="std-img h-40 w-40 overflow-hidden rounded-full border-4 border-blue bg-green">
          <img
            className="h-full w-full object-cover object-top"
            src={std.profile_pic}
            alt={std.name}
          />
        </div>
        <div className="btns flex items-center justify-center gap-7">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("request_accept")
            )) && (
            <button
              onClick={() => handleAcceptReq(std.key)}
              className="approve-btn w-20  rounded-xl border-2 border-lemon bg-lemon py-[2px] text-center text-lg text-bg transition-all duration-300 hover:scale-105 hover:bg-bg hover:text-lemon active:scale-90"
            >
              Approve
            </button>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("request_delete")
            )) && (
            <Popconfirm
              okText="Confirm"
              okType="danger"
              cancelText="Close"
              color="#ffffff"
              //okButtonProps={{ loading: loading }}
              onConfirm={() => handleDeleteReq(std.key)}
              title="Do you  want to delete the request of the student ?"
            >
              <button className="approve-btn w-20  rounded-xl border-2 border-delete bg-delete py-[2px] text-center text-lg text-bg transition-all duration-300 hover:scale-105 hover:bg-bg  hover:text-delete active:scale-90">
                Delete
              </button>
            </Popconfirm>
          )}
        </div>
      </div>
      <div
        //!std-right
        className="std-details amd: flex h-auto w-1/2  items-start  justify-center gap-5  amd:w-full"
      >
        <div className="flex h-auto w-fit flex-col items-start justify-start gap-5 ">
          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faHashtag} />
            </div>
            <h4 className="text-lg ">
              Number: <span className="text-base opacity-80">{std.n}</span>
            </h4>
          </div>
          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faEnvelope} />
            </div>
            <h4 className="text-lg ">
              {" "}
              E-mail: <span className="text-base opacity-80">{std.email}</span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faSchoolFlag} />
            </div>
            <h4 className="text-lg ">
              {" "}
              School: <span className="text-base opacity-80">{std.school}</span>
            </h4>
          </div>
          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faMobileScreenButton} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Mobile:{" "}
              <span className="text-base opacity-80">
                {std.mobile}
                <Link to={`https://wa.me/+2${std.mobile}`} target="_blank">
                  {" "}
                  <FontAwesomeIcon icon={faWhatsapp} />
                </Link>{" "}
              </span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faMobileScreenButton} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Parent's Mobile:{" "}
              <span className="text-base opacity-80 xs:text-center">
                {std.parent_mobile}
                <Link
                  to={`https://wa.me/+2${std.parent_mobile}`}
                  target="_blank"
                >
                  {" "}
                  <FontAwesomeIcon icon={faWhatsapp} />
                </Link>
              </span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faMobileScreenButton} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Parent's Mobile 2:{" "}
              <span className="text-base opacity-80 xs:text-center">
                {std.parent_mobile2}
                <Link
                  to={`https://wa.me/+2${std.parent_mobile2}`}
                  target="_blank"
                >
                  {" "}
                  <FontAwesomeIcon icon={faWhatsapp} />
                </Link>
              </span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faBriefcase} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Parent's Job:{" "}
              <span className="text-base opacity-80">{std.parent_job}</span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faClock} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Section :{" "}
              <span className="text-base opacity-80"> {std.section}</span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faLocationDot} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Area : <span className="text-base opacity-80"> {std.area}</span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faClock} />
            </div>
            <h4 className="text-lg ">
              Year: <span className="text-base opacity-80"> {std.year}</span>
            </h4>
          </div>

          <div className="flex justify-start">
            <div className="w-8 min-w-[32px] ">
              <FontAwesomeIcon className="" icon={faClock} />
            </div>
            <h4 className="text-lg ">
              {" "}
              Created At:{" "}
              <span className="text-base opacity-80"> {std.created_at}</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestsCard;
