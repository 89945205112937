import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faFilePdf,
  faFilePen,
  faVideo,
  faFileLines,
  faPlus,
  faSpinner,
  faClose,
  faPen,
  faCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Popconfirm } from "antd";
function SessionType(props) {
  //!--- DND Imports ---
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.type.id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  //!--- Rest Imports -----
  const AuthState = useAuthState();
  const navigate = useNavigate();
  function reducer(state, action) {
    switch (action.type) {
      case "setEditFormName": {
        return {
          ...state,
          editFormName: action.payload,
        };
      }
      case "setEditFormVisibility": {
        return {
          ...state,
          editFormVisibility: action.payload,
        };
      }
      case "setEditFormErrors": {
        return {
          ...state,
          editFormErrors: action.payload,
        };
      }
      case "setSubmitEditContent": {
        return {
          ...state,
          submitEditContent: action.payload,
        };
      }
      case "setSubmitDeleteContent": {
        return {
          ...state,
          submitDeleteContent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    editFormName: "",
    editFormVisibility: null,
    editFormErrors: "",
    submitEditContent: { flag: "", dependency: false, data: {} },
    submitDeleteContent: { flag: "", dependency: false, data: {} },
  });
  //! ---- edit content  request ----
  const [EditContentSuccess, EditContentErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_EDIT_SESSION_TYPE_CONTENT_API,
    "POST",
    state.submitEditContent.flag,
    state.submitEditContent.dependency,
    state.submitEditContent.data,
    true
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.editFormName === "") {
      dispatch({
        type: "setEditFormErrors",
        payload: "name",
      });
    } else {
      dispatch({
        type: "setSubmitEditContent",
        payload: {
          flag: "EditContent",
          dependency: !state.submitEditContent.dependency,
          data: {
            content_id: props.type.id,
            name: state.editFormName,
            visibility: state.editFormVisibility,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (state.editFormName !== "") {
      dispatch({
        type: "setEditFormErrors",
        payload: "",
      });
    } else if (state.editFormVisibility !== null) {
      dispatch({
        type: "setEditFormErrors",
        payload: "",
      });
    }
  }, [state.editFormName, state.editFormVisibility]);

  useEffect(() => {
    dispatch({
      type: "setEditFormName",
      payload: props.type.name,
    });
    dispatch({
      type: "setEditFormVisibility",
      payload: props.type.visibility,
    });
  }, []);

  //! ---- delete content  request ----
  const [DeleteContentSuccess, DeleteContentErrors, DeleteSubmitLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_DELETE_SESSION_TYPE_CONTENT_API,
      "POST",
      state.submitDeleteContent.flag,
      state.submitDeleteContent.dependency,
      state.submitDeleteContent.data,
      true
    );
  function handleDeleteContent(id) {
    dispatch({
      type: "setSubmitDeleteContent",
      payload: {
        flag: "DeleteContent",
        dependency: !state.submitDeleteContent.dependency,
        data: {
          id: id,
        },
      },
    });
  }

  useEffect(() => {
    //! reset the form on success submit

    if (EditContentSuccess) {
      props.dispatch({
        type: "setCurrentEditable",
        payload: null,
      });
      dispatch({
        type: "setEditFormErrors",
        payload: "",
      });

      props.dispatch({
        type: "setRefetch",
        payload: !props.state.refetch,
      });
    }
    if (DeleteContentSuccess) {
      props.dispatch({
        type: "setRefetch",
        payload: !props.state.refetch,
      });
    }
  }, [EditContentSuccess, DeleteContentSuccess]);

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className={`hover:bg-secondary/30 group  hover:text-whit transition-all duration-100 ease-in-out px-7 xs:px-2 border-y-bg border-y-2 !cursor-pointer type-video flex justify-between gap-4 items-center w-full h-10  `}
    >
      <div
        className={`icon-type-wrapper w-6 ${
          props.type.id === props.state.currentEditable && "xs:hidden"
        }`}
      >
        <FontAwesomeIcon
          icon={
            props.type.type === "quiz"
              ? faFilePen
              : props.type.type === "video"
              ? faVideo
              : props.type.type === "attachment"
              ? faFilePdf
              : props.type.type === "homework"
              ? faFileLines
              : ""
          }
        />
      </div>
      <div className="title-form flex justify-between items-center gap-5 w-full ">
        {props.type.id !== props.state.currentEditable ? (
          <>
            <button
              className={` ${props.type.visibility === 0 && "!text-blue/60"}`}
              onClick={() =>
                navigate(
                  props.type.type === "quiz" &&
                    (AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith(
                            "lecture_quizzes_permissions"
                          )
                      ))
                    ? `/admin/courses/quiz/${props.type.id}`
                    : props.type.type === "homework" &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith(
                              "lecture_homeworks_permissions"
                            )
                        ))
                    ? `/admin/courses/homework/${props.type.id}`
                    : props.type.type === "video" &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith(
                              "lecture_videos_permissions"
                            )
                        ))
                    ? `/admin/courses/video/${props.type.id}`
                    : props.type.type === "attachment" &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith(
                              "lecture_material_permissions"
                            )
                        ))
                    ? `/admin/courses/attachment/${props.type.id}`
                    : null
                )
              }
            >
              {" "}
              {props.type.name}
            </button>
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              (AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_quizzes_permissions")
              ) &&
                props.type.type === "quiz") ||
              (AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_videos_permissions")
              ) &&
                props.type.type === "video") ||
              (AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_material_permissions")
              ) &&
                props.type.type === "attachment") ||
              (AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture_homeworks_permissions")
              ) &&
                props.type.type === "homework")) && (
              <div className="type-options flex justify-center gap-6 items-center">
                <div
                  onClick={() =>
                    props.dispatch({
                      type: "setCurrentEditable",
                      payload: props.type.id,
                    })
                  }
                  className="type-edit-icon w-7 h-7 flex justify-center items-center border-2 rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-blue hover:text-white hover:border-blue"
                >
                  <FontAwesomeIcon icon={faPen} />
                </div>
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#ffffff"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDeleteContent(props.type.id)}
                  title="Are you sure you want to delete?"
                >
                  <button className="type-edit-icon w-7 h-7  flex justify-center items-center border-2 border-delete text-delete rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-delete hover:text-white hover:border-delete">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </Popconfirm>
              </div>
            )}
          </>
        ) : (
          <form
            onSubmit={onSubmit}
            method="post"
            className="flex  w-full   items-start  justify-between  gap-4  "
          >
            {/**
             *  //! name
             * */}
            <div className=" flex w-1/3  flex-col items-start justify-center gap-1 ">
              <input
                className={`signin-inputs  w-full`}
                type="text"
                id="name"
                placeholder="Content Name"
                name="name"
                autoComplete="on"
                value={state.editFormName}
                onChange={(e) =>
                  dispatch({
                    type: "setEditFormName",
                    payload: e.target.value,
                  })
                }
              />

              {state.editFormErrors === "name" && (
                <p className="text-xs text-red-500 z-20">
                  This field is required.
                </p>
              )}
              {
                //!-------server errors -----

                EditContentErrors &&
                  EditContentErrors?.response?.data?.errors?.name && (
                    <p className=" text-xs text-red-500 z-20">
                      {EditContentErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            {/**
             *  //! visibility
             * */}
            <div className=" visibility flex w-1/3 flex-col items-start justify-center gap-1 ">
              <div className="flex w-full  items-center justify-center gap-5 ">
                <label className="visibility-switch w-full ">
                  <input
                    className="w-full"
                    id="visibility"
                    name="visibility"
                    type="checkbox"
                    checked={state.editFormVisibility === 1}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch({
                          type: "setEditFormVisibility",
                          payload: 1,
                        });
                      } else {
                        dispatch({
                          type: "setEditFormVisibility",
                          payload: 0,
                        });
                      }
                    }}
                  />
                  <span className="visibility-slider w-full">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div className="sm:hidden">
                  <label
                    className="!w-fit cursor-pointer truncate"
                    htmlFor="visibility"
                  >
                    Visibility{" "}
                  </label>
                </div>
              </div>

              {state.editFormErrors === "visibility" && (
                <p className="text-xs text-red-500 z-20">
                  This field is required.
                </p>
              )}
              {
                //!-------server errors -----

                EditContentErrors &&
                  EditContentErrors?.response?.data?.errors?.visibility && (
                    <p className=" text-xs text-red-500  z-20">
                      {EditContentErrors?.response?.data?.errors?.visibility[0]}
                    </p>
                  )
              }
            </div>
            <div className="edit-form-content-options w-1/3 flex justify-end items-center gap-6">
              {/**
               *  //! submit
               * */}
              <button
                disabled={
                  state.editFormName === "" ||
                  (submitLoading && state.submitEditContent.flag)
                }
                className="edit-form-submit-icon disabled:text-blue/30 w-7 h-7 flex justify-center items-center border-2 rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-blue hover:text-white hover:border-blue"
                type="submit"
              >
                {submitLoading && state.submitEditContent.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </button>
              {/**
               *  //! cancel
               * */}
              <button
                onClick={() =>
                  props.dispatch({
                    type: "setCurrentEditable",
                    payload: null,
                  })
                }
                disabled={submitLoading && state.submitEditContent.flag}
                className="edit-form-cancel-icon w-7 h-7 flex justify-center items-center border-2 rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-delete hover:text-white hover:border-delete border-delete text-delete "
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </form>
        )}
      </div>{" "}
    </div>
  );
}

function AdminCourse() {
  function reducer(state, action) {
    switch (action.type) {
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setCurrentForm": {
        return {
          ...state,
          currentForm: action.payload,
        };
      }

      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }
      case "setSessions": {
        return {
          ...state,
          sessions: action.payload,
        };
      }

      case "setSubmitAddContent": {
        return {
          ...state,
          submitAddContent: action.payload,
        };
      }
      case "setSubmitDragContent": {
        return {
          ...state,
          submitDragContent: action.payload,
        };
      }
      case "setFormName": {
        return {
          ...state,
          formName: action.payload,
        };
      }
      case "setFormType": {
        return {
          ...state,
          formType: action.payload,
        };
      }

      case "setFormErrors": {
        return {
          ...state,
          formErrors: action.payload,
        };
      }

      //!---SessionType Actions ----
      case "setCurrentEditable": {
        return {
          ...state,
          currentEditable: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    src: "",
    refetch: false,
    currentVideo: "",
    currentForm: null,
    submitAddContent: { flag: "", dependency: false, data: {} },
    submitDragContent: { flag: "", dependency: false, data: {} },
    sessions: [],
    formName: "",
    formType: "",
    formErrors: "",

    //!---SessionType States ----
    currentEditable: null,
  });

  const navigate = useNavigate();
  const AuthState = useAuthState();
  const { courseID } = useParams();
  //!---- get course info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_COURSE_INFO_API}${courseID}`,
    "GET",
    courseID,
    state.refetch
  );
  useEffect(() => {
    if (LectureInfoSuccess) {
      dispatch({
        type: "setSessions",
        payload: LectureInfoSuccess.data.sessions,
      });
    }
  }, [LectureInfoSuccess]);

  //!--- add content request
  const [addContentSuccess, addContentErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_SESSION_TYPE_CONTENT_API,
    "POST",
    state.submitAddContent.flag,
    state.submitAddContent.dependency,
    state.submitAddContent.data,
    true
  );
  //! ---- drag content  request ----
  const [DragContentSuccess, DragContentErrors, DragSubmitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_DRAG_SESSION_TYPE_CONTENT_API,
    "POST",
    state.submitDragContent.flag,
    state.submitDragContent.dependency,
    state.submitDragContent.data
  );
  function handleDragEnd(event, index) {
    const { active, over } = event;
    //! to check if the element is not dragged over itself
    if (active.id !== over.id) {
      const oldIndex = LectureInfoSuccess.data.sessions[
        index
      ].session.findIndex((type) => type.id === active.id);

      const newIndex = LectureInfoSuccess.data.sessions[
        index
      ].session.findIndex((type) => type.id === over.id);
      //!make a copy of the array to make changes over it, and we splice the object that has changes and put it again in the same index but with the new sorting
      const newSessions = state.sessions;
      newSessions.splice(index, 1, {
        ...state.sessions[index],
        session: arrayMove(state.sessions[index].session, oldIndex, newIndex),
      });
      dispatch({
        type: "setSessions",
        payload: newSessions,
      });
      const IDSArray = newSessions[index].session.map((type, index) => ({
        id: type.id,
        n: index + 1,
      }));
      const finalData = { content: IDSArray };
      dispatch({
        type: "setSubmitDragContent",
        payload: {
          flag: "DragContent",
          dependency: !state.submitDragContent.dependency,
          data: finalData,
        },
      });
    }
  }
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  const onSubmit = (e, session_id) => {
    e.preventDefault();
    if (state.formName === "") {
      dispatch({
        type: "setFormErrors",
        payload: "name",
      });
    } else if (state.formType === "") {
      dispatch({
        type: "setFormErrors",
        payload: "type",
      });
    } else {
      dispatch({
        type: "setSubmitAddContent",
        payload: {
          flag: "addContentRequest",
          dependency: !state.submitAddContent.dependency,
          data: {
            name: state.formName,
            type: state.formType,
            session_id: session_id,
            course_id: courseID,
          },
        },
      });
    }
  };

  //! ---- delete content  request ----
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [deleteSessionSuccess, deleteSessionErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_SESSION_DELETE_INFO_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteSession(id) {
    setSubmitDelete({
      flag: "deleteExam",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete
    if (deleteSessionSuccess) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
    }
  }, [deleteSessionSuccess]);

  useEffect(() => {
    //! reset the form on success submit

    if (addContentSuccess) {
      dispatch({
        type: "setCurrentForm",
        payload: null,
      });
      dispatch({
        type: "setFormErrors",
        payload: "",
      });
      dispatch({
        type: "setFormName",
        payload: "",
      });
      dispatch({
        type: "setFormType",
        payload: "",
      });

      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
    }
    if (DragContentSuccess) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
    }
  }, [addContentSuccess, DragContentSuccess]);

  useEffect(() => {
    if (state.formName !== "") {
      dispatch({
        type: "setFormErrors",
        payload: "",
      });
    } else if (state.formType !== "") {
      dispatch({
        type: "setFormErrors",
        payload: "",
      });
    }
  }, [state.formName, state.formType]);

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full  overflow-y-auto px-10 py-10 sm:px-4  ">
      <HelmetTags title="Courses | Mr.Mohamed Salama"></HelmetTags>{" "}
      <div className="mb-7 w-full flex flex-col items-start gap-10 md:items-center ">
        {/* <h2 className=" text-2xl font-bold">Course {courseID}</h2> */}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_session_add")
          )) && (
          <Link
            to={`/admin/courses/add-session/${courseID}`}
            className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 "
          >
            Add Session <FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Link>
        )}
      </div>
      {/**
       * //!--  playlist  ---
       */}
      {state?.sessions ? (
        <section className="playlist-wrapper flex justify-center mt-10">
          <div
            className={`course-header-content relative transition-all duration-300 w-3/4  lg:w-full border-2 border-blue  overflow-y-auto h-auto`}
          >
            {/**
             * //!-- course-header-----
             */}
            <div className="course-header bg-blue text-white p-4 shadow-lg w-full ">
              <h2 className="course-title md:text-center text-xl font-bold">
                Course Content
              </h2>
              <p className="course-details md:text-center mt-3 ">
                {LectureInfoSuccess?.data.n_sessions} sections •{" "}
                {LectureInfoSuccess?.data.n_content} content
              </p>
            </div>
            {/**
             * //!-- course-content playlist-----
             */}
            <div
              className={`playlist course-content w-full  rounded-  overflow-hidden bg-bg  transition-{height} duration-500 ease-in-out `}
            >
              {state?.sessions?.map((session, index) => (
                <div
                  key={index}
                  className={`session-collapsed transition-{height} duration-700 ease-in-out w-full   `}
                >
                  {/**
                   * //!---session-title-wrapper-border-y ----
                   */}
                  <div
                    onClick={(e) => {
                      if (e.target.parentElement) {
                        e.target.parentNode.classList.toggle(
                          "collapse-session"
                        );
                      }
                    }}
                    className="session-title-wrapper w-full cursor-pointer border-y-2 px-4 flex justify-between items-center gap-5 h-16 bg-green "
                  >
                    <div className="icon_title flex justify-start gap-5 items-center font-semibold text-lg pointer-events-none">
                      <FontAwesomeIcon
                        className="chevron-icon transition-all duration-300 ease-in-out"
                        icon={faChevronUp}
                      />
                      <p className="">{session.name}</p>
                    </div>
                    <div className="session-details-left">
                      <div className="type-options flex justify-center gap-6 items-center">
                        {AuthState.userData?.admin?.roles[0]?.name ===
                          "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("lecture_session_update")
                        ) ? (
                          <Link
                            to={`/admin/courses/edit-session/${session.id}`}
                          >
                            <div className="type-edit-icon w-7 h-7 flex justify-center items-center border-2 rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-blue hover:text-white hover:border-blue">
                              <FontAwesomeIcon icon={faPen} />
                            </div>
                          </Link>
                        ) : null}

                        {AuthState.userData?.admin?.roles[0]?.name ===
                          "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("lecture_session_delete")
                        ) ? (
                          <Popconfirm
                            okText="Confirm"
                            okType="danger"
                            cancelText="Cancel"
                            color="#ffffff"
                            okButtonProps={{ loading: state.loading }}
                            onConfirm={() => handleDeleteSession(session.id)}
                            title="Are you sure you want to delete?"
                          >
                            <button className="type-edit-icon w-7 h-7  flex justify-center items-center border-2 border-delete text-delete rounded-md hover:scale-110 transition-all duration-300 ease-in-out active:scale-90 hover:bg-delete hover:text-white hover:border-delete">
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </Popconfirm>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/**
                   * //!---session-content-----
                   */}
                  <div className="session-content w-full  transition-all duration-700 ease-in-out h-fit py-4   flex flex-col items-center">
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={(event) => handleDragEnd(event, index)}
                    >
                      <SortableContext
                        items={session.session}
                        strategy={verticalListSortingStrategy}
                      >
                        {session.session?.map((type) => {
                          let src;
                          if (type.type === "video") {
                            src =
                              type.platform === "youtube"
                                ? `https://www.youtube.com/embed/${type.vdo_id}`
                                : type.platform === "vimeo"
                                ? `https://player.vimeo.com/video/${type.vdo_id}`
                                : `https://player.vdocipher.com/v2/?otp=${type.otp}&playbackInfo=${type.playbackInfo}`;
                          }

                          return (
                            <SessionType
                              key={type.id}
                              src={src}
                              currentVideo={state.currentVideo}
                              dispatch={dispatch}
                              state={state}
                              type={type}
                            />
                          );
                        })}
                      </SortableContext>
                    </DndContext>
                    {state.currentForm === index && (
                      <form
                        onSubmit={(e) => onSubmit(e, session.id)}
                        method="post"
                        className="flex   w-10/12  flex-col items-start  justify-center  rounded-3xl bg-white px-4 py-5 shadow-lg shadow-blue/50 mt-4"
                      >
                        {/**
                         *  //! name-type
                         * */}
                        <div className="name-type flex justify-between  w-full   md:flex-col items-start md:justify-start  md:items-center gap-6">
                          {/**
                           *  //! name
                           * */}
                          <div className=" flex w-1/2 md:w-full flex-col items-start justify-center gap-2 ">
                            <input
                              className="signin-inputs  w-full "
                              type="text"
                              id="name"
                              placeholder="Content Name"
                              name="name"
                              autoComplete="on"
                              value={state.formName}
                              onChange={(e) =>
                                dispatch({
                                  type: "setFormName",
                                  payload: e.target.value,
                                })
                              }
                            />
                            {state.formErrors === "name" && (
                              <p className="text-xs text-red-500">
                                This field is required.
                              </p>
                            )}
                            {
                              //!-------server errors -----

                              addContentErrors &&
                                addContentErrors?.response?.data?.errors
                                  ?.name && (
                                  <p className=" text-xs text-red-500 ">
                                    {
                                      addContentErrors?.response?.data?.errors
                                        ?.name[0]
                                    }
                                  </p>
                                )
                            }
                          </div>
                          {/**
                           *  //! type
                           * */}
                          <div
                            className={`flex w-1/2 md:w-full flex-col items-start justify-center  gap-2 `}
                          >
                            <select
                              placeholder="ddd"
                              name="type"
                              id="type"
                              value={state.formType}
                              onChange={(e) => {
                                dispatch({
                                  type: "setFormType",
                                  payload: e.target.value,
                                });
                              }}
                            >
                              <option hidden disabled value="">
                                Content Type
                              </option>
                              <option value="quiz">Quiz</option>
                              <option value="video">Video</option>
                              <option value="attachment">Attachment</option>
                              <option value="homework">Homework</option>
                            </select>

                            {state.formErrors === "type" && (
                              <p className="text-xs text-red-500">
                                This field is required.
                              </p>
                            )}
                            {
                              //!-------server errors -----

                              addContentErrors &&
                                addContentErrors?.response?.data?.errors
                                  ?.type && (
                                  <p
                                    className=" text-xs text-red-500
  "
                                  >
                                    {
                                      addContentErrors?.response?.data?.errors
                                        ?.type[0]
                                    }
                                  </p>
                                )
                            }
                          </div>
                        </div>
                        {/** submit */}
                        <button
                          disabled={
                            state.formName === "" ||
                            state.formType === "" ||
                            (submitLoading && state.submitAddContent.flag)
                          }
                          className=" submit mt-4 w-full "
                          type="submit"
                        >
                          {submitLoading && state.submitAddContent.flag ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            "Add Content"
                          )}
                        </button>
                      </form>
                    )}

                    {(AuthState.userData?.admin?.roles[0]?.name ===
                      "superadmin" ||
                      AuthState.userData?.admin?.permissions?.some(
                        (permission) =>
                          permission.name.startsWith("lecture_add_content")
                      )) && (
                      <div className="w-full flex justify-evenly items-center mt-4">
                        <button
                          onClick={() =>
                            dispatch({
                              type: "setCurrentForm",
                              payload: index,
                            })
                          }
                          className=" rounded-lg border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-secondary   active:scale-90 text-sm "
                        >
                          Add Content{" "}
                          <FontAwesomeIcon className="ml-1" icon={faPlus} />
                        </button>
                        {state.currentForm === index && (
                          <button
                            onClick={() => {
                              dispatch({
                                type: "setCurrentForm",
                                payload: null,
                              });
                              dispatch({
                                type: "setFormErrors",
                                payload: "",
                              });
                              dispatch({
                                type: "setFormName",
                                payload: "",
                              });
                              dispatch({
                                type: "setFormType",
                                payload: "",
                              });
                            }}
                            className=" rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 text-sm "
                          >
                            Cancel
                            <FontAwesomeIcon className="ml-1" icon={faClose} />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <loader />
      )}
    </section>
  );
}

export default AdminCourse;
