import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useEffect, useState } from "react";

import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";

import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext";
import Loader from "../../../../MainComponents/Loader";
import { CSVLink } from "react-csv";
import { Suspense } from "react";
import RequestsCard from "./RequestsCard.jsx";

function Requests() {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);
  const [academiclevel, setAcademiclevel] = useState(1);
  const [academicType, setAcademicType] = useState("scientific");

  const [left, setLeft] = useState("left-0");
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedInput, setSearchedInput] = useState("");

  //!--------- all Requests request -------

  const [allRequestsData, allRequestsErrors] = useAxios(
    `${process.env.REACT_APP_ALL_REQUESTS_API}/${academiclevel}`,
    "GET",
    "GET",
    academiclevel
  );
  useEffect(() => {
    if (allRequestsData) {
      setLoading(false);
      setFilteredRequests(allRequestsData?.data);
    }
  }, [academiclevel, allRequestsData]);

  //!--------- delete Request -------

  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  //!--------- accept Request -------

  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );
  useEffect(() => {
    //! refetch the data on success delete or accept request
    if (deleteRequestSuccess || acceptRequestSuccess) {
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]);

  function handleDeleteReq(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }

  function handleAcceptReq(id) {
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id: id },
    });
  }

  const handleChangeacAdemicLevel = (position, acadimcNum) => {
    setLeft(position);
    setAcademiclevel(acadimcNum);
  };

  const handleSearche = (e) => {
    setSearchedInput(e.target.value);

    const filterdData = allRequestsData?.data.filter((prev) =>
      prev.mobile.startsWith(e.target.value)
    );

    setFilteredRequests(filterdData);
  };

  const data =
    academiclevel === 2 || academiclevel === 3
      ? filteredRequests.filter((data) => data.section === academicType)
      : filteredRequests;

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4 flex flex-col items-start md:items-center">
      <HelmetTags title="Requests | Mr.Mohamed Salama"></HelmetTags>

      <div className="w-full flex justify-between items-center gap-5 mb-7">
        <h2 className=" text-start text-2xl font-bold ">Pending Students</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("request_accept")
          )) && (
          <button className=" rounded-lg border-2 border-delete bg-delete px-2 py-1 font-semibold text-bg transition-all duration-300 hover:bg-bg hover:text-delete   active:scale-90 ">
            <CSVLink data={data} filename="Requested Students">
              Export
            </CSVLink>{" "}
            <FontAwesomeIcon className="ml-2" icon={faDownload} />
          </button>
        )}
      </div>
      <div
        className={`filter relative flex  h-10 min-h-[40px] w-72 justify-start  mb-10`}
      >
        <button
          onClick={() => handleChangeacAdemicLevel("left-o", 1)}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 1
        </button>
        <button
          onClick={() => handleChangeacAdemicLevel("left-1/3", 2)}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 2
        </button>
        <button
          onClick={() => handleChangeacAdemicLevel("left-2/3", 3)}
          className="z-10 h-full w-1/3 text-base font-semibold "
        >
          Stage 3
        </button>
        <div
          className={`std-selector absolute top-0 h-full w-1/3 ${left} rounded-md bg-green duration-300`}
        ></div>
      </div>

      {academiclevel === 2 || academiclevel === 3 ? (
        <div
          className={`filter relative flex  h-10 min-h-[40px] w-72 justify-center  mb-10`}
        >
          <button
            onClick={() => setAcademicType("scientific")}
            className={`z-10 h-full w-1/3 text-base font-semibold  ${
              academicType === "scientific" && "bg-green"
            }`}
          >
            Scientific
          </button>
          <button
            onClick={() => setAcademicType("literary")}
            className={`z-10 h-full w-1/3 text-base font-semibold  ${
              academicType === "literary" && "bg-green"
            }`}
          >
            Literary
          </button>
        </div>
      ) : null}

      <div className="search-wrapper w-1/2 md:w-full mb-8">
        <input
          type="search"
          className="signin-inputs px-4 placeholder:!text-base"
          placeholder="Search..."
          onChange={(e) => handleSearche(e)}
          value={searchedInput}
        />
      </div>

      <div className="students flex w-full flex-col items-center gap-10">
        {loading ? (
          <Loader />
        ) : allRequestsData?.data?.length === 0 ? (
          <p className="w-full text-center">There are no new requests</p>
        ) : allRequestsErrors && typeof allRequestsErrors === "string" ? (
          <p className="w-full text-center">{allRequestsErrors}</p>
        ) : (
          <div
            //!students
            className="students flex w-full flex-col items-center gap-10"
          >
            <Suspense fallback={<div>Loading...</div>}>
              {data?.map((std, i) => (
                <RequestsCard
                  std={std}
                  key={i}
                  handleAcceptReq={handleAcceptReq}
                  handleDeleteReq={handleDeleteReq}
                />
              ))}
            </Suspense>
          </div>
        )}
      </div>
    </section>
  );
}

export default Requests;
