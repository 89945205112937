import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import QuestionAnswer from "./QuestionAnswer";
import { Link, useParams } from "react-router-dom";

const QuestionsAnswers = ({ state, dispatch, exam }) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const { ID } = useParams();

  const handelShowQuestion = (questionId) => {
    setShowQuestion(true);
    setCurrentSelectedIndex(questionId);
  };

  const handelRetake = () => {
    localStorage.removeItem(`Quiz${ID}${state?.quizInfo.title}`);
    dispatch({ type: "setAllData" });
    dispatch({ type: "setRefetch", payload: true });
  };

  return (
    <div
      className={`left-response-container  flex   w-3/4  items-start  md:w-full`}
    >
      <QuestionAnswer
        showQuestion={showQuestion}
        setShowQuestion={setShowQuestion}
        answeredQuestions={state.finishQuizData.questions}
        currentSelectedIndex={currentSelectedIndex}
      />

      <div
        className={`all-questions flex h-full flex-col items-center overflow-y-auto  bg-light p-[40px] text-light transition-all  duration-300 ease-in-out ${
          state.showQuestion ? "w-1/2  " : "w-full"
        } w-1/2 md:order-2 md:w-full`}
      >
        <h1 className="text-[20px] font-bold text-secondary ">Test Grades</h1>
        <p className="my-[20px] text-center text-secondary">
          Click on the question number to see its answer
        </p>

        <div className="response-questions myactual-questions-container my-[40px] w-full">
          {state?.finishQuizData?.questions.map((question, index) => (
            <div
              key={index}
              onClick={() => handelShowQuestion(index)}
              className={`single-ques-square  mb-[4px] flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-md text-[18px] font-bold text-[#fff] ${
                question.is_correct ? "bg-lime-500" : "bg-secondary"
              }  `}
            >
              {index + 1}
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center w-1/2 md:flex-col gap-3 mb-4">
          {state.finishQuizData?.is_faild === 1 ? (
            <>
              <button
                onClick={handelRetake}
                className="flex justify-center items-center gap-2 w-full bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
              >
                Retake
              </button>
              <Link
                to={
                  exam ? `/exams/${ID}` : `/courses/${state.quizInfo.course_id}`
                }
                className="flex justify-center items-center gap-2 w-full bg-blue  duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue  active:scale-90"
              >
                Back To Course
              </Link>
            </>
          ) : (
            <Link
              to={
                exam ? `/exams/${ID}` : `/courses/${state.quizInfo.course_id}`
              }
              className="flex justify-center items-center gap-2 w-full bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
            >
              {exam ? "Back To Exam" : "Back To Course"}
            </Link>
          )}
        </div>

        <div className="indicators flex w-full items-center justify-center gap-[20px] border-t-2 border-t-light py-[20px] sm:flex-col">
          <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-lime-500">
            <FontAwesomeIcon icon={faCircleCheck} />
            <p>Correct Answer</p>
          </div>
          <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-secondary">
            <FontAwesomeIcon icon={faCircleXmark} /> <p>Wrong Answer</p>
          </div>
          <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-amber-400">
            <FontAwesomeIcon icon={faSpinner} spinPulse />{" "}
            <p>Waiting For Correction</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsAnswers;
