import {
  faArrowRightFromBracket,
  faBroadcastTower,
  faChartLine,
  faCircleChevronRight,
  faClipboardQuestion,
  faCommentAlt,
  faFile,
  faFilePen,
  faGear,
  faGraduationCap,
  faHouse,
  faQrcode,
  faStarHalfStroke,
  faUser,
  faUserGear,
  faUserMinus,
  faUserPlus,
  faUserShield,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReducer } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  useAuthDispatch,
  useAuthState,
} from "../../../MainComponents/GlobalContext";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { SiVodafone } from "react-icons/si";
import { MdLocalOffer } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { useMemo } from "react";
import axios from "axios";

function AdminLayout() {
  const AuthDispatch = useAuthDispatch();
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const initialState = {
    collapse: false,
    showSidebar: false,
    refetch: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case "SetCollapse": {
        return {
          ...state,
          collapse: !state.collapse,
        };
      }
      case "SetRefetch": {
        return {
          ...state,
          refetch: !state.refetch,
        };
      }
      case "SetShowSidebar": {
        return {
          ...state,
          showSidebar: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  //!--------- get numbers in tabs-------
  const [NumbersInTabs, NumbersInTabsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_NUMBERS_IN_TABS_API,
    "GET",
    "GET",
    state.refetch,
    "",
    "",
    AuthState.refetchIfAcceptOrDeleteRequest
  );

  function handleSignOut() {
    dispatch({
      type: "SetShowSidebar",
      payload: false,
    });

    localStorage.removeItem("userData");
    AuthDispatch({ type: "setUserData", payload: null });
    navigate("/admin/signin");
  }

  const adminOrStuedntToken = JSON.parse(localStorage?.userData).admin_token
    ? JSON.parse(localStorage?.userData).admin_token
    : JSON.parse(localStorage?.userData).student_token;
  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + adminOrStuedntToken;
  }, [adminOrStuedntToken]);

  return (
    <section className="dark:bg-dark flex h-screen max-h-screen w-full overflow-hidden bg-bg dark:text-blue md:pt-16">
      <section className="header-mobile-only fixed inset-0 top-0 z-50  hidden h-16 w-full items-center justify-between bg-secondary px-16 text-blue md:flex">
        <div className="logo h-fit">
          <img
            className="h-12 cursor-pointer transition duration-200 ease-in-out hover:scale-105"
            onClick={() => navigate("/admin/dashboard")}
            src="https://api.our-matrix.com/uploads/assets/logo.png"
            alt="logo-dash-bg"
          />
        </div>
        <div className="burger-dash bg">
          <input
            onChange={() =>
              dispatch({
                type: "SetShowSidebar",
                payload: !state.showSidebar,
              })
            }
            checked={state.showSidebar}
            type="checkbox"
            id="checkbox"
          />
          <label htmlFor="checkbox" className="toggle">
            <div className="bar bar--top"></div>
            <div className="bar bar--middle"></div>
            <div className="bar bar--bottom"></div>
          </label>
        </div>
      </section>

      {/**
       * //!dash menu fixed
       *
       */}
      <div
        className={`dash-menu-fixed  z-40 ${
          state.collapse ? "w-24" : "w-1/5 "
        } fixed left-0 top-0 
                    h-full w-1/5 transition-all duration-200 ease-in-out ${
                      state.showSidebar
                        ? "md:pointer-events-auto md:w-full md:translate-x-0 md:opacity-100 bg-bg"
                        : "md:pointer-events-none  md:-translate-x-20 md:opacity-0"
                    } border-r-2 border-r-blue md:pt-32`}
      >
        <div className="collapse-btn-wrapper relative w-full md:hidden ">
          <FontAwesomeIcon
            onClick={() =>
              dispatch({
                type: "SetCollapse",
              })
            }
            className={` absolute right-0 top-20 z-50 translate-x-1/2 cursor-pointer rounded-full bg-bg text-4xl transition duration-200 ease-in-out hover:scale-105 ${
              state.collapse && "rotate-180"
            }`}
            icon={faCircleChevronRight}
          />
        </div>
        <div className="dash-menu-container  flex  h-full w-full   flex-col items-center justify-start gap-7 overflow-y-auto  py-10 ">
          <div className="logo h-fit md:hidden">
            <img
              className={` ${
                state.collapse ? "h-14" : "h-20"
              }  cursor-pointer transition duration-200 ease-in-out hover:scale-105`}
              onClick={() => navigate("/admin/dashboard")}
              src="https://api.our-matrix.com/uploads/assets/logo.png"
              alt="logo-dash-bg"
            />
          </div>

          <ul className="dash-menu-items flex h-fit w-full  flex-col items-center  ">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.length > 0) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3 "
                  to="/admin/dashboard"
                >
                  <div
                    className={`relative flex  w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faHouse} />
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Dashboard
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("student")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/students"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faUsers} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Students{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_add")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_delete")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_update")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/codes"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5 " icon={faQrcode} />{" "}
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Codes{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_add")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_delete")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_update")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/points-codes"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5 " icon={faQrcode} />{" "}
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Points Codes
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {/* Vodafone Cash Permission */}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_addvodafone")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_update_vodafone")
              ) ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("code_delete_vodafone")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/vodafone-cash-codes"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <SiVodafone />
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Vodafone cash codes
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {/* Offers Code Permmison */}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("offer")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/offers-codes"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <MdLocalOffer />
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Offers Codes
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("activationcode")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/activation-code "
                >
                  <div
                    className={`relative  flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5 " icon={faUserShield} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }  truncate alg:text-base lg:w-3/5`}
                      >
                        Activation Codes
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("send_whatsapp")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/whatsapp"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <BsWhatsapp />
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Whatsapp
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("code_tracker")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/codes-tracker"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <RiUserFollowLine />
                      <span
                        className={` 
                        ${state.collapse && "hidden"}   alg:text-base lg:w-3/5`}
                      >
                        Codes Tracker
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("lecture")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/courses"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faGraduationCap} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Courses{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("show_reports")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/reports"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faFile} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Reports
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("show_reports")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/late-students"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faUserMinus} />
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Late Students
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("exceptions")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/exception"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon
                        className="w-5"
                        icon={faBroadcastTower}
                      />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Exception
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("exam")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/exams"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faFilePen} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }  truncate alg:text-base lg:w-3/5`}
                      >
                        Trial Exams{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions.some((permission) =>
                permission.name.startsWith("questionbank")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/question-bank"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon
                        className="w-5"
                        icon={faClipboardQuestion}
                      />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }  truncate alg:text-base lg:w-3/5`}
                      >
                        Question Bank
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("request")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/requests "
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5 " icon={faUserPlus} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Requests{" "}
                      </span>
                    </div>
                    {NumbersInTabs?.data?.requests_count &&
                      (AuthState.userData?.admin?.roles[0]?.name ===
                        "superadmin" ||
                        AuthState.userData?.admin?.permissions?.some(
                          (permission) =>
                            permission.name.startsWith("request_accept")
                        )) && (
                        <>
                          <span
                            className={`numbers rounded-full bg-red-400 px-1 text-sm lg:hidden amd-dash:block ${
                              state.collapse && "hidden"
                            }`}
                          >
                            {NumbersInTabs?.data?.requests_count}
                          </span>
                          <div
                            className={`notification-pointer x absolute bottom-1/2 right-[5px] h-2 w-2 translate-y-1/2 rounded-full bg-red-400 lg:block amd-dash:-right-3 amd-dash:top-0 amd-dash:translate-y-0 amd-dash:hidden ${
                              state.collapse
                                ? " -right-[11px] top-0 block translate-y-0 "
                                : "hidden"
                            } `}
                          ></div>
                        </>
                      )}
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("user")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/assistants"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faUserGear} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Assistants{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("reviews")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/reviews"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon
                        className="w-5"
                        icon={faStarHalfStroke}
                      />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Reviews{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("analystics")
              )) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/analytics"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faChartLine} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Analytics{" "}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("ask")
            ) && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/chat"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faCommentAlt} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        Inbox
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {AuthState.userData?.admin?.roles[0]?.name === "superadmin" && (
              <li
                onClick={() =>
                  dispatch({
                    type: "SetRefetch",
                  })
                }
                className="w-full"
              >
                <NavLink
                  onClick={() =>
                    dispatch({
                      type: "SetShowSidebar",
                      payload: false,
                    })
                  }
                  className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                  to="/admin/all-chats"
                >
                  <div
                    className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                      state.collapse && "w-fit"
                    }`}
                  >
                    <div className="flex items-center justify-start gap-4 alg:gap-2">
                      <FontAwesomeIcon className="w-5" icon={faCommentAlt} />{" "}
                      <span
                        className={` ${
                          state.collapse && "hidden"
                        }   alg:text-base lg:w-3/5`}
                      >
                        All Chats
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}
            <li
              onClick={() =>
                dispatch({
                  type: "SetRefetch",
                })
              }
              className="w-full"
            >
              <NavLink
                onClick={() =>
                  dispatch({
                    type: "SetShowSidebar",
                    payload: false,
                  })
                }
                className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3"
                to="/admin/profile"
              >
                <div
                  className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                    state.collapse && "w-fit"
                  }`}
                >
                  <div className="flex items-center justify-start gap-4 alg:gap-2">
                    <FontAwesomeIcon className="w-5" icon={faUser} />{" "}
                    <span
                      className={` ${
                        state.collapse && "hidden"
                      }   alg:text-base lg:w-3/5`}
                    >
                      Profile
                    </span>
                  </div>
                </div>
              </NavLink>
            </li>
          </ul>

          <div className="w-full">
            <div
              className="admin-dash-btn flex w-full justify-center border-y-2 border-y-bg px-2 py-3 cursor-pointer"
              onClick={() => handleSignOut()}
            >
              <div
                className={`relative flex w-[212px]  items-center   justify-between gap-4 alg:w-44 alg:gap-2 lg:w-32 amd-dash:w-fit ${
                  state.collapse && "w-fit"
                }`}
              >
                <div className="flex items-center justify-start gap-4 alg:gap-2">
                  <FontAwesomeIcon
                    className="w-5"
                    icon={faArrowRightFromBracket}
                  />{" "}
                  <span
                    className={` ${
                      state.collapse && "hidden"
                    }   alg:text-base lg:w-3/5`}
                  >
                    Logout{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**
       * //!placeholder
       *
       */}
      <div
        className={`placeholder h-full ${
          state.collapse ? "w-24" : "w-1/5 min-w-[20%]"
        }  transition-all duration-200 ease-in-out md:w-0 md:min-w-[0%]`}
      ></div>
      {/**
       * //!outlet
       *
       */}
      <section className="outlet  h-full w-full overflow-y-auto  ">
        <Outlet dispatch="dddd" />
      </section>
    </section>
  );
}

export default AdminLayout;
