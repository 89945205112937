import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { useMemo } from "react";
import StudentChat from "../Pages/Student/fabs/StudentChat";

function Layout() {
  const adminOrStuedntToken = JSON.parse(localStorage?.userData).admin_token
    ? JSON.parse(localStorage?.userData).admin_token
    : JSON.parse(localStorage?.userData).student_token;
  useMemo(() => {
    // set axios
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + adminOrStuedntToken;
  }, [adminOrStuedntToken]);

  return (
    <>
      <div id="top"></div>
      <Navbar />
      <div className="pt-20 min-h-screen">
        <Outlet />
      </div>
      <StudentChat />
      <Footer />
    </>
  );
}

export default Layout;
