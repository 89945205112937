import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./MainComponents/GlobalContext.jsx";

import { Toaster } from "react-hot-toast";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Toaster position="top-center" reverseOrder={false} />
        <Toaster />
        <AuthProvider>
          <App />
        </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);
