import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CoursesForm = ({ courseId, mobileNumber }) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [updateCoursesSucsses, updateCoursesErrors, updateCoursesLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_UPDATE_COURSE_EXCEPTION_API,
      "POST",
      formFlag,
      formDependency,
      formValues
    );

  const onSubmit = (formData) => {
    setFormValues({ id: courseId, mobile: mobileNumber, ...formData });
    setFormFlag("updateSession");
    setFormDependency(true);
  };

  useEffect(() => {
    if (updateCoursesSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateCoursesSucsses]);

  useEffect(() => {
    if (updateCoursesErrors) {
      toast.error(updateCoursesErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [updateCoursesErrors]);
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className={`flex flex-col items-end justify-center gap-2`}>
        <label className="w-full truncate" htmlFor="hour">
          Number Of Hours
        </label>
        <input
          className="signin-inputs w-full pl-4    "
          type="number"
          id="hour"
          placeholder="Number Of Hours"
          name="hour"
          autoComplete="on"
          {...register("hour", {
            required: true,
          })}
        />
        {errors.name && (
          <p className="text-xs text-red-500  ">
            {errors.hour.type === "required" && "Please fill this input"}
          </p>
        )}

        {
          //!-------server errors -----

          updateCoursesErrors &&
            updateCoursesErrors?.response?.data?.errors?.hour && (
              <p className="text-xs text-red-500  ">
                {updateCoursesErrors?.response?.data?.errors?.hour[0]}
              </p>
            )
        }
      </div>
      <button className=" submit mt-6 w-full" type="submit">
        {updateCoursesLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Update Course"
        )}
      </button>
    </form>
  );
};

export default CoursesForm;
