import { Link, useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useState } from "react";
import SendStudentsWhatsappMessages from "./SendStudentsWhatsappMessages";
import { useAuthState } from "../../../../MainComponents/GlobalContext";

const AdminViewCourse = () => {
  const AuthState = useAuthState();

  const { courseID } = useParams();
  const [showSendWhatsApp, setShowSendWhatsApp] = useState(false);
  const [selectionActiveStudents, setSelectionActiveStudents] = useState([]);
  const [selectionLateStudents, setSelectionLateStudents] = useState([]);
  const [isActiveSutdents, setIsActiveSutdents] = useState(true);

  const handelSelectionActiveStudents = (records) => {
    setSelectionActiveStudents((prev) => [
      ...records.map((record) => ({
        key: record.key,
      })),
    ]);
  };

  const handelSelectionLateStudents = (records) => {
    setSelectionLateStudents((prev) => [
      ...records.map((record) => ({
        key: record.key,
      })),
    ]);
  };

  const activeSutdentsTable = useTable(
    "viewCourse",
    "nested",
    "",
    "",
    "",
    courseID,
    "",
    "",
    "",
    "",
    handelSelectionActiveStudents
  );

  const lateSutdentsTable = useTable(
    "viewCourseLateStudents",
    "",
    "",
    "",
    "",
    courseID,
    "",
    "",
    "",
    "",
    handelSelectionLateStudents
  );

  const whatsappValidation =
    AuthState.userData?.admin?.permissions?.some((permission) =>
      permission.name.startsWith("send_whatsapp")
    ) || AuthState.userData?.admin?.roles[0]?.name === "superadmin";

  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title={"Course View | Mr.Mohamed Salama"}></HelmetTags>{" "}
      <h2 className="mb-7 text-start sm:text-center text-2xl font-bold">
        Course View
      </h2>
      <div className="flex items-center gap-6 md:flex-col">
        {whatsappValidation && (
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-secondary active:scale-90"
            disabled={
              (selectionActiveStudents.length === 0 && isActiveSutdents) ||
              (selectionLateStudents.length === 0 && !isActiveSutdents)
            }
            onClick={() => setShowSendWhatsApp((prev) => !prev)}
          >
            {showSendWhatsApp ? "Back To Table" : "Send Whatsapp Messages"}
          </button>
        )}

        <button
          disabled={showSendWhatsApp}
          className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-secondary active:scale-90"
          onClick={() => setIsActiveSutdents((prev) => !prev)}
        >
          {isActiveSutdents ? "Late Students" : "Active Students"}
        </button>
        <Link
          to={`/admin/courses/view/${courseID}/add-student`}
          className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-secondary active:scale-90"
        >
          Add Student
        </Link>
        <Link
          to={`/admin/courses/view/${courseID}/all-assignments`}
          className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-bg duration-300 hover:bg-bg hover:text-secondary active:scale-90"
        >
          View All Assignments
        </Link>
      </div>
      {showSendWhatsApp ? (
        <SendStudentsWhatsappMessages
          isActiveSutdents={isActiveSutdents}
          selection={
            isActiveSutdents ? selectionActiveStudents : selectionLateStudents
          }
        />
      ) : (
        <div className="h-fit  w-full">
          {isActiveSutdents ? activeSutdentsTable : lateSutdentsTable}
        </div>
      )}
    </section>
  );
};

export default AdminViewCourse;
