import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";

const PurchasesForm = ({ courseId, mobileNumber }) => {
  const [values, setValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [dependency, setDependency] = useState(false);

  const [
    updatePurchasesSucsses,
    updatePurchasesErrors,
    updatePurchasesLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_STUDENT_PURCHASE_EXCEPTION_API,
    "POST",
    formFlag,
    dependency,
    values
  );

  const onClick = () => {
    setFormFlag(courseId);
    setDependency(true);
    setValues({
      id: courseId,
      mobile: mobileNumber,
    });
  };

  useEffect(() => {
    if (updatePurchasesSucsses) {
      toast.success("Success..");
      setFormFlag("");
      setDependency(false);
    }
  }, [updatePurchasesSucsses]);

  useEffect(() => {
    if (updatePurchasesErrors) {
      toast.error(updatePurchasesErrors.message);
      setFormFlag("");
      setDependency(false);
    }
  }, [updatePurchasesErrors]);
  return (
    <div className="w-full">
      <button onClick={onClick} className=" submit mt-6 w-full" type="submit">
        {updatePurchasesLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          " Update Purchases"
        )}
      </button>
    </div>
  );
};

export default PurchasesForm;
