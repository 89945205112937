import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import useCountDown from "../../../../MainComponents/Hooks/useCountDown";

const StudentCoursesCard = ({ teacher, course }) => {
  const navigate = useNavigate();
  const { days, hours, minutes, seconds } = useCountDown(course.expire_at);

  return (
    <div
      className={`single-course-container hover:bg-lemon bg-green duration-300 w-[350px] ${
        teacher ? "sm:w-[250px]" : "sm:w-[300px]"
      }   rounded-md p-4 shadow-lg`}
    >
      <div className="img-wrapper w-full rounded-md overflow-hidden">
        <img
          className="w-full h-40 object-cover cursor-pointer hover:saturate-150 duration-300 "
          src={course.img}
          alt={course.title}
          onClick={() =>
            navigate(
              teacher
                ? `/admin/courses/${course.key}`
                : `/courses/${course.key}`
            )
          }
        />
      </div>

      <div className="details-wrapper mt-5">
        <div className="type-hours w-full flex justify-between items-center">
          <div className="type rounded-lg px-2 bg-pink text-white cursor-pointer">
            {course.type}
          </div>
          {/* <div className="hours"> 14 lessons | {course.time}</div> */}
        </div>

        <h2 className="title font-bold mt-5 mb-2   flex justify-between items-center">
          {teacher ? (
            <Link
              to={`/admin/courses/${course.key}`}
              className="cursor-pointer w-3/5  truncate"
            >
              {course.title}
            </Link>
          ) : (
            <Link to={`/courses/${course.key}`}>
              <span className="cursor-pointer w-3/5  truncate">
                {course.title}
              </span>
            </Link>
          )}
          {/* <span className="font-normal"> {course.type}</span> */}
        </h2>
        <p className="description">{course.description}</p>

        <div
          className={`price-cta flex  items-center ${
            teacher ? " justify-evenely" : "flex-col gap-3"
          }  mt-5 w-full`}
        >
          {!course.is_available && (
            <p className="price font-bold">
              {" "}
              {course.price === 0 ? "Free" : `${course.price} EGP`}
            </p>
          )}

          {teacher ? (
            <>
              <div className="teacher-btns flex justify-end items-center gap-4  w-full">
                <Link
                  to="/"
                  className="delete-btn bg-delete text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-delete hover:bg-bg hover:text-delete transition-all duration-300  hover:scale-105 active:scale-90"
                >
                  Delete
                </Link>
                <Link
                  to={`/admin/courses/edit-course/${course.key}`}
                  className="edit-btn   bg-edit text-bg w-16 py-[6px] font-semibold rounded-2xl text-center border-2 border-edit hover:bg-bg hover:text-edit transition-all duration-300  hover:scale-105 active:scale-90"
                >
                  Edit
                </Link>
              </div>
            </>
          ) : (
            <>
              {course.is_available ? (
                <div className="w-full font-bold p-2 border-[#fff] bg-[#fff] rounded-md">
                  <p>Expired After:</p>
                  <p className="text-center">
                    {days}: days, {hours}: Hrs, {minutes}: Min, {seconds}: Sec
                  </p>
                </div>
              ) : (
                ""
              )}
              <Link
                to={`/courses/${course.key}`}
                className="flex justify-center items-center gap-2 w-full bg-blue hover:bg-bg hover:text-blue duration-300 px-3 py-2 font-semibold rounded-2xl  text-bg border-2 border-blue hover:scale-105 active:scale-90"
              >
                {course.is_available || course.price === 0 ? (
                  <>
                    <FontAwesomeIcon icon={faUnlock} />
                    View
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faLock} />
                    Enroll Now
                  </>
                )}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentCoursesCard;
