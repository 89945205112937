import { useEffect } from "react";
import { toast } from "react-hot-toast";
import useAxios from "../../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { useState } from "react";

const SessionSelect = ({
  values,
  courseId,
  setSessionId,
  setAllViewsVideos,
}) => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });
  const sessionSelect = watch("session");

  const [sessionSelectSucsses, sessionSelectErrors, sessionSelectLoading] =
    useAxios(
      process.env.REACT_APP_ADMIN_GET_VIDEOS_COURSE_EXCEPTION_API,
      "POST",
      formFlag,
      formDependency,
      formValues
    );

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag("EditSessionInformation");
    setFormDependency(true);
  };

  useEffect(() => {
    if (sessionSelect) onSubmit({ id: courseId, session_id: sessionSelect });
  }, [courseId, sessionSelect]);

  useEffect(() => {
    if (sessionSelectSucsses) {
      setFormFlag("");
      setFormDependency(false);
      setAllViewsVideos(sessionSelectSucsses.data);
    }
  }, [sessionSelect, sessionSelectSucsses, setAllViewsVideos]);

  useEffect(() => {
    if (sessionSelectErrors) {
      toast.error(sessionSelectErrors.message);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [sessionSelectErrors]);

  useEffect(() => {
    setSessionId(sessionSelect);
  }, [sessionSelect, setSessionId]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      <div className={`flex flex-col items-end justify-center gap-2`}>
        <label className="w-full truncate" htmlFor="course">
          Sessions
        </label>
        <select
          name="session"
          id="session"
          {...register("session", { required: true })}
        >
          <option value="">Select a session</option>
          {values.map((value, i) => (
            <option key={i} value={value.id}>
              {value.session}
            </option>
          ))}
        </select>

        {errors.session && (
          <p className="text-xs text-red-500 ">
            {errors.session.session === "required" && "This field is required."}
          </p>
        )}
        {
          //!-------server errors -----

          sessionSelectErrors &&
            sessionSelectErrors?.response?.data?.errors?.course && (
              <p className="w-full text-end text-xs text-red-500  ">
                {sessionSelectErrors?.response?.data?.errors?.course[0]}
              </p>
            )
        }
      </div>
    </form>
  );
};

export default SessionSelect;
